import verifiedLogo from "../../assests/header/verified-custom-logo.svg";
import { motion } from "framer-motion";

const GeneralPopupBackground = (props) => {
  return (
    <div
      style={
        props.popupContentPosition?.toLowerCase() !== "center" &&
        !props.expandedView
          ? {
              alignItems: props.popupContentPosition,
              justifyContent: props.popupContentPosition,
            }
          : props.popupContentPosition?.toLowerCase() !== "center" &&
            props.expandedView
          ? {
              alignItems: props.popupContentPosition,
              justifyContent: props.popupContentPosition,
              background: "transparent",
            }
          : props.expandedView
          ? {
              background: "transparent",
            }
          : {}
      }
      className="general-popup-background"
    >
      {props.customLoading && (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0",
          }}
          className="general-popup-background"
        >
          <motion.img
            height="100px"
            width="100px"
            src={verifiedLogo}
            alt="Loading..."
            animate={{
              rotate: 360,
              scale: [1, 1.5, 1],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        </div>
      )}
      {props.popupContent}
    </div>
  );
};

export default GeneralPopupBackground;
