import axios from "axios";
import { currenciesToFiat, errorToast, PoolType } from "./constants";
import { PrimaryIssuePool, SecondaryIssuePool } from "../lib/sor/index";
import { BigNumber, ethers } from "ethers";
import Web3 from "web3";
import { getUserKYCDetails } from "../services/contracts/clientContract";
import VerifyKYC from "../components/popups/verifyKYC";
import { ChainConfig } from "./config";

export const capitalizeFirstLetter = (content) => {
  if (content.length === 0) return "";
  return content.charAt(0).toUpperCase() + content.slice(1).toLowerCase();
};

export const convertToBlockTimestamp = (day, month, year) => {
  const date = new Date(year, month - 1, day);
  return Math.floor(date.getTime() / 1000);
};

export const convertFromBlockTimestamp = (blockTimestamp) => {
  const unixTime = new Date(Number(blockTimestamp) * 1000);
  return unixTime.toLocaleDateString("default");
};

export const getYesterdayTimeFromNow = () => {
  const now = new Date();
  const nowInMinutes = now.getTime();
  const yesterday = new Date(nowInMinutes - 24 * 60 * 60 * 1000); //24hours in minutes
  return yesterday.getTime();
};

export const fetchMarginPoolCurrentPrice = async (
  currencySymbol,
  securitySymbol,
  poolCfiCode,
  priceType
) => {
  const marginCurrencySymbol = currenciesToFiat[currencySymbol]
    ? currenciesToFiat[currencySymbol]
    : "USD";
  const brokerageApiEndpoint = process.env.REACT_APP_BROKERAGE_ENDPOINT;
  const url = brokerageApiEndpoint
    ? `${brokerageApiEndpoint}/${poolCfiCode.toLowerCase()}?security=${securitySymbol}&cash=${marginCurrencySymbol}&type=${priceType}`
    : "";
  if (url) {
    return await axios({
      method: "GET",
      url: url,
    })
      .then((res) => {
        return res?.data?.body?.price;
      })
      .catch((err) => {
        console.error(
          `Request to get response from url: ${url} failed with error: ${err}`
        );
        return "0.00";
      });
  } else {
    console.error("Brokarage api endpoint not found. Try again later");
    return "0.00";
  }
};

export const createAccessTokenForNewUser = async (userAddress, chainId) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_SUMSUB_ENDPOINT}?account=${userAddress}&networkId=${chainId}`,
    });
    return {
      accessToken: res?.data?.token,
    };
  } catch (err) {
    console.error("error while creating access token: ", err);
    return {
      accessToken: "",
    };
  }
};

export const calculatePrimaryAndSecondarySOR = async (
  poolIn,
  amountIn,
  swapType,
  userAddress
) => {
  let tokenIn, tokenOut;
  if (swapType === "sell") {
    tokenIn = poolIn.security;
    tokenOut = poolIn.currency;
  } else {
    tokenIn = poolIn.currency;
    tokenOut = poolIn.security;
  }
  const pool =
    poolIn.poolType === PoolType.primaryPool
      ? PrimaryIssuePool.fromPool(poolIn)
      : SecondaryIssuePool.fromPool(poolIn);
  const poolPairData = pool.parsePoolPairData(tokenIn, tokenOut);
  const amount = ethers.utils.parseUnits(
    amountIn.toString(),
    Number(poolPairData.decimalsIn)
  );
  let amountOut,
    slippage = 0;
  if (swapType === "sell") {
    amountOut = pool._exactTokenInForTokenOut(
      poolPairData,
      BigNumber.from(amount.toString()),
      userAddress
    );
    slippage = pool._spotPriceAfterSwapExactTokenInForTokenOut(
      poolPairData,
      BigNumber.from(amount.toString()),
      userAddress
    );
  } else if (swapType === "buy") {
    amountOut = pool._tokenInForExactTokenOut(
      poolPairData,
      swapType === "buy" && poolIn.poolType === PoolType.secondaryPool
        ? BigNumber.from(amountIn.toString())
        : BigNumber.from(
            Web3.utils.toWei(Number(amountIn).toString(), "ether")
          ),
      userAddress
    );
    slippage = pool._spotPriceAfterSwapTokenInForExactTokenOut(
      poolPairData,
      BigNumber.from(amount.toString()),
      userAddress
    );
  }
  const slippagePercentage = (Number(slippage) * 100).toFixed(8);
  return {
    slippage: slippagePercentage,
    amountOut: amountOut,
  };
};

export const authenticateKYCStatus = async (
  chainId,
  userAddress,
  signer,
  appKitModal,
  setPopupContent,
  setPopupContentPosition,
  setShowPopup,
  setCustomLoading,
  setFavouriteClicked
) => {
  let isWalletConnected = false;
  if (userAddress === "") {
    await appKitModal.open().then(() => {
      isWalletConnected = true;
    });
  } else {
    isWalletConnected = true;
  }
  if (isWalletConnected) {
    const userKyc = await getUserKYCDetails(chainId, userAddress, signer);
    if (Number(userKyc?.userKycStatus) !== 3) {
      const accessToken = await createAccessTokenForNewUser(
        userAddress,
        chainId
      ).then((res) => res?.accessToken);
      if (setCustomLoading) {
        setCustomLoading(false);
      }
      if (setFavouriteClicked) {
        setFavouriteClicked(false);
      }
      setPopupContentPosition("center");
      setPopupContent(
        <VerifyKYC
          setShowPopup={setShowPopup}
          setPopupContent={setPopupContent}
          setPopupContentPosition={setPopupContentPosition}
          buttonAction="verify kyc"
          additionalProps={{
            accessToken,
            userAddress,
            chainId,
          }}
        />
      );
      setShowPopup(true);
    } else {
      return true;
    }
  }
};

export const formatFileSize = (sizeInBytes) => {
  let formattedSize;
  if (Number(sizeInBytes) < 1024)
    formattedSize = `${sizeInBytes} B`; //not up to KB ??
  else if (Number(sizeInBytes) < 1048576)
    formattedSize = `${(Number(sizeInBytes) / 1024).toFixed(2)} KB`;
  // noot up to MB ??
  else formattedSize = `${(Number(sizeInBytes) / 1048576).toFixed(2)} MB`;

  return formattedSize;
};

export const extractNumberWithCommas = (textInput) => {
  let numberWithDecimals = textInput.toString().replace(/[^0-9.]/g, "");
  if (numberWithDecimals.includes(".")) {
    const [integer, decimal] = numberWithDecimals.split(".");
    const integerWithCommas = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return {
      number: Number(`${integer}.${decimal}`),
      fmtNumber: `${integerWithCommas}.${decimal}`,
    };
  } else {
    return {
      number: Number(numberWithDecimals),
      fmtNumber: numberWithDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    };
  }
};

export const switchNetwork = async (chainId, window) => {
  try {
    if (!window.ethereum) {
      errorToast(
        "Opps something went wrong.",
        "Download metamask and try again."
      );
      return false;
    }
    return await window.ethereum
      .request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...ChainConfig[chainId],
          },
        ],
      })
      .then((res) => {
        return true;
      });
  } catch (err) {
    console.error("switch network failed: ", err);
    errorToast("Opps something went wrong.", "Approve switch network request.");
    return false;
  }
};
