import popupCloseIcon from "../../assests/page/popup-close-icon.svg";
import exandIcon from "../../assests/page/chevron-right.svg";
import backIcon from "../../assests/page/chevron-left.svg";
import searchIcon from "../../assests/sideBar/search-icon.svg";
import lidoBanner from "../../assests/overlay/lidoBanner.svg";
import { useEffect, useState } from "react";
import { allCountriesApi, popularCountries } from "../../utils/constants";
import axios from "axios";
import PaymentMethods from "./paymentMethods";
import { useGetSupportedCurrencies } from "../../hooks/currencies";
import Avatar from "react-avatar";
import { authenticateKYCStatus } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import ERC20ABI from "../../utils/abis/ERC20.json";
import { ChainConfig } from "../../utils/config";
import { toast } from "react-toastify";
import { contractAddress, Liquidity } from "@verified-network/verified-sdk";
import { useAppKit } from "@reown/appkit/react";

const AddFunds = (props) => {
  const [amount, setAmount] = useState("");
  const [showAllCurrencies, setShowAllCurrencies] = useState(false);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.currencySelected || {
      countryName: "",
      officialName: "",
      flag: null,
      currencySymbol: "",
      currencyName: "",
      officialCurrencySymbol: "",
      isClicked: false,
    }
  );
  const [newSearchContent, setNewSearchContent] = useState(null);
  const [userCurrencyBalance, setUserCurrencyBalance] = useState(0.0);
  const appKitModal = useAppKit();

  const {
    getSupportedCurrenciesData,
    getSupportedCurrenciesLoading,
    getSupportedCurrenciesError,
  } = useGetSupportedCurrencies();

  const { chainId, provider, web3 } = useSelector((state) => state.network);
  const { userAddress } = useSelector((state) => state.user);

  useEffect(
    () => {
      (async () => {
        const getAllCurrenciesAndCountries = async () => {
          await axios({ method: "GET", url: allCountriesApi })
            .then((res) => {
              const currencies = res.data.map((cnt) => {
                if (cnt.currencies && Object.keys(cnt.currencies).length > 0) {
                  return {
                    countryName: cnt.name.common,
                    officialName: cnt.name.official,
                    flag: cnt.flags.svg,
                    currencySymbol: Object.keys(cnt.currencies || {})[0],
                    currencyName: Object.values(cnt.currencies || {})[0].name,
                    officialCurrencySymbol: Object.values(
                      cnt.currencies || {}
                    )[0].symbol,
                  };
                } else {
                  return null;
                }
              });
              setAllCurrencies(
                currencies.filter((currency) => currency !== null)
              );
            })
            .catch((err) => {
              console.error("get all countries failled with error: ", err);
              //Todo: toast here
            });
        };

        // if (showAllCurrencies) {
        //   await getAllCurrenciesAndCountries();
        // }
        await getAllCurrenciesAndCountries();
      })();
    },
    []
    // [showAllCurrencies]
  );

  const handleAddFundsButtonDisplay = () => {
    const button = document.getElementById("add-funds-popup-button");
    if (
      button &&
      selectedCurrency.currencySymbol?.length > 0 &&
      document.getElementById("add-funds-popup-input").value.length > 0 &&
      Number(document.getElementById("add-funds-popup-input").value) > 0
    ) {
      button.className = "startup-button-active";
    } else if (button) {
      button.className = "startup-button";
    }
  };

  const handleChooseCurrencyButtonDisplay = () => {
    const button = document.getElementById("choose-currency-popup-button");
    if (button && selectedCurrency.isClicked) {
      button.className = "startup-button-active";
    } else if (button) {
      button.className = "startup-button";
    }
  };

  const handleProvideLiquidity = async () => {
    setNewSearchContent(null);
    if (provider && provider.getSigner && chainId && userAddress && web3) {
      props.setCustomLoading(true);
      //check kyc
      await authenticateKYCStatus(
        chainId,
        userAddress,
        provider.getSigner(userAddress),
        appKitModal,
        props.setPopupContent,
        props.setPopupContentPosition,
        props.setShowPopup,
        props.setCustomLoading,
        props.setFavouriteClicked
      ).then(async (res) => {
        if (res) {
          //buy liquidity
          const currencyAmountFixed = ethers.utils
            .parseUnits(amount.toString(), selectedCurrency.tokenDecimals)
            .toString();
          if (
            Number(selectedCurrency.tokenBalance) < Number(currencyAmountFixed)
          ) {
            props.setCustomLoading(false);
            toast.error("Insufficient currency balance!");
            return;
          }
          const liquidityContractAddress = contractAddress[chainId].Liquidity;
          if (liquidityContractAddress) {
            if (
              selectedCurrency.tokenAddress.toLowerCase() !==
              ethers.constants.AddressZero.toLowerCase()
            ) {
              const tokenContract = new web3.eth.Contract(
                ERC20ABI,
                selectedCurrency.tokenAddress
              );
              let isApproved = false;
              await tokenContract.methods
                .allowance(userAddress, liquidityContractAddress)
                .call()
                .then((res) => {
                  if (Number(res) >= Number(currencyAmountFixed)) {
                    isApproved = true;
                  }
                });
              if (!isApproved) {
                await tokenContract.methods
                  .approve(liquidityContractAddress, currencyAmountFixed)
                  .send({ from: userAddress })
                  .then(() => {
                    isApproved = true;
                    toast.success("Approved Liquidity Contract Successfully");
                  })
                  .catch((err) => {
                    toast.error("Approve Transactioon Failed");
                    console.error("Approve Transactioon Failed", err);
                  });
              }

              if (isApproved) {
                const liquidityContract = new Liquidity(
                  provider.getSigner(userAddress),
                  liquidityContractAddress
                );
                await liquidityContract
                  .buy(selectedCurrency.tokenAddress, currencyAmountFixed)
                  .then((res) => {
                    if (res?.status === 0) {
                      toast.success("Liquidity Provided Successfully");
                      console.log(
                        "Liquidity Provided Successfully with hash ",
                        res?.response?.hash
                      );
                    } else {
                      toast.error("Provide Liquidity Failed");
                      console.error("Provide Liquidity");
                    }
                  })
                  .catch((err) => {
                    toast.error("Provide Liquidity Failed");
                    console.error("Provide Liquidity Failed", err);
                  });
              }
            } else {
              const transactionObject = {
                from: userAddress,
                to: liquidityContractAddress,
                value: currencyAmountFixed,
              };
              try {
                const transaction = await provider
                  .getSigner(userAddress)
                  .sendTransaction(transactionObject);
                let pendingTransaction = await transaction.wait();
                if (pendingTransaction?.transactionHash) {
                  toast.success("Liquidity Provided Successfully");
                }
              } catch (err) {
                toast.error("Provide Liquidity Failed");
                console.error("Provide Liquidity Failed", err);
              }
            }
            props.setCustomLoading(false);
          }
        }
      });
    } else if (userAddress === "") {
      //connect user wallet if not connected
      appKitModal.open();
    }
  };

  return (
    <div className="add-funds-popup">
      {!showAllCurrencies && (
        <div className="add-funds-content">
          <div className="add-funds-content-header">
            <div className="add-funds-content-header-text-container">
              {selectedCurrency.isCrypto && (
                <p className="add-funds-content-header-text">
                  Provide Liquidity
                </p>
              )}
              {!selectedCurrency.isCrypto && (
                <p className="add-funds-content-header-text">Add Funds</p>
              )}
            </div>
            <img
              onClick={() => {
                props.setShowPopup(false);
                props.setPopupContent(null);
                setNewSearchContent(null);
                props.setPopupContentPosition("center");
                if (props.setFavouriteClicked) {
                  props.setFavouriteClicked(false);
                }
              }}
              className="add-funds-popup-close-icon"
              alt="Close Icon"
              src={popupCloseIcon}
            />
          </div>
          <div className="add-funds-content-footer">
            <div className="add-funds-content-footer-balance-container">
              <p className="add-funds-content-footer-balance-first">
                Wallet Balance:
              </p>
              <p className="add-funds-content-footer-balance-second">
                {`${selectedCurrency.officialCurrencySymbol}  ${
                  Number(userCurrencyBalance) > 0
                    ? userCurrencyBalance
                    : Number(selectedCurrency.balance).toFixed(6)
                }`}
              </p>
            </div>
            <div
              style={{ marginBottom: "12px" }}
              className="asset-info-popup-second-trading-body-line"
            ></div>
            <p className="add-funds-content-footer-text">Enter Amount</p>
            <div className="a-f-content-footer-input-container">
              <div className="a-f-content-footer-input-group">
                <p className="a-f-content-footer-input-text">
                  {selectedCurrency.officialCurrencySymbol}
                </p>
                <input
                  id="add-funds-popup-input"
                  onInput={(e) => {
                    setAmount(e.target.value);
                    handleAddFundsButtonDisplay();
                  }}
                  type="number"
                  className="a-f-content-footer-input"
                  placeholder="00.00"
                />
              </div>
              <div
                onClick={() => {
                  setShowAllCurrencies(true);
                }}
                className="a-f-content-footer-currencies"
              >
                <div className="a-f-content-footer-currencies-container">
                  <p className="a-f-content-footer-currency">
                    {selectedCurrency.currencySymbol}
                  </p>
                  <img alt="Exand Icon" src={exandIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAllCurrencies && (
        <div
          style={{ overflow: "auto", marginBottom: "30px" }}
          className="add-funds-content"
        >
          <div className="add-funds-content-header">
            <div className="add-funds-content-header-text-container">
              <img
                onClick={() => {
                  setShowAllCurrencies(false);
                  setNewSearchContent(null);
                }}
                className="add-funds-popup-back-icon"
                alt="Back Icon"
                src={backIcon}
              />
              <p className="add-funds-search-header-text">Choose a Currency</p>
            </div>
            <img
              onClick={() => {
                props.setShowPopup(false);
                props.setPopupContent(null);
                setNewSearchContent(null);
                props.setPopupContentPosition("center");
                if (props.setFavouriteClicked) {
                  props.setFavouriteClicked(false);
                }
              }}
              className="add-funds-popup-close-icon"
              alt="Close Icon"
              src={popupCloseIcon}
            />
          </div>
          <div className="add-funds-content-search">
            <img alt="Search Icon" src={searchIcon} />
            <input
              onInputCapture={() => {
                selectedCurrency.isClicked = false;
              }}
              onInput={(e) => {
                if (e.target.value === "") {
                  setNewSearchContent(null);
                } else {
                  setNewSearchContent(
                    // eslint-disable-next-line array-callback-return
                    allCurrencies.filter((curr) => {
                      if (
                        curr.currencySymbol
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      ) {
                        curr.searched = e.target.value.toLowerCase();
                        curr.nameArray = curr.currencySymbol.split("");
                        return curr;
                      }
                    })
                  );
                }
                const previouslyActiveTag = document.getElementsByClassName(
                  "a-f-content-search-header-main-active"
                );
                if (previouslyActiveTag.length > 0) {
                  previouslyActiveTag[0].className =
                    "a-f-content-search-header-main";
                }
                handleChooseCurrencyButtonDisplay();
              }}
              className="add-funds-content-search-input"
              type="text"
              placeholder="Search for a currency / country"
            />
          </div>
          <div className="add-funds-content-search-result">
            <div className="a-f-content-search-result-header">
              {/* <p className="a-f-content-search-header-text">
                Crypto Currencies
              </p> */}
              {getSupportedCurrenciesLoading && <p>Loading...</p>}
              {getSupportedCurrenciesData
                .filter(
                  (curr) =>
                    curr.currencySymbol &&
                    curr.officialCurrencySymbol &&
                    curr.currencyName
                )
                .map((curr, idx) => (
                  <div
                    id={`crypto-curencies-${idx}`}
                    key={`crypto-curencies-${idx}`}
                    onClickCapture={() => {
                      curr["isClicked"] = true;
                      setSelectedCurrency(curr);
                    }}
                    onClick={(e) => {
                      const previouslyActiveTag =
                        document.getElementsByClassName(
                          "a-f-content-search-header-main-active"
                        );
                      if (previouslyActiveTag.length > 0) {
                        previouslyActiveTag[0].className =
                          "a-f-content-search-header-main";
                      }
                      const currentTag = document.getElementById(
                        `crypto-curencies-${idx}`
                      );
                      currentTag.className =
                        "a-f-content-search-header-main-active";
                      handleChooseCurrencyButtonDisplay();
                    }}
                    className="a-f-content-search-header-main"
                  >
                    <div className="a-f-content-search-header-main-container">
                      <div className="a-f-content-search-header-main-content">
                        <div className="a-f-content-search-header-image">
                          <Avatar name={curr.currencySymbol} />
                        </div>
                        <div className="a-f-content-search-header-text-container">
                          <p className="a-f-content-search-header-text-header">
                            {curr.currencySymbol}
                          </p>
                          <p className="a-f-content-search-header-text-footer">
                            {curr.currencyName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {showAllCurrencies && (
        <button
          id="choose-currency-popup-button"
          onClick={(e) => {
            if (
              e.target.className.includes("startup-button") &&
              e.target.className !== "startup-button"
            ) {
              e.target.className = "startup-button-click";
              setShowAllCurrencies(false);
              setNewSearchContent(null);
            } else {
              const buttonElement = document.getElementsByClassName(
                "startup-button-active"
              );
              if (buttonElement.length > 0) {
                buttonElement[0].className = "startup-button-click";
                setShowAllCurrencies(false);
                setNewSearchContent(null);
              }
            }
          }}
          className="startup-button"
        >
          <p className="st-button-text">Proceed</p>
        </button>
      )}
      {!showAllCurrencies &&
        selectedCurrency.tokenAddress?.toLowerCase() ===
          ethers.constants.AddressZero.toLowerCase() && (
          <img src={lidoBanner} alt="" />
        )}
      {!showAllCurrencies && !selectedCurrency.isCrypto && (
        <button
          id="add-funds-popup-button"
          onClick={(e) => {
            if (
              e.target.className.includes("startup-button") &&
              e.target.className !== "startup-button"
            ) {
              e.target.className = "startup-button-click";
              setNewSearchContent(null);
              props.setPopupContentPosition("flex-end");
              props.setPopupContent(
                <PaymentMethods
                  setShowPopup={props.setShowPopup}
                  setPopupContent={props.setPopupContent}
                  setPopupContentPosition={props.setPopupContentPosition}
                  setFavouriteClicked={props.setFavouriteClicked}
                />
              );
            } else {
              const buttonElement = document.getElementsByClassName(
                "startup-button-active"
              );
              if (buttonElement.length > 0) {
                buttonElement[0].className = "startup-button-click";
                setNewSearchContent(null);
                props.setPopupContentPosition("flex-end");
                props.setPopupContent(
                  <PaymentMethods
                    setShowPopup={props.setShowPopup}
                    setPopupContent={props.setPopupContent}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                );
              }
            }
          }}
          className="startup-button"
        >
          <p className="add-funds-popup-a-f-button-text">Add funds to wallet</p>
        </button>
      )}
      {!showAllCurrencies && selectedCurrency.isCrypto && (
        <>
          {!selectedCurrency.isCryptoFiat && (
            <button
              id="add-funds-popup-button"
              onClick={async (e) => {
                if (
                  e.target.className.includes("startup-button") &&
                  e.target.className !== "startup-button"
                ) {
                  e.target.className = "startup-button-click";
                  await handleProvideLiquidity();
                } else {
                  const buttonElement = document.getElementsByClassName(
                    "startup-button-active"
                  );
                  if (buttonElement.length > 0) {
                    buttonElement[0].className = "startup-button-click";
                    await handleProvideLiquidity();
                  }
                }
              }}
              className="startup-button"
            >
              <p className="add-funds-popup-a-f-button-text">
                Provide Liquidity
              </p>
            </button>
          )}
          {selectedCurrency.isCryptoFiat && (
            <button
              id="add-funds-popup-button"
              onClick={async (e) => {
                if (
                  e.target.className.includes("startup-button") &&
                  e.target.className !== "startup-button"
                ) {
                  e.target.className = "startup-button-click";
                  await handleProvideLiquidity();
                } else {
                  const buttonElement = document.getElementsByClassName(
                    "startup-button-active"
                  );
                  if (buttonElement.length > 0) {
                    buttonElement[0].className = "startup-button-click";
                    await handleProvideLiquidity();
                  }
                }
              }}
              className="startup-button"
            >
              <p className="add-funds-popup-a-f-button-text">
                Provide Liquidity
              </p>
            </button>
          )}
          {/* {selectedCurrency.isCryptoFiat &&
            Number(selectedCurrency.balance) < Number(amount) && (
              <button
                id="add-funds-popup-button"
                onClick={(e) => {
                  if (
                    e.target.className.includes("startup-button") &&
                    e.target.className !== "startup-button"
                  ) {
                    e.target.className = "startup-button-click";
                    setNewSearchContent(null);
                    props.setPopupContentPosition("flex-end");
                    props.setPopupContent(
                      <PaymentMethods
                        setShowPopup={props.setShowPopup}
                        setPopupContent={props.setPopupContent}
                        setPopupContentPosition={props.setPopupContentPosition}
                        setFavouriteClicked={props.setFavouriteClicked}
                      />
                    );
                  } else {
                    const buttonElement = document.getElementsByClassName(
                      "startup-button-active"
                    );
                    if (buttonElement.length > 0) {
                      buttonElement[0].className = "startup-button-click";
                      setNewSearchContent(null);
                      props.setPopupContentPosition("flex-end");
                      props.setPopupContent(
                        <PaymentMethods
                          setShowPopup={props.setShowPopup}
                          setPopupContent={props.setPopupContent}
                          setPopupContentPosition={
                            props.setPopupContentPosition
                          }
                          setFavouriteClicked={props.setFavouriteClicked}
                          selectedCurrency={{
                            symbol: selectedCurrency.officialCurrencySymbol,
                            amount: amount,
                            ...selectedCurrency,
                          }}
                        />
                      );
                    }
                  }
                }}
                className="startup-button-active"
              >
                <p className="add-funds-popup-a-f-button-text">
                  Add funds to wallet
                </p>
              </button>
            )} */}
        </>
      )}
    </div>
  );
};

{
  /* <div className="a-f-content-search-result-header">
              <p className="a-f-content-search-header-text">
                Popular Currencies
              </p>
              {allCurrencies
                .filter(
                  (curr) =>
                    (curr.currencySymbol &&
                      curr.currencyName &&
                      curr.flag &&
                      popularCountries.includes(
                        curr.countryName.toLowerCase()
                      )) ||
                    popularCountries.includes(curr.officialName.toLowerCase())
                )
                .map((popularCurrency, idx) => (
                  <>
                    {popularCurrency.currencySymbol && (
                      <div
                        id={`popular-curencies-${idx}`}
                        key={`popular-curencies-${idx}`}
                        onClickCapture={() => {
                          popularCurrency["isClicked"] = true;
                          setSelectedCurrency(popularCurrency);
                        }}
                        onClick={(e) => {
                          const previouslyActiveTag =
                            document.getElementsByClassName(
                              "a-f-content-search-header-main-active"
                            );
                          if (previouslyActiveTag.length > 0) {
                            previouslyActiveTag[0].className =
                              "a-f-content-search-header-main";
                          }
                          const currentTag = document.getElementById(
                            `popular-curencies-${idx}`
                          );
                          currentTag.className =
                            "a-f-content-search-header-main-active";
                          handleChooseCurrencyButtonDisplay();
                        }}
                        className="a-f-content-search-header-main"
                      >
                        <div className="a-f-content-search-header-main-container">
                          <div className="a-f-content-search-header-main-content">
                            <div className="a-f-content-search-header-image">
                              <img
                                alt="Country Flag"
                                src={popularCurrency.flag}
                              />
                            </div>
                            <div className="a-f-content-search-header-text-container">
                              <p className="a-f-content-search-header-text-header">
                                {popularCurrency.currencySymbol}
                              </p>
                              <p className="a-f-content-search-header-text-footer">
                                {popularCurrency.currencyName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>
            <div className="a-f-content-search-result-header">
              <p className="a-f-content-search-header-text">All Currencies </p>
              {!newSearchContent &&
                allCurrencies
                  .filter(
                    (curr) =>
                      curr.currencySymbol && curr.currencyName && curr.flag
                  )
                  .map((currency, idx) => (
                    <>
                      {currency.currencySymbol && (
                        <div
                          id={`all-curencies-${idx}`}
                          key={`all-curencies-${idx}`}
                          onClickCapture={() => {
                            currency["isClicked"] = true;
                            setSelectedCurrency(currency);
                          }}
                          onClick={(e) => {
                            const previouslyActiveTag =
                              document.getElementsByClassName(
                                "a-f-content-search-header-main-active"
                              );
                            if (previouslyActiveTag.length > 0) {
                              previouslyActiveTag[0].className =
                                "a-f-content-search-header-main";
                            }
                            const currentTag = document.getElementById(
                              `all-curencies-${idx}`
                            );
                            currentTag.className =
                              "a-f-content-search-header-main-active";
                            handleChooseCurrencyButtonDisplay();
                          }}
                          className="a-f-content-search-header-main"
                        >
                          <div className="a-f-content-search-header-main-container">
                            <div className="a-f-content-search-header-main-content">
                              <div className="a-f-content-search-header-image">
                                <img alt="Country Flag" src={currency.flag} />
                              </div>
                              <div className="a-f-content-search-header-text-container">
                                <p className="a-f-content-search-header-text-header">
                                  {currency.currencySymbol}
                                </p>
                                <p className="a-f-content-search-header-text-footer">
                                  {currency.currencyName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

              {newSearchContent &&
                newSearchContent.length > 0 &&
                newSearchContent
                  .filter(
                    (curr) =>
                      curr.currencySymbol && curr.currencyName && curr.flag
                  )
                  .map((currency, idx) => (
                    <>
                      {currency.currencySymbol && (
                        <div
                          id={`all-curencies-${idx}`}
                          key={`all-curencies-${idx}`}
                          onClickCapture={() => {
                            currency["isClicked"] = true;
                            setSelectedCurrency(currency);
                          }}
                          onClick={(e) => {
                            const previouslyActiveTag =
                              document.getElementsByClassName(
                                "a-f-content-search-header-main-active"
                              );
                            if (previouslyActiveTag.length > 0) {
                              previouslyActiveTag[0].className =
                                "a-f-content-search-header-main";
                            }
                            const currentTag = document.getElementById(
                              `all-curencies-${idx}`
                            );
                            currentTag.className =
                              "a-f-content-search-header-main-active";
                            handleChooseCurrencyButtonDisplay();
                          }}
                          className="a-f-content-search-header-main"
                        >
                          <div className="a-f-content-search-header-main-container">
                            <div className="a-f-content-search-header-main-content">
                              <div className="a-f-content-search-header-image">
                                <img alt="Country Flag" src={currency.flag} />
                              </div>
                              <div className="a-f-content-search-header-text-container">
                                <p className="a-f-content-search-header-text-header">
                                  {/* {currency.currencySymbol} */
}
//           {currency.nameArray?.length > 0 &&
//             currency.nameArray.map((nm, idx) => (
//               <>
//                 {!currency.searched.includes(
//                   nm.toLowerCase()
//                 ) && (
//                   <span
//                     key={idx}
//                     style={{
//                       color: "#B6B7C3",
//                     }}
//                   >
//                     {nm}
//                   </span>
//                 )}
//                 {currency.searched.includes(
//                   nm.toLowerCase()
//                 ) && <span key={idx}>{nm}</span>}
//               </>
//             ))}
//         </p>
//         // <p className="a-f-content-search-header-text-footer">
//         //   {currency.currencyName}
//         // </p>
//       </div>
//     </div>
//   </div>
// </div>
//     )}
//   </>
// ))}
// </div> */}

export default AddFunds;
