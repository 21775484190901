import popupCloseIcon from "../../assests/page/popup-close-icon.svg";
import expandIcon from "../../assests/page/chevron-right-icon.svg";
import backIcon from "../../assests/page/chevron-left.svg";
import notificationIcon from "../../assests/page/notifications.svg";
import settingsIcon from "../../assests/page/settings.svg";
import profilePicture from "../../assests/page/profile-picture.svg";
import accountIcon from "../../assests/page/account-balance-icon.svg";
import walletIcon from "../../assests/page/account-balance-wallet-icon.svg";
import demographyIcon from "../../assests/page/demography-icon.svg";
import debitCard from "../../assests/page/debit-card.svg";
import friends from "../../assests/page/friends.svg";
import aboutIcon from "../../assests/page/quick_reference.svg";
import questions from "../../assests/page/shield_question.svg";
import profilePictureBig from "../../assests/page/profile-picture-large.svg";
import editIcon from "../../assests/page/profile-pic-edit.svg";
import verifiedIcon from "../../assests/page/verified-checkmark.svg";
import keyIcon from "../../assests/page/key_vertical.svg";
import copyIcon from "../../assests/page/content_copy.svg";
import coSignersIcon from "../../assests/page/co-signers.svg";
import lockIcon from "../../assests/page/lock.svg";
import phoneIcon from "../../assests/page/phone.svg";
import logoutIcon from "../../assests/page/logout-red.svg";
import deleteIcon from "../../assests/page/delete-red.svg";
import overlayVector from "../../assests/page/overlay-vector.svg";
import visaIcon from "../../assests/page/visa-icon.svg";
import exposeCvvIcon from "../../assests/page/expose-icon.svg";
import hideCvvIcon from "../../assests/page/hide-cvv-icon.svg";
import toggleActive from "../../assests/page/Toggle-active.svg";
import ethereumIcon from "../../assests/overlay/ethereum-icon.svg";
import toggle from "../../assests/page/Toggle.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProfilePin from "./profilePin";
import ProfileEditContact from "./profileEditContact";
import ProfileEditCountry from "./profileEditCountry";
import ProfileCoSigner from "./profileCoSigner";
import ProfileChangePin from "./profileChangePin";
import GeneralPopupBackground from ".";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import { capitalizeFirstLetter } from "../../utils/helpers";
import { useAppKit, useWalletInfo } from "@reown/appkit/react";
import { wagmiAdapter } from "../..";
import { SwitchNetwork } from "./network";
import { chainDetails } from "../../utils/config";

const ProfilePopup = (props) => {
  const [showProfileDetails, setShowProfileDetails] = useState(
    props.showProfileDetails || false
  );
  const [customPortal, setCustomPortal] = useState(props.customPortal || "");
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [customPopupContent, setCustomPopupContent] = useState(null);

  const {
    userAddress,
    userFullName,
    userFirstName,
    userEmail,
    userCountry,
    userCountryCode,
    userBalance,
    userRole,
    userKycStatus,
    userImageLink,
    userType,
    isConnected,
  } = useSelector((state) => state.user);
  const { chainId } = useSelector((state) => state.network);
  const appKitModal = useAppKit();

  const navigate = useNavigate();

  return (
    <>
      {/* {showCustomPopup && (
        <GeneralPopupBackground
          popupContent={customPopupContent}
          popupContentPosition={"center"}
        />
      )} */}
      <div className="search-filter-popup">
        <div className="search-filter-popup-content">
          <div className="asset-info-popup-header">
            <div className="asset-info-popup-header-main">
              <div className="asset-info-popup-header-main-text">
                {showProfileDetails && (
                  <img
                    onClick={() => {
                      setShowProfileDetails(false);
                    }}
                    className="profile-popup-icon"
                    alt="Back Icon"
                    src={backIcon}
                  />
                )}
                {!showProfileDetails && (
                  <>
                    {customPortal === "" && (
                      <p className="search-filter-popup-header-text">Profile</p>
                    )}
                    {customPortal === "settings" && (
                      <>
                        <img
                          onClick={() => {
                            setCustomPortal("");
                          }}
                          className="profile-popup-icon"
                          alt="Back Icon"
                          src={backIcon}
                        />
                        <p className="search-filter-popup-header-text">
                          Account Settings
                        </p>
                      </>
                    )}
                    {customPortal === "card" && (
                      <>
                        <img
                          onClick={() => {
                            setCustomPortal("");
                          }}
                          className="profile-popup-icon"
                          alt="Back Icon"
                          src={backIcon}
                        />
                        <p className="search-filter-popup-header-text">
                          Card Settings
                        </p>
                      </>
                    )}
                  </>
                )}
                {showProfileDetails && (
                  <p className="search-filter-popup-header-text">
                    Profile Details
                  </p>
                )}
              </div>
              <div className="asset-info-popup-icons">
                {!showProfileDetails && (
                  <>
                    {customPortal === "" && (
                      <>
                        {/* <img
                          className="profile-popup-icon"
                          alt="Close Icon"
                          src={notificationIcon}
                        /> */}
                        <img
                          onClick={() => {
                            setCustomPortal("settings");
                          }}
                          className="profile-popup-icon"
                          alt="Close Icon"
                          src={settingsIcon}
                        />
                      </>
                    )}
                  </>
                )}
                <img
                  onClick={() => {
                    props.setPopupContentPosition("center");
                    props.setPopupContent(null);
                    if (props.setFavouriteClicked) {
                      props.setFavouriteClicked(false);
                    }
                    props.setShowPopup(false);
                    navigate(
                      window.location.href
                        .replace(window.location.search, "")
                        .replace(window.location.origin, "")
                    );
                  }}
                  className="profile-popup-icon"
                  alt="Close Icon"
                  src={popupCloseIcon}
                />
              </div>
            </div>
          </div>

          <div
            style={
              customPortal === "card" ? { gap: "20px", overflow: "auto" } : {}
            }
            className="profile-popup-content"
          >
            {!showProfileDetails && (
              <>
                {customPortal === "" && (
                  <>
                    <div
                      onClick={() => {
                        setShowProfileDetails(true);
                      }}
                      className="profile-popup-content-first"
                    >
                      <div className="profile-popup-content-first-container">
                        <div className="profile-popup-content-first-icon">
                          {userImageLink.length > 0 && (
                            <img src={userImageLink} alt="Profile Icon" />
                          )}
                          {userImageLink === "" && (
                            <Avatar
                              name={userFullName.replace("undefined", "")}
                            />
                          )}
                        </div>
                        <div className="profile-popup-content-first-text-container">
                          {userFullName.replace("undefined", "").length > 0 && (
                            <p className="profile-popup-content-first-text-title">
                              {userFullName.replace("undefined", "")}
                            </p>
                          )}

                          {userFullName === "" && (
                            <p className="profile-popup-content-first-text-title">
                              No Name
                            </p>
                          )}

                          <p className="profile-popup-content-first-text-label">
                            Profile Details
                          </p>
                        </div>
                      </div>
                      <img src={expandIcon} alt="Expand Icon" />
                    </div>

                    <div className="profile-popup-content-third">
                      <p className="profile-popup-content-second-text">Chain</p>
                      <div className="profile-popup-content-third-content-line"></div>
                      <div className="profile-popup-content-third-container">
                        <div className="profile-popup-content-third-content">
                          <div className="profile-popup-content-third-content-text-container">
                            {chainDetails[Number(chainId)]?.icon && (
                              <img
                                src={chainDetails[Number(chainId)]?.icon}
                                alt=""
                              />
                            )}
                            <p className="profile-popup-content-third-content-text">
                              {
                                wagmiAdapter?.wagmiChains?.find(
                                  (chn) => chn.id === chainId
                                ).name
                              }
                            </p>
                          </div>
                          <button
                            onClick={() => {
                              props.setFavouriteClicked(false);
                              props.setPopupContentPosition("center");
                              props.setPopupContent(
                                <SwitchNetwork
                                  setShowPopup={props.setShowPopup}
                                  setPopupContent={props.setPopupContent}
                                  setPopupContentPosition={
                                    props.setPopupContentPosition
                                  }
                                  setFavouriteClicked={
                                    props.setFavouriteClicked
                                  }
                                  setCustomLoading={props.setCustomLoading}
                                />
                              );
                              props.setShowPopup(true);
                            }}
                            className="profile-popup-content-third-button"
                          >
                            <p className="profile-popup-content-third-button-text">
                              Switch
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* <div className="profile-popup-content-second">
                      <p className="profile-popup-content-second-text">
                        Payment & Accounts
                      </p>
                      <div className="profile-popup-content-second-container">
                        <div
                          onClick={() => {
                            setCustomPortal("card");
                          }}
                          className="profile-popup-content-second-content"
                        >
                          <div className="profile-popup-content-second-content-icon">
                            <img src={accountIcon} alt="" />
                          </div>
                          <p className="profile-popup-content-second-content-text">
                            My Wallets
                          </p>
                        </div>

                        <div
                          onClick={() => {
                            setCustomPortal("card");
                          }}
                          className="profile-popup-content-second-content"
                        >
                          <div className="profile-popup-content-second-content-icon">
                            <img src={debitCard} alt="" />
                          </div>
                          <p className="profile-popup-content-second-content-text">
                            Debit Card
                          </p>
                        </div>

                        <div
                          onClick={() => {
                            setCustomPortal("card");
                          }}
                          className="profile-popup-content-second-content"
                        >
                          <div className="profile-popup-content-second-content-icon">
                            <img src={walletIcon} alt="" />
                          </div>
                          <p className="profile-popup-content-second-content-text">
                            Bank Accounts
                          </p>
                        </div>

                        <div
                          onClick={() => {
                            setCustomPortal("card");
                          }}
                          className="profile-popup-content-second-content"
                        >
                          <div className="profile-popup-content-second-content-icon">
                            <img src={demographyIcon} alt="" />
                          </div>
                          <p className="profile-popup-content-second-content-text">
                            Broker Accounts
                          </p>
                        </div>
                      </div>
                    </div> */}

                    <div className="profile-popup-content-third">
                      <p className="profile-popup-content-second-text">
                        Information
                      </p>
                      <div className="profile-popup-content-third-content-line"></div>
                      <div className="profile-popup-content-third-container">
                        {/* <div className="profile-popup-content-third-content">
                          <div className="profile-popup-content-third-content-text-container">
                            <img src={friends} alt="" />
                            <p className="profile-popup-content-third-content-text">
                              Invite Friends
                            </p>
                          </div>
                          <img src={expandIcon} alt="Expand Icon" />
                        </div> */}
                        {/* <div className="profile-popup-content-third-content-line"></div> */}

                        <div
                          onClick={() => {
                            window.open(
                              "https://verified.network/about-us",
                              "_blank"
                            );
                          }}
                          className="profile-popup-content-third-content"
                        >
                          <div className="profile-popup-content-third-content-text-container">
                            <img src={aboutIcon} alt="" />
                            <p className="profile-popup-content-third-content-text">
                              About Us
                            </p>
                          </div>
                          <img src={expandIcon} alt="Expand Icon" />
                        </div>
                        <div className="profile-popup-content-third-content-line"></div>

                        <div
                          onClick={() => {
                            window.open(
                              "https://verified.network/helpdesk",
                              "_blank"
                            );
                          }}
                          className="profile-popup-content-third-content"
                        >
                          <div className="profile-popup-content-third-content-text-container">
                            <img src={questions} alt="" />
                            <p className="profile-popup-content-third-content-text">
                              Help & Support
                            </p>
                          </div>
                          <img src={expandIcon} alt="Expand Icon" />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {customPortal === "settings" && (
                  <>
                    <p className="profile-popup-content-settings-text">
                      Privacy and Security
                    </p>
                    <div className="profile-popup-content-settings-container">
                      {/* <div className="profile-popup-content-settings-first">
                        <div className="profile-popup-content-settings-first-text-container">
                          <img src={keyIcon} alt="" />

                          <p className="profile-popup-content-settings-first-text">
                            Verified Wallet Password Key
                          </p>
                        </div>

                        <img
                          // onClick={() => {
                          //   setCustomPopupContent(
                          //     <BorrowWarningPopup
                          //       setShowCustomPopup={setShowCustomPopup}
                          //       setShowPopup={props.setShowPopup}
                          //       setPopupContent={props.setPopupContent}
                          //       setPopupContentPosition={props.setPopupContentPosition}
                          //       setCustomPopupContent={setCustomPopupContent}
                          //     />
                          //   );
                          //   setShowCustomPopup(true);
                          // }}
                          className="profile-popup-icon"
                          src={copyIcon}
                          alt=""
                        />
                      </div> */}

                      {/* <div className="profile-popup-content-settings-line"></div> */}

                      {/* <div
                        onClick={() => {
                          props.setPopupContentPosition("flex-end");
                          props.setPopupContent(
                            <ProfileCoSigner
                              setShowPopup={props.setShowPopup}
                              setPopupContent={props.setPopupContent}
                              setPopupContentPosition={
                                props.setPopupContentPosition
                              }
                              setFavouriteClicked={props.setFavouriteClicked}
                            />
                          );
                        }}
                        className="profile-popup-content-settings-second"
                      >
                        <div className="profile-popup-content-settings-first-text-container">
                          <img src={coSignersIcon} alt="" />

                          <p className="profile-popup-content-settings-first-text">
                            My Co-signers
                          </p>
                        </div>

                        <img src={expandIcon} alt="" />
                      </div>

                      <div className="profile-popup-content-settings-line"></div> */}

                      {/* <div
                        onClick={() => {
                          props.setPopupContentPosition("flex-end");
                          props.setPopupContent(
                            <ProfilePin
                              setShowPopup={props.setShowPopup}
                              setPopupContent={props.setPopupContent}
                              setPopupContentPosition={
                                props.setPopupContentPosition
                              }
                              setFavouriteClicked={props.setFavouriteClicked}
                              customPortal="settings"
                              showProfileDetails={false}
                              nextPopup={
                                <ProfileChangePin
                                  setShowPopup={props.setShowPopup}
                                  setPopupContent={props.setPopupContent}
                                  setPopupContentPosition={
                                    props.setPopupContentPosition
                                  }
                                  setFavouriteClicked={
                                    props.setFavouriteClicked
                                  }
                                />
                              }
                            />
                          );
                        }}
                        className="profile-popup-content-settings-second"
                      >
                        <div className="profile-popup-content-settings-first-text-container">
                          <img src={lockIcon} alt="" />

                          <p className="profile-popup-content-settings-first-text">
                            Change PIN
                          </p>
                        </div>

                        <img src={expandIcon} alt="" />
                      </div> */}
                      {/* 
                      <div className="profile-popup-content-settings-line"></div> */}

                      {/* <div className="profile-popup-content-settings-second">
                        <div className="profile-popup-content-settings-first-text-container">
                          <img src={phoneIcon} alt="" />

                          <p className="profile-popup-content-settings-first-text">
                            Active Devices
                          </p>
                        </div>

                        <img src={expandIcon} alt="" />
                      </div> */}

                      {/* <div className="profile-popup-content-settings-line"></div> */}

                      <div
                        onClick={() => {
                          if (userAddress.length > 0) {
                            appKitModal.open();
                          }
                        }}
                        className="profile-popup-content-settings-second"
                      >
                        <div className="profile-popup-content-settings-first-text-container">
                          <img src={logoutIcon} alt="" />

                          <p className="profile-popup-content-settings-first-text-red">
                            Log Out
                          </p>
                        </div>

                        <img src={expandIcon} alt="" />
                      </div>

                      <div className="profile-popup-content-settings-line"></div>

                      {/* <div className="profile-popup-content-settings-second">
                        <div className="profile-popup-content-settings-first-text-container">
                          <img src={deleteIcon} alt="" />

                          <p className="profile-popup-content-settings-first-text-red">
                            Delete Account
                          </p>
                        </div>

                        <img src={expandIcon} alt="" />
                      </div> */}
                    </div>
                  </>
                )}

                {customPortal === "card" && (
                  <>
                    <div className="profile-popup-card-header">
                      <div className="profile-popup-card-header-first">
                        <div className="profile-popup-card-header-first-container">
                          <div className="profile-popup-card-header-first-header">
                            <img
                              style={{
                                width: "235px",
                                height: "201px",
                                flexShrink: "0",
                              }}
                              className="g-s-content-overlay"
                              alt="Overlay"
                              src={overlayVector}
                            />
                            <img
                              style={{
                                width: "120px",
                              }}
                              className="g-s-content-overlay"
                              alt="Overlay"
                              src={overlayVector}
                            />
                            <p className="profile-popup-card-header-first-header-text">
                              SRINATH SANGVIKAR
                            </p>
                            <div className="profile-popup-card-header-first-header-label">
                              <p className="profile-popup-card-header-first-header-label-text">
                                8582
                              </p>
                              <p className="profile-popup-card-header-first-header-label-text">
                                1377
                              </p>
                              <p className="profile-popup-card-header-first-header-label-text">
                                9657
                              </p>
                              <p className="profile-popup-card-header-first-header-label-text">
                                3221
                              </p>
                            </div>
                          </div>

                          <div className="profile-popup-card-header-first-footer">
                            <div className="profile-popup-card-header-first-footer-first">
                              <div className="profile-popup-card-header-first-footer-first-body">
                                <p className="profile-popup-card-header-first-footer-first-body-text">
                                  Expires on
                                </p>

                                <p className="profile-popup-card-header-first-footer-first-body-label">
                                  02/22
                                </p>
                              </div>

                              <div className="profile-popup-card-header-first-footer-first-main">
                                <p className="profile-popup-card-header-first-footer-first-body-text">
                                  CVV
                                </p>

                                <p className="profile-popup-card-header-first-footer-first-body-label">
                                  272
                                </p>
                              </div>

                              <div className="profile-popup-card-header-first-footer-first-icon">
                                <img src={hideCvvIcon} alt="Hide CVV Icon" />
                              </div>
                            </div>

                            <img src={visaIcon} alt="Card Issuer Icon" />
                          </div>

                          <div className="profile-popup-card-header-second">
                            <div className="profile-popup-card-header-second-container">
                              <p className="profile-popup-card-header-second-text">
                                View Balance
                              </p>
                              <div className="profile-popup-card-header-second-label">
                                <p className="profile-popup-card-header-second-label-title">
                                  ₹
                                </p>
                                <p className="profile-popup-card-header-second-label-text">
                                  23,221
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="profile-popup-card-footer">
                      <div className="profile-popup-card-footer-container">
                        <p className="profile-popup-card-footer-text">
                          Card Actions
                        </p>
                        <div className="profile-popup-card-footer-body">
                          <div className="profile-popup-card-footer-body-content">
                            <p className="profile-popup-card-footer-body-text">
                              Reset PIN
                            </p>
                            <img src={expandIcon} alt="Expand Icon" />
                          </div>

                          <div className="profile-popup-card-footer-body-content">
                            <p className="profile-popup-card-footer-body-text">
                              Enable/Disable Wallets
                            </p>
                            <img src={toggleActive} alt="Expand Icon" />
                          </div>

                          <div className="profile-popup-card-footer-body-content">
                            <p className="profile-popup-card-footer-body-text">
                              Online Transactions
                            </p>
                            <img src={toggleActive} alt="Expand Icon" />
                          </div>

                          <div className="profile-popup-card-footer-body-content">
                            <p className="profile-popup-card-footer-body-text">
                              Tap and Pay Transactions
                            </p>
                            <img src={toggleActive} alt="Expand Icon" />
                          </div>

                          <div
                            style={{ paddingBottom: "0" }}
                            className="profile-popup-card-footer-body-content"
                          >
                            <p className="profile-popup-card-footer-body-text">
                              Cancel Card
                            </p>
                            <img src={toggleActive} alt="Expand Icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {showProfileDetails && (
              <>
                <div className="profile-popup-show-details-header">
                  <div className="profile-popup-show-details-header-icon">
                    {userImageLink.length > 0 && (
                      <img src={userImageLink} alt="Profile Icon" />
                    )}
                    {userImageLink === "" && (
                      <Avatar name={userFullName.replace("undefined", "")} />
                    )}

                    <div className="profile-popup-show-details-header-icon-container">
                      <img
                        src={editIcon}
                        alt="Edit Icon"
                        className="profile-popup-clicakable-icon"
                      />
                    </div>
                  </div>

                  <div className="profile-popup-show-details-header-text-container">
                    {userFullName.replace("undefined", "").length > 0 && (
                      <p className="profile-popup-show-details-header-title">
                        {userFullName.replace("undefined", "")}
                      </p>
                    )}

                    {userFullName === "" && (
                      <p className="profile-popup-show-details-header-title">
                        No Name
                      </p>
                    )}

                    {Number(userKycStatus) === 3 && (
                      <div className="profile-popup-show-details-header-label-container">
                        <img src={verifiedIcon} alt="Verified Icon" />
                        <p className="profile-popup-show-details-header-label">
                          KYC Verified
                        </p>
                      </div>
                    )}
                    {Number(userKycStatus) !== 3 && (
                      <div className="profile-popup-show-details-header-label-container">
                        <p className="profile-popup-show-details-header-label">
                          KYC Not Verified
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="profile-popup-show-details-body">
                  <p className="profile-popup-show-details-body-title">
                    Personal Details
                  </p>
                  <div className="profile-popup-show-details-body-container">
                    <div className="profile-popup-show-details-body-content-first">
                      <div className="profile-popup-show-details-body-content-first-container">
                        <p className="profile-popup-show-details-body-content-first-title">
                          My Verified Wallet ID
                        </p>
                        <p className="profile-popup-show-details-body-content-first-label">
                          {/* {`${userAddress.substring(
                            0,
                            5
                          )}...${userAddress.substring(
                            userAddress.length - 3,
                            userAddress.length
                          )}`} */}
                          {userAddress}
                        </p>
                      </div>
                    </div>

                    <div className="profile-popup-show-details-body-content-line"></div>

                    <div className="profile-popup-show-details-body-content-second">
                      <div className="profile-popup-show-details-body-content-first-container">
                        <p className="profile-popup-show-details-body-content-first-title">
                          Default Country of Registration
                        </p>
                        <p className="profile-popup-show-details-body-content-first-label">
                          {userCountry}
                        </p>
                      </div>
                      {userCountry.length > 0 && (
                        <img
                          onClick={() => {
                            props.setPopupContentPosition("flex-end");
                            props.setPopupContent(
                              <ProfilePin
                                setShowPopup={props.setShowPopup}
                                setPopupContent={props.setPopupContent}
                                setPopupContentPosition={
                                  props.setPopupContentPosition
                                }
                                setFavouriteClicked={props.setFavouriteClicked}
                                showProfileDetails={true}
                                nextPopup={
                                  <ProfileEditCountry
                                    setShowPopup={props.setShowPopup}
                                    setPopupContent={props.setPopupContent}
                                    setPopupContentPosition={
                                      props.setPopupContentPosition
                                    }
                                    setFavouriteClicked={
                                      props.setFavouriteClicked
                                    }
                                  />
                                }
                              />
                            );
                          }}
                          src={editIcon}
                          alt="Edit Icon"
                          className="profile-popup-clicakable-icon"
                        />
                      )}
                    </div>

                    <div className="profile-popup-show-details-body-content-line"></div>

                    <div className="profile-popup-show-details-body-content-first">
                      <div className="profile-popup-show-details-body-content-first-container">
                        <p className="profile-popup-show-details-body-content-first-title">
                          Name ( As per govt ID )
                        </p>
                        {userFullName.replace("undefined", "").length > 0 && (
                          <p className="profile-popup-show-details-body-content-first-label">
                            {userFullName.replace("undefined", "")}
                          </p>
                        )}

                        {userFullName === "" && (
                          <p className="profile-popup-show-details-body-content-first-label">
                            No Name
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="profile-popup-show-details-body-content-line"></div>

                    <div className="profile-popup-show-details-body-content-first">
                      <div className="profile-popup-show-details-body-content-first-container">
                        <p className="profile-popup-show-details-body-content-first-title">
                          My Role
                        </p>
                        <p className="profile-popup-show-details-body-content-first-label">
                          {capitalizeFirstLetter(userRole)}
                        </p>
                      </div>
                    </div>

                    {userType.length > 0 && (
                      <div className="profile-popup-show-details-body-content-line"></div>
                    )}

                    {userType.length > 0 && (
                      <div className="profile-popup-show-details-body-content-first">
                        <div className="profile-popup-show-details-body-content-first-container">
                          <p className="profile-popup-show-details-body-content-first-title">
                            My Role Type
                          </p>
                          <p className="profile-popup-show-details-body-content-first-label">
                            {capitalizeFirstLetter(userType)}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="profile-popup-show-details-body-content-line"></div>

                    <div className="profile-popup-show-details-body-content-second">
                      <div className="profile-popup-show-details-body-content-first-container">
                        <p className="profile-popup-show-details-body-content-first-title">
                          Email Address
                        </p>
                        <p className="profile-popup-show-details-body-content-first-label">
                          {userEmail}
                        </p>
                      </div>
                      {/* <img
                        onClick={() => {
                          props.setPopupContentPosition("flex-end");
                          props.setPopupContent(
                            <ProfilePin
                              setShowPopup={props.setShowPopup}
                              setPopupContent={props.setPopupContent}
                              setPopupContentPosition={
                                props.setPopupContentPosition
                              }
                              setFavouriteClicked={props.setFavouriteClicked}
                              showProfileDetails={true}
                              nextPopup={
                                <ProfileEditContact
                                  setShowPopup={props.setShowPopup}
                                  setPopupContent={props.setPopupContent}
                                  setPopupContentPosition={
                                    props.setPopupContentPosition
                                  }
                                  setFavouriteClicked={
                                    props.setFavouriteClicked
                                  }
                                />
                              }
                            />
                          );
                        }}
                        src={editIcon}
                        alt="Edit Icon"
                        className="profile-popup-clicakable-icon"
                      /> */}
                    </div>

                    {/* <div className="profile-popup-show-details-body-content-line"></div> */}

                    {/* <div className="profile-popup-show-details-body-content-first">
                      <div className="profile-popup-show-details-body-content-first-container">
                        <p className="profile-popup-show-details-body-content-first-title">
                          PAN ID Details
                        </p>
                        <p className="profile-popup-show-details-body-content-first-label">
                          CGWPR1234G
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePopup;
