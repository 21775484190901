import popupCloseIcon from "../../assests/page/popup-close-icon.svg";
import backIcon from "../../assests/page/chevron-left.svg";
import alarmIcon from "../../assests/sideBar/alarm.svg";
import favoriteIcon from "../../assests/overlay/Star.svg";
import favoriteClickedIcon from "../../assests/overlay/star-clicked.svg";
import productLogo from "../../assests/page/product-logo.svg";
import graphIcon from "../../assests/page/graph-image.svg";
import graphNumbersIcon from "../../assests/page/graph-numbers-image.svg";
import increaseIcon from "../../assests/page/add.svg";
import decreaseIcon from "../../assests/page/remove.svg";
import infoIcon from "../../assests/page/info-icon.svg";
import radioButtonIcon from "../../assests/page/radio-button.svg";
import radioButtonClickedIcon from "../../assests/page/radio-button-clicked.svg";
import walletIcon from "../../assests/page/wallet-icon.svg";
import priceUp from "../../assests/page/asset-price-up-icon.svg";
import priceDown from "../../assests/page/asset-price-up-icon.svg";
import collateralBank from "../../assests/page/collateral-bank-icon.svg";
import exandIcon from "../../assests/page/chevron-right.svg";
import maximizeIcon from "../../assests/overlay/maximize-icon.svg";
import minimizeIcon from "../../assests/overlay/minimize-icon.svg";
import bankAccountIcon from "../../assests/page/bank-account-icon.svg";
import collateralExpand from "../../assests/page/asset-collateral-right.svg";
import expandMoreIcon from "../../assests/page/expand-more-blue.svg";
import defaultExpandMoreIcon from "../../assests/page/expand-more-icon.svg";
import typeIcon from "../../assests/overlay/type-icon.svg";
import volumeIcon from "../../assests/overlay/volume-icon.svg";
import calenderIcon from "../../assests/overlay/calender-icon.svg";
import categoryIcon from "../../assests/overlay/category-icon.svg";
import athIcon from "../../assests/overlay/ath-icon.svg";
import atlIcon from "../../assests/overlay/atl-icon.svg";
import fixedFeeIcon from "../../assests/overlay/trade-fee-icon.svg";
import priceIndIcon from "../../assests/overlay/price-ind-icon.svg";
import priceDotIcon from "../../assests/overlay/price-dot-icon.svg";
import bulbIcon from "../../assests/overlay/red-bulb-icon.svg";
import calenderBgIcon from "../../assests/overlay/calender-bg-icon.svg";
import netDividendIcon from "../../assests/overlay/net-dividend-icon.svg";
import dividendYieldIcon from "../../assests/overlay/dividend-yield-icon.svg";
import payoutIcon from "../../assests/overlay/payout-icon.svg";
import expandForwardIcon from "../../assests/overlay/forward-expand-icon.svg";
import candleStickIcon from "../../assests/overlay/candle-stick-icon.svg";
import lineChartIcon from "../../assests/overlay/line-chart-icon.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddFunds from "./addFunds";
import PageSeperator from "../overlays/pageSeperator";
import Avatar from "react-avatar";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  authenticateKYCStatus,
  calculatePrimaryAndSecondarySOR,
  capitalizeFirstLetter,
  convertFromBlockTimestamp,
} from "../../utils/helpers";
import { toast } from "react-toastify";
import {
  createPrimaryOrder,
  createSecondaryAndMarginOrder,
} from "../../services/contracts/vaultContract";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { errorToast, PoolType, successToast } from "../../utils/constants";
import ERC20ABI from "../../utils/abis/ERC20.json";
import { contractAddress } from "@verified-network/verified-sdk";
import { useAppKit } from "@reown/appkit/react";
import expandIcon from "../../assests/page/portfolio-expand.svg";
import { getTokensSwapRate, swapTokenWithParaSwap } from "../../lib/paraswap";
import { ChainConfig, chainDetails } from "../../utils/config";
import { AreaChart, CandleStickChart } from "../overlays/charts";

const AssetInfoPage = (props) => {
  const [productDetails, setProductDetails] = useState("overview");
  const [grahDetails, setGrahDetails] = useState("price");
  const [candleStickClicked, setCandleStickClicked] = useState(false);
  const [businessTag, setBusinessTag] = useState(null);
  const [financialTag, setFinancialTag] = useState(null);
  const [showAssetTrading, setShowAssetTrading] = useState(false);
  const [tradeType, setTradeType] = useState("");
  const [tradeCategory, setTradCategory] = useState("market");
  const [tradePrice, setTradePrice] = useState(0);
  const [tradeAmount, setTradeAmount] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(
    props.productClicked.currentPrice
  );
  const [limitPrice, setLimitPrice] = useState(
    props.productClicked.currentPrice
  );
  const [isTradeInput, setIsTradeInput] = useState(false);
  const [payWithVerifiedWallet, setPayWithVerifiedWallet] = useState(false);
  const [payWithBank, setPayWithBank] = useState(false);
  const [collateralAdded, setCollateralAdded] = useState(0);
  const [showSetCollateral, setShowSetCollateral] = useState(false);
  const [userBalances, setUserBalances] = useState({
    security: 0.0,
    currency: 0.0,
  });

  const [sorValue, setSorValue] = useState({
    amountOut: 0.0,
    slippage: 0.0,
  });
  const [selectedCurrency, setSelectedCurrency] = useState({
    symbol: props.productClicked?.currencySymbol,
    img: "",
    decimals: props.productClicked.currencyDecimals,
    balance: 0,
    address: props.productClicked?.currency,
  });
  const [showCurrenciesDropdown, setShowCurrenciesDropdown] = useState(false);
  const [businessViewMoreClicked, setBusinessViewMoreClicked] = useState(false);
  const [paraswapDetails, setParaswapDetails] = useState({});
  const [financialViewMoreClicked, setFinancialViewMoreClicked] =
    useState(false);
  const [swapSlippage, setSwapSlippage] = useState(0.5);
  const [trySwapAgain, setTrySwapAgain] = useState(true);
  const [isMaximized, setIsMaximized] = useState(false);
  const [graphTime, setGraphTime] = useState({
    label: "1h",
    time: 60 * 60,
  });

  const { chainId, provider, web3 } = useSelector((state) => state.network);

  const { userAddress } = useSelector((state) => state.user);

  const appKitModal = useAppKit();

  const navigate = useNavigate();

  useEffect(() => {
    const handleSetTags = () => {
      const businessPTag = document.getElementById(
        "asset-info-popup-business-summary"
      );
      const financialPTag = document.getElementById(
        "asset-info-popup-financial-summary"
      );
      if (businessPTag) {
        setBusinessTag(businessPTag);
      }
      if (financialPTag) {
        setFinancialTag(financialPTag);
      }
    };

    handleSetTags();

    return () => {};
  });

  useEffect(() => {
    const handleParaswapRate = async () => {
      let tokenFrom, tokenTo, tokenFromDecimals, amountToSwap;
      if (tradeType === "sell") {
        tokenFrom = props.productClicked.currency;
        tokenFromDecimals = Number(props.productClicked.currencyDecimals);
        tokenTo = selectedCurrency.address;
        amountToSwap = Number(sorValue.amountOut);
      } else if (tradeType === "buy") {
        tokenFrom = selectedCurrency.address;
        tokenFromDecimals = Number(selectedCurrency.decimals);
        tokenTo = props.productClicked.currency;

        amountToSwap =
          props.productClicked.poolType === PoolType.secondaryPool
            ? Number(tradeAmount)
            : props.productClicked.poolType === PoolType.primaryPool
            ? Number(sorValue.amountOut)
            : 0;
      }

      if (amountToSwap > 0) {
        const paraswapRate = await getTokensSwapRate(
          userAddress,
          chainId,
          provider,
          tradeType,
          tokenFrom,
          tokenTo,
          tokenFromDecimals,
          amountToSwap,
          swapSlippage
        );
        setParaswapDetails(paraswapRate);
      } else {
        setParaswapDetails({});
      }
    };

    if (
      userAddress &&
      chainId > 0 &&
      provider?.getSigner &&
      props?.productClicked?.currency &&
      selectedCurrency?.address?.length > 0 &&
      selectedCurrency.address !== props.productClicked.currency &&
      props.productClicked?.poolType !== PoolType.marginPool &&
      trySwapAgain
    ) {
      handleParaswapRate();
    } else if (!trySwapAgain) {
      setParaswapDetails({ amount: 0 });
    } else {
      setParaswapDetails({});
    }
  }, [
    userAddress,
    chainId,
    provider,
    props.productClicked.currency,
    props.productClicked.currencyDecimals,
    selectedCurrency.address,
    selectedCurrency.decimals,
    sorValue,
    tradeAmount,
    tradeType,
    props.productClicked.poolType,
    trySwapAgain,
    swapSlippage,
  ]);

  useEffect(() => {
    const getBalances = async () => {
      let securityBalance, currencyBalance;
      if (userAddress && props.productClicked.security && web3) {
        const securityBalanceRaw = await new web3.eth.Contract(
          ERC20ABI,
          props.productClicked.security
        ).methods
          .balanceOf(userAddress)
          .call();
        securityBalance = ethers.utils.formatUnits(
          securityBalanceRaw,
          props.productClicked.securityDecimals
        );
      }
      if (userAddress && selectedCurrency.address && web3) {
        const currencyBalanceRaw = await new web3.eth.Contract(
          ERC20ABI,
          selectedCurrency.address
        ).methods
          .balanceOf(userAddress)
          .call();
        currencyBalance = ethers.utils.formatUnits(
          currencyBalanceRaw,
          selectedCurrency.decimals.toString()
        );
      }
      // if (
      //   userAddress &&
      //   selectedCurrency.address &&
      //   selectedCurrency.decimals &&
      //   web3
      // ) {
      //   const selectedCurrencyBalanceRaw = await new web3.eth.Contract(
      //     ERC20ABI,
      //     selectedCurrency.address
      //   ).methods
      //     .balanceOf(userAddress)
      //     .call();
      //   currencyBalance = ethers.utils.formatUnits(
      //     selectedCurrencyBalanceRaw,
      //     Number(selectedCurrency.decimals)
      //   );
      // }
      setUserBalances({
        security: securityBalance,
        currency: currencyBalance,
      });
    };

    getBalances();
  }, [
    props.productClicked.security,
    props.productClicked.securityDecimals,
    props.productClicked.currency,
    props.productClicked.currencyDecimals,
    web3,
    userAddress,
    selectedCurrency.address,
    selectedCurrency.decimals,
  ]);

  const updatePointerPosition = (price, defaultPosition) => {
    const pointer = document.getElementById("price-range-page-container");
    const pointerDiv = document.getElementById("price-range-page");
    const pointerText = document.getElementById("price-range-page-text");
    const pointerDot = document.getElementById("price-range-line-dot");
    const rangeDiv = document.getElementById("price-range-line");

    if (pointer && pointerDiv && pointerText && pointerDot && rangeDiv) {
      const pointerWidth = pointer.offsetWidth;
      const newPosition =
        ((price - Number(props.productClicked?.lowestPrice)) /
          (Number(props.productClicked?.highestPrice) -
            Number(props.productClicked?.lowestPrice))) *
        pointerWidth;
      pointerDot.style.left = defaultPosition
        ? `${rangeDiv.offsetWidth / 2 + pointerDiv.offsetWidth / 2}px`
        : `${newPosition - pointerDot.offsetWidth / 2}px`;
      pointerDiv.style.left = defaultPosition
        ? `${rangeDiv.offsetWidth / 2 - pointerDiv.offsetWidth / 2}px`
        : `${newPosition - pointerDiv.offsetWidth / 2}px`;
      pointerText.innerText = `${price} ${props.productClicked?.currencyFiatSymbol}`;
    }
  };

  // const chartOptions =
  //   grahDetails === "price"
  //     ? {
  //         title: {
  //           text: `${props.productClicked.securityName} ${capitalizeFirstLetter(
  //             grahDetails
  //           )} Chart`,
  //         },
  //         chart: {
  //           type: "line",
  //           zoomType: "x",
  //         },
  //         subtitle: {
  //           text: "Source: Verified Network",
  //         },
  //         xAxis: {
  //           type: "datetime",
  //           title: {
  //             text: "Date",
  //           },
  //           dateTimeLabelFormats: {
  //             day: "%e %b",
  //             month: "%b %Y",
  //           },
  //           labels: {
  //             enabled: true,
  //           },
  //         },
  //         yAxis: [
  //           {
  //             title: {
  //               text: "Price (USD)",
  //             },
  //             opposite: true,
  //           },
  //           // {
  //           //   title: {
  //           //     text: "Volume",
  //           //   },
  //           //   opposite: false,
  //           //   linkedTo: 0,
  //           //   visible: false,
  //           // },
  //         ],
  //         tooltip: {
  //           shared: true,
  //           crosshairs: true,
  //         },
  //         credits: {
  //           enabled: false,
  //         },
  //         series: [
  //           {
  //             name: `${
  //               props.productClicked.securitySymbol
  //             } ${capitalizeFirstLetter(grahDetails)}`,
  //             data: props.productClicked.prices,
  //             tooltip: {
  //               valueDecimals: 4,
  //             },
  //             color: "#7A76FF",
  //           },
  //           {
  //             name: `${props.productClicked.securitySymbol}  Volume`,
  //             data: props.productClicked.prices,
  //             type: "area",
  //             color: {
  //               linearGradient: {
  //                 x1: 0,
  //                 y1: 0,
  //                 x2: 0,
  //                 y2: 1,
  //               },
  //               stops: [
  //                 [0, "#A29FFF"],
  //                 [1, "rgba(255, 255, 255, 0)"],
  //               ],
  //             },
  //             fillOpacity: 0.3,
  //             yAxis: 0,
  //             zIndex: 0,
  //           },
  //           // {
  //           //   name: `${props.productClicked.securitySymbol} Volume`,
  //           //   data: props.productClicked.volume,
  //           //   // marker: {
  //           //   //   enabled: false,
  //           //   // },
  //           //   type: "area",
  //           //   color: "#A29FFF",
  //           //   fillOpaacity: 0.3,
  //           //   yAxis: 1,
  //           //   tooltip: {
  //           //     valuPrefix: "",
  //           //     valueDecimals: 4,
  //           //   },
  //           // },
  //         ],
  //       }
  //     : {
  //         title: {
  //           text: `${props.productClicked.securityName} ${capitalizeFirstLetter(
  //             grahDetails
  //           )} Chart`,
  //         },
  //         chart: {
  //           type: "line",
  //           zoomType: "x",
  //         },
  //         subtitle: {
  //           text: "Source: Verified Network",
  //         },
  //         xAxis: {
  //           type: "datetime",
  //           title: {
  //             text: "Date",
  //           },
  //           dateTimeLabelFormats: {
  //             day: "%e %b",
  //             month: "%b %Y",
  //           },
  //         },
  //         yAxis: [
  //           {
  //             title: {
  //               text: "Volume",
  //             },
  //             opposite: true,
  //           },
  //         ],
  //         tooltip: {
  //           shared: true,
  //           crosshairs: true,
  //         },
  //         credits: {
  //           enabled: false,
  //         },
  //         series: [
  //           {
  //             name: `${
  //               props.productClicked.securitySymbol
  //             } ${capitalizeFirstLetter(grahDetails)}`,
  //             data: props.productClicked.volume,
  //             tooltip: {
  //               valueDecimals: 4,
  //             },
  //             color: "#7A76FF",
  //           },
  //           {
  //             // name: `${
  //             //   props.productClicked.securitySymbol
  //             // } ${capitalizeFirstLetter(grahDetails)} Area`,
  //             data: props.productClicked.volume,
  //             type: "area",
  //             color: {
  //               linearGradient: {
  //                 x1: 0,
  //                 y1: 0,
  //                 x2: 0,
  //                 y2: 1,
  //               },
  //               stops: [
  //                 [0, "#A29FFF"],
  //                 [1, "rgba(255, 255, 255, 0)"],
  //               ],
  //             },
  //             fillOpacity: 0.5,
  //             yAxis: 0,
  //             zIndex: 0,
  //           },
  //         ],
  //       };

  return (
    <div
      style={
        isMaximized && document.getElementById("side-bar-id")
          ? {
              width: `${
                Number(window.innerWidth) -
                (Number(document.getElementById("side-bar-id").offsetWidth) +
                  36)
              }px`,
            }
          : {}
      }
      id="asset-info-id"
      className="search-filter-popup"
    >
      <div className="search-filter-popup-content">
        <div className="asset-info-popup-header">
          <div className="asset-info-popup-header-main">
            <div className="asset-info-popup-header-main-text">
              {!showSetCollateral && (
                <>
                  {!showAssetTrading && (
                    <p className="search-filter-popup-header-text">
                      Asset Details
                    </p>
                  )}
                  {showAssetTrading && (
                    <>
                      <img
                        onClick={() => {
                          setTradeType("");
                          setShowAssetTrading(false);
                          setTradCategory("market");
                          setTradePrice(null);
                          setTradeAmount(0);
                          setIsTradeInput(false);
                          setPayWithVerifiedWallet(false);
                          setPayWithBank(false);
                          setSelectedCurrency({
                            symbol: props.productClicked?.currencySymbol,
                            img: "",
                            decimals: props.productClicked.currencyDecimals,
                            balance: 0,
                            address: props.productClicked?.currency,
                          });
                        }}
                        alt="Back Icon"
                        src={backIcon}
                      />
                      {tradeType === "buy" && (
                        <p className="search-filter-popup-header-text">
                          Buy Asset
                        </p>
                      )}
                      {tradeType === "sell" && (
                        <p className="search-filter-popup-header-text">
                          Sell Asset
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
              {showSetCollateral && (
                <>
                  <img
                    onClick={() => {
                      setShowSetCollateral(false);
                      setPayWithBank(false);
                      setPayWithVerifiedWallet(false);
                    }}
                    alt="Back Icon"
                    src={backIcon}
                  />
                  <p className="search-filter-popup-header-text">
                    Set Collateral
                  </p>
                </>
              )}
            </div>
            <div className="asset-info-popup-icons">
              {/* <img onClick={() => {}} alt="Close Icon" src={alarmIcon} /> */}
              {/* <img
                onClick={() => {}}
                alt="Close Icon"
                src={favoriteClickedIcon}
              /> */}
              {!isMaximized && (
                <img
                  onClick={() => {
                    props.setExpandedView(true);
                    setIsMaximized(true);
                  }}
                  alt="Close Icon"
                  src={maximizeIcon}
                />
              )}
              {isMaximized && (
                <img
                  onClick={() => {
                    props.setExpandedView(false);
                    setIsMaximized(false);
                  }}
                  alt="Close Icon"
                  src={minimizeIcon}
                />
              )}
              <img
                onClick={() => {
                  props.setShowPopup(false);
                  props.setPopupContentPosition("center");
                  props.setPopupContent(null);
                  if (props.setFavouriteClicked) {
                    props.setFavouriteClicked(false);
                  }
                  setTradeType("");
                  setTradCategory("market");
                  setTradePrice(null);
                  setTradeAmount(0);
                  setIsTradeInput(false);
                  setPayWithVerifiedWallet(false);
                  setPayWithBank(false);
                }}
                alt="Close Icon"
                src={popupCloseIcon}
              />
            </div>
          </div>

          {!showAssetTrading && !showSetCollateral && (
            <div className="asset-info-popup-header-body">
              <div
                onClick={() => {
                  setProductDetails("overview");
                }}
                className="asset-info-popup-header-menu"
              >
                {productDetails === "overview" && (
                  <>
                    <p className="asset-info-popup-header-menu-text-active">
                      Overview
                    </p>
                    <div className="asset-info-popup-header-menu-line"></div>
                  </>
                )}
                {productDetails !== "overview" && (
                  <p className="asset-info-popup-header-menu-text">Overview</p>
                )}
              </div>
              <div
                onClick={() => {
                  setProductDetails("fundamentals");
                }}
                className="asset-info-popup-header-menu"
              >
                {productDetails === "fundamentals" && (
                  <>
                    <p className="asset-info-popup-header-menu-text-active">
                      Fundamentals
                    </p>{" "}
                    <div className="asset-info-popup-header-menu-line"></div>
                  </>
                )}
                {productDetails !== "fundamentals" && (
                  <p className="asset-info-popup-header-menu-text">
                    Fundamentals
                  </p>
                )}
              </div>
            </div>
          )}
        </div>

        {!showSetCollateral && (
          <div className="asset-info-popup-body">
            <div className="asset-info-popup-first">
              <div className="asset-info-popup-first-header">
                <div className="asset-info-popup-first-header-title">
                  <div className="asset-info-popup-first-header-icon">
                    {props.productClicked.logo && (
                      <img alt="Product Logo" src={props.productClicked.logo} />
                    )}
                    {!props.productClicked.logo && (
                      <Avatar name={props.productClicked.securitySymbol} />
                    )}
                  </div>
                  <div className="asset-info-popup-first-header-texts">
                    <p className="asset-info-popup-first-product-name">
                      {props.productClicked.securitySymbol}
                    </p>
                    <p className="asset-info-popup-first-product-description">
                      {props.productClicked.securityName}
                    </p>
                  </div>
                </div>
                <div className="asset-info-popup-first-header-price">
                  <p className="asset-info-popup-first-price-header">
                    {props.productClicked?.currencyFiatSymbol}{" "}
                    {props.productClicked.currentPrice}
                  </p>
                  <p
                    style={
                      Number(props.productClicked.currentPrice) <
                      Number(
                        props.productClicked.pricesBeforeTodayFmt
                          ?.priceBeforeToday
                      )
                        ? { color: "#BC1919" }
                        : {}
                    }
                    className="asset-info-popup-first-price-footer"
                  >
                    {Number(props.productClicked.currentPrice) >=
                    Number(
                      props.productClicked.pricesBeforeTodayFmt
                        ?.priceBeforeToday
                    )
                      ? +(
                          Number(props.productClicked.currentPrice) -
                          Number(
                            props.productClicked.pricesBeforeTodayFmt
                              ?.priceBeforeToday
                          )
                        ).toFixed(2)
                      : -(
                          Number(
                            props.productClicked.pricesBeforeTodayFmt
                              ?.priceBeforeToday
                          ) - Number(props.productClicked.currentPrice)
                        ).toFixed(2)}{" "}
                    ({" "}
                    {Number(props.productClicked.currentPrice) >=
                    Number(
                      props.productClicked.pricesBeforeTodayFmt
                        ?.priceBeforeToday
                    )
                      ? +(
                          ((Number(props.productClicked.currentPrice) -
                            Number(
                              props.productClicked.pricesBeforeTodayFmt
                                ?.priceBeforeToday
                            )) *
                            100) /
                          Number(props.productClicked.currentPrice)
                        ).toFixed(2)
                      : -(
                          ((Number(
                            props.productClicked.pricesBeforeTodayFmt
                              ?.priceBeforeToday
                          ) -
                            Number(props.productClicked.currentPrice)) *
                            100) /
                          Number(
                            props.productClicked.pricesBeforeTodayFmt
                              ?.priceBeforeToday
                          )
                        ).toFixed(2)}{" "}
                    % )
                  </p>
                </div>
              </div>

              {!props.showPandL && (
                <>
                  <div className="asset-info-popup-first-footer">
                    <div className="asset-info-popup-first-footer-content">
                      <p className="asset-info-popup-first-footer-content-header">
                        Minimum Quantity
                      </p>
                      <p className="asset-info-popup-first-footer-content-footer">
                        {Number(props.productClicked.minOrderSize).toFixed(4)}
                      </p>
                    </div>

                    <div className="asset-info-popup-first-footer-line">
                      <div className="asset-info-popup-first-footer-content">
                        <p className="asset-info-popup-first-footer-content-header">
                          Total Volume
                        </p>
                        <p className="asset-info-popup-first-footer-content-footer">
                          {(
                            Number(props.productClicked.totalBought) +
                            Number(props.productClicked.totalSold)
                          ).toFixed(4)}
                        </p>
                      </div>
                    </div>

                    <div className="asset-info-popup-first-footer-content">
                      <p className="asset-info-popup-first-footer-content-header">
                        Issue Type
                      </p>
                      <p className="asset-info-popup-first-footer-content-footer">
                        {props.productClicked.poolType}
                      </p>
                    </div>
                  </div>
                  {props.showSeparator && (
                    <PageSeperator
                      separatorText={props.separatorText}
                      separatorWidth={props.separatorWidth}
                    />
                  )}
                </>
              )}

              {props.showPandL && !props.productClicked.isMarginIssue && (
                <div className="asset-info-popup-p-and-l">
                  <div className="asset-info-popup-p-and-l-content">
                    <div className="asset-info-popup-p-and-l-content-section">
                      <p className="asset-info-popup-p-and-l-content-title">
                        Total Qty
                      </p>
                      <p className="asset-info-popup-p-and-l-content-text">
                        {Number(
                          props.productClicked.totalInvestorBalance
                        ).toFixed(4)}
                      </p>
                    </div>
                    <div className="asset-info-popup-p-and-l-content-section">
                      <p className="asset-info-popup-p-and-l-content-title">
                        P&L
                      </p>
                      <div className="asset-info-popup-p-and-l-content-text-container">
                        <p className="asset-info-popup-p-and-l-content-text-gain">
                          $62.64
                        </p>
                        <img src={priceUp} alt="Arrow Up Icon" />
                      </div>
                    </div>
                  </div>
                  <div className="asset-info-popup-p-and-l-content">
                    <div className="asset-info-popup-p-and-l-content-section">
                      <p className="asset-info-popup-p-and-l-content-title">
                        Total Amt Invested
                      </p>
                      <p className="asset-info-popup-p-and-l-content-text">
                        1440.00
                      </p>
                    </div>
                    <div className="asset-info-popup-p-and-l-content-section">
                      <p className="asset-info-popup-p-and-l-content-title">
                        Current Value
                      </p>
                      <p className="asset-info-popup-p-and-l-content-text">
                        {Number(props.productClicked.currentPrice).toFixed(4)}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {props.showPandL &&
                props.productClicked.isMarginIssue &&
                props.showMarginExtra && (
                  <>
                    <div className="asset-info-popup-p-and-l-content">
                      <div className="asset-info-popup-p-and-l-content-section">
                        <p className="asset-info-popup-p-and-l-content-title">
                          Current Value
                        </p>
                        <p className="asset-info-popup-p-and-l-content-text">
                          8105
                        </p>
                      </div>
                      <div className="asset-info-popup-p-and-l-content-section">
                        <p className="asset-info-popup-p-and-l-content-title">
                          P&L
                        </p>
                        <div className="asset-info-popup-p-and-l-content-text-container">
                          <p className="asset-info-popup-p-and-l-content-text-gain">
                            $105.50
                          </p>
                          <img src={priceUp} alt="Arrow Up Icon" />
                        </div>
                      </div>
                    </div>

                    {tradeType !== "buy" && (
                      <>
                        <div className="asset-info-popup-p-and-l-content">
                          <div className="asset-info-popup-p-and-l-content-section">
                            <p className="asset-info-popup-p-and-l-content-title">
                              Collateral Value
                            </p>
                            <p className="asset-info-popup-p-and-l-content-text">
                              2000
                            </p>
                          </div>
                          <div className="asset-info-popup-p-and-l-content-section">
                            <p className="asset-info-popup-p-and-l-content-title">
                              Margin Received
                            </p>
                            <div className="asset-info-popup-p-and-l-content-text-container">
                              <p className="asset-info-popup-p-and-l-content-text-gain">
                                6000
                              </p>
                              <img src={priceUp} alt="Arrow Up Icon" />
                            </div>
                          </div>
                        </div>
                        <div className="asset-info-popup-p-and-l-content">
                          <div className="asset-info-popup-p-and-l-content-section">
                            <p className="asset-info-popup-p-and-l-content-title">
                              Total Qty
                            </p>
                            <p className="asset-info-popup-p-and-l-content-text">
                              10
                            </p>
                          </div>
                          <div className="asset-info-popup-p-and-l-content-section">
                            <p className="asset-info-popup-p-and-l-content-title">
                              P&L Settlement Frequency
                            </p>
                            <p className="asset-info-popup-p-and-l-content-text">
                              3 Days
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

              {props.showPandL &&
                props.productClicked.isMarginIssue &&
                !props.showMarginExtra && (
                  <div className="asset-info-popup-p-and-l">
                    <div className="asset-info-popup-p-and-l-content">
                      <div className="asset-info-popup-p-and-l-content-section">
                        <p className="asset-info-popup-p-and-l-content-title">
                          Financing Cost
                        </p>
                        <p className="asset-info-popup-p-and-l-content-text">
                          20% (of current price)
                        </p>
                      </div>
                      <div className="asset-info-popup-p-and-l-content-section">
                        <p className="asset-info-popup-p-and-l-content-title">
                          Margin Available
                        </p>
                        <p className="asset-info-popup-p-and-l-content-text">
                          {" "}
                          4x (of financing cost)
                        </p>
                      </div>
                    </div>

                    {tradeType !== "buy" && (
                      <div className="asset-info-popup-p-and-l-content">
                        <div className="asset-info-popup-p-and-l-content-section">
                          <p className="asset-info-popup-p-and-l-content-title">
                            Minimum Quantity
                          </p>
                          <p className="asset-info-popup-p-and-l-content-text">
                            1440.00
                          </p>
                        </div>
                        <div className="asset-info-popup-p-and-l-content-section">
                          <p className="asset-info-popup-p-and-l-content-title">
                            P&L Settlement Frequency
                          </p>
                          <p className="asset-info-popup-p-and-l-content-text">
                            3 Days
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {showAssetTrading && (
                <>
                  {props.productClicked.poolType === PoolType.marginPool &&
                    tradeType === "buy" && (
                      <div className="asset-info-popup-collateral-container">
                        <p className="asset-info-popup-collateral-header-text">
                          Collateral Details
                        </p>
                        <div className="asset-info-popup-collateral-body">
                          <div className="asset-info-popup-collateral-main">
                            <div className="asset-info-popup-collateral-main-first">
                              <div className="asset-info-popup-collateral-main-first-header">
                                <p className="asset-info-popup-collateral-main-title">
                                  Collateral
                                </p>
                                <p className="asset-info-popup-collateral-main-first-text">
                                  ${collateralAdded}
                                </p>
                              </div>
                              <div className="asset-info-popup-collateral-main-first-footer">
                                <p className="asset-info-popup-collateral-main-title">
                                  Margin
                                </p>
                                <p className="asset-info-popup-collateral-main-second-footer">
                                  $ 00.00
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                // setIsCollateralAdded(true);
                                setShowSetCollateral(true);
                              }}
                              className="asset-info-popup-collateral-main-second"
                            >
                              <div className="asset-info-popup-collateral-main-second-container">
                                <img src={collateralBank} alt="Expand Icon" />
                                <p className="asset-info-popup-collateral-main-second-text">
                                  Add Collateral
                                </p>
                              </div>
                              <img src={collateralExpand} alt="Expand Icon" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  <div
                    style={
                      props.productClicked &&
                      props.productClicked.isMarginIssue &&
                      tradeType === "buy" &&
                      Number(collateralAdded) === 0
                        ? {
                            opacity: "0.5",
                            background: "#FFF",
                            pointerEvents: "none",
                          }
                        : {}
                    }
                    className="asset-info-popup-trading-container"
                  >
                    <div className="asset-info-popup-trading-first-header">
                      <div
                        style={{ position: "relative" }}
                        className="asset-info-popup-trading-first-header-text-container"
                      >
                        <p className="asset-info-popup-trading-first-header-text">
                          {tradeType === "buy" ? "Buy With" : "Sell To"}
                        </p>
                        <div
                          style={{
                            zIndex: "2",
                            position: "absolute",
                            top: "-8px",
                            right: "0",
                          }}
                          className="asset-info-popup-trading-first-header-dropdown-cont"
                        >
                          <button
                            onClick={() => {
                              setShowCurrenciesDropdown(
                                !showCurrenciesDropdown
                              );
                            }}
                            className="portfolio-page-content-header-button"
                          >
                            {selectedCurrency.img.length > 0 && (
                              <div className="asset-info-popup-trading-first-header-dropdown-content-img">
                                <img src={selectedCurrency.img} alt="" />
                              </div>
                            )}
                            <p className="portfolio-page-content-header-button-text">
                              {selectedCurrency.symbol}
                            </p>
                            <img src={expandIcon} alt="Expand Icon" />
                          </button>
                          {showCurrenciesDropdown &&
                            props.paraswapSupportedTokens?.length > 0 &&
                            props.paraswapSupportedTokens?.find(
                              (tk) =>
                                tk?.address?.toLowerCase() ===
                                props?.productClicked?.currency.toLowerCase()
                            ) && (
                              <div className="asset-info-popup-trading-first-header-dropdown">
                                {props.paraswapSupportedTokens?.map((ptk) => (
                                  <div
                                    onClick={() => {
                                      setSelectedCurrency(ptk);
                                      setShowCurrenciesDropdown(false);
                                    }}
                                    className="asset-info-popup-trading-first-header-dropdown-content"
                                  >
                                    <div className="asset-info-popup-trading-first-header-dropdown-content-img">
                                      <img src={ptk.img} alt="" />
                                    </div>

                                    <p className="portfolio-page-content-header-button-text">
                                      {ptk.symbol}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "5px" }}
                        className="asset-info-popup-trading-first-header-text-container"
                      >
                        {props.productClicked.poolType ===
                          PoolType.primaryPool && (
                          <p className="asset-info-popup-trading-first-header-text">
                            {`No. of ${
                              props.productClicked.securitySymbol
                            } to ${capitalizeFirstLetter(tradeType)}`}
                          </p>
                        )}
                        {props.productClicked.poolType !==
                          PoolType.primaryPool && (
                          <p className="asset-info-popup-trading-first-header-text">
                            {`No. of ${
                              tradeType === "buy"
                                ? selectedCurrency.symbol
                                : props.productClicked.securitySymbol
                            }`}
                          </p>
                        )}
                        <p className="asset-info-popup-trading-first-header-text-footer">
                          Max Qty:{" "}
                          {tradeType === "buy"
                            ? Number(userBalances.currency).toFixed(4)
                            : Number(userBalances.security).toFixed(4)}{" "}
                          {tradeType === "buy"
                            ? selectedCurrency.symbol
                            : props.productClicked.securitySymbol}
                        </p>
                      </div>
                      <input
                        type="number"
                        className="asset-info-popup-trading-input"
                        placeholder="0"
                        onInput={async (e) => {
                          setTradeAmount(e.target.value);
                          if (
                            props.productClicked.poolType !==
                              PoolType.marginPool &&
                            Number(e.target.value) > 0
                          ) {
                            const sorValue =
                              await calculatePrimaryAndSecondarySOR(
                                props.productClicked,
                                Number(e.target.value),
                                tradeType,
                                userAddress
                              );
                            setSorValue(sorValue);
                          } else {
                            setSorValue({ amountOut: 0.0, slippage: 0.0 });
                          }
                        }}
                      />

                      {Object.keys(paraswapDetails).length > 0 && (
                        <>
                          <div className="asset-info-popup-trading-first-footer">
                            <div
                              style={{ minWidth: "fit-content" }}
                              className="asset-info-popup-trading-first-footer-body"
                            >
                              <img
                                src={infoIcon}
                                alt="Info Icon"
                                className="asset-info-popup-trading-slippage-icon"
                              />
                              <p
                                style={{ minWidth: "fit-content" }}
                                className="asset-info-popup-trading-first-footer-text"
                              >
                                Slippage
                              </p>
                            </div>
                            <div
                              style={{
                                marginLeft: "5px",
                              }}
                              className="a-f-content-footer-input-container"
                            >
                              <div
                                style={{
                                  minWidth: "fit-content",
                                  marginRight: "3px",
                                }}
                                onClickCapture={() => {
                                  setTrySwapAgain(false);
                                  setSwapSlippage(0.5);
                                }}
                                onClick={() => {
                                  setTrySwapAgain(true);
                                }}
                                className="a-f-content-footer-currencies-slip"
                              >
                                <div className="a-f-content-footer-currencies-container">
                                  <p className="a-f-content-footer-currency">
                                    0.5 %
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  minWidth: "fit-content",
                                  marginRight: "3px",
                                }}
                                onClickCapture={() => {
                                  setTrySwapAgain(false);
                                  setSwapSlippage(1);
                                }}
                                onClick={() => {
                                  setTrySwapAgain(true);
                                }}
                                className="a-f-content-footer-currencies-slip"
                              >
                                <div className="a-f-content-footer-currencies-container">
                                  <p className="a-f-content-footer-currency">
                                    1 %
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  minWidth: "fit-content",
                                }}
                                onClickCapture={() => {
                                  setTrySwapAgain(false);
                                  setSwapSlippage(5);
                                }}
                                onClick={() => {
                                  setTrySwapAgain(true);
                                }}
                                className="a-f-content-footer-currencies-slip"
                              >
                                <div className="a-f-content-footer-currencies-container">
                                  <p className="a-f-content-footer-currency">
                                    5 %
                                  </p>
                                </div>
                              </div>
                              <div className="a-f-content-footer-input-group">
                                <input
                                  style={{
                                    border: " 1px solid #d8d9df",
                                    borderRadius: "8px",
                                  }}
                                  onInput={(e) => {
                                    setTrySwapAgain(false);
                                    setSwapSlippage(e.target.value);
                                  }}
                                  type="number"
                                  className="a-f-content-footer-input"
                                  placeholder="00.00"
                                  value={swapSlippage}
                                />
                              </div>

                              {trySwapAgain && (
                                <div
                                  style={{ background: "transparent" }}
                                  className="a-f-content-footer-currencies"
                                >
                                  <div className="a-f-content-footer-currencies-container">
                                    <p className="a-f-content-footer-currency">
                                      %
                                    </p>
                                  </div>
                                </div>
                              )}

                              {!trySwapAgain && (
                                <div
                                  style={{
                                    marginLeft: "3px",
                                  }}
                                  onClick={() => {
                                    setTrySwapAgain(true);
                                  }}
                                  className="a-f-content-footer-currencies-slip"
                                >
                                  <div className="a-f-content-footer-currencies-container">
                                    <p className="a-f-content-footer-currency">
                                      Retry
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="a-f-content-footer-input-container">
                            <div className="a-f-content-footer-input-group">
                              <input
                                id="add-funds-popup-input"
                                disabled={true}
                                type="text"
                                className="a-f-content-footer-input"
                                placeholder="00.00"
                                value={paraswapDetails?.amount?.toFixed(10)}
                              />
                            </div>
                            <div className="a-f-content-footer-currencies">
                              <div className="a-f-content-footer-currencies-container">
                                <p className="a-f-content-footer-currency">
                                  {tradeType === "buy"
                                    ? props.productClicked.currencySymbol
                                    : selectedCurrency.symbol}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {props.productClicked.isMarginIssue &&
                      tradeType === "buy" &&
                      Number(collateralAdded) === 0 && (
                        <div className="asset-info-popup-trading-first-footer">
                          <div className="asset-info-popup-trading-first-footer-body">
                            <img
                              src={infoIcon}
                              alt="Info Icon"
                              className="asset-info-popup-trading-slippage-icon"
                            />
                            <p className="asset-info-popup-trading-first-footer-text">
                              Your order may get rejected due to insufficient
                              margin availability. Add more collaterals or
                              change the buy qty.
                            </p>
                          </div>
                        </div>
                      )}

                    {props.productClicked.poolType !== PoolType.primaryPool && (
                      <div className="asset-info-popup-trading-first-header">
                        <div className="asset-info-popup-trading-first-header-text-container">
                          <p className="asset-info-popup-trading-first-header-text">
                            Buy Price
                          </p>
                        </div>
                        <div className="asset-info-popup-trading-input-group">
                          <div className="asset-info-popup-trading-price-input-group">
                            <div
                              onClick={() => {
                                if (tradeCategory === "limit") {
                                  if (isTradeInput) {
                                    setTradePrice(Number(tradePrice) + 1);
                                  } else if (Number(limitPrice) >= 0) {
                                    setLimitPrice(Number(limitPrice) + 1);
                                  }
                                }
                              }}
                              style={
                                tradeCategory === "market"
                                  ? { cursor: "unset" }
                                  : {}
                              }
                              className="asset-info-popup-trading-price-toggle"
                            >
                              <img
                                src={increaseIcon}
                                alt="Price Increase Icon"
                              />
                            </div>
                            <input
                              onInputCapture={() => {
                                setIsTradeInput(true);
                              }}
                              onInput={(e) => {
                                setTradePrice(e.target.value);
                              }}
                              disabled={
                                tradeCategory === "market" ? true : false
                              }
                              type="number"
                              value={
                                tradeCategory === "market"
                                  ? currentPrice
                                  : !isTradeInput
                                  ? limitPrice
                                  : tradePrice
                              }
                              className="asset-info-popup-trading-price-input"
                              placeholder={currentPrice}
                            />
                            <div
                              onClick={() => {
                                if (tradeCategory === "limit") {
                                  if (isTradeInput && Number(tradePrice) > 0) {
                                    setTradePrice(Number(tradePrice) - 1);
                                  } else if (Number(limitPrice) > 0) {
                                    setLimitPrice(Number(limitPrice) - 1);
                                  }
                                }
                              }}
                              style={
                                tradeCategory === "market"
                                  ? { cursor: "unset" }
                                  : {}
                              }
                              className="asset-info-popup-trading-price-toggle"
                            >
                              <img
                                src={decreaseIcon}
                                alt="Price Decrease Icon"
                              />
                            </div>
                          </div>
                          <div className="asset-info-popup-trading-categories">
                            {tradeCategory === "market" && (
                              <div
                                onClick={() => {
                                  setTradCategory("market");
                                }}
                                className="asset-info-popup-trading-category-active"
                              >
                                <p className="asset-info-popup-trading-category-text-active">
                                  Market
                                </p>
                              </div>
                            )}
                            {tradeCategory !== "market" && (
                              <div
                                onClick={() => {
                                  setTradCategory("market");
                                }}
                                className="asset-info-popup-trading-category"
                              >
                                <p className="asset-info-popup-trading-category-text">
                                  Market
                                </p>
                              </div>
                            )}
                            {tradeCategory === "limit" && (
                              <div
                                onClick={() => {
                                  setTradCategory("limit");
                                }}
                                className="asset-info-popup-trading-category-active"
                              >
                                <p className="asset-info-popup-trading-category-text-active">
                                  Limit
                                </p>
                              </div>
                            )}
                            {tradeCategory !== "limit" && (
                              <div
                                onClick={() => {
                                  setTradCategory("limit");
                                }}
                                className="asset-info-popup-trading-category"
                              >
                                <p className="asset-info-popup-trading-category-text">
                                  Limit
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="asset-info-popup-trading-first-footer">
                      <div className="asset-info-popup-trading-first-footer-body">
                        <img
                          src={infoIcon}
                          alt="Info Icon"
                          className="asset-info-popup-trading-slippage-icon"
                        />
                        <p className="asset-info-popup-trading-first-footer-text">
                          Expected Slippage
                        </p>
                      </div>
                      <p className="asset-info-popup-trading-first-footer-text">
                        {sorValue.slippage} %
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="asset-info-popup-second">
              {!showAssetTrading && (
                <>
                  {productDetails === "overview" && (
                    <p className="asset-info-popup-second-header-text">
                      Graph Overview
                    </p>
                  )}
                  {productDetails === "fundamentals" && (
                    <p className="asset-info-popup-second-header-text">
                      Company Profile
                    </p>
                  )}
                  <div className="asset-info-popup-second-body">
                    {productDetails === "overview" && (
                      <>
                        <div className="asset-info-popup-second-body-header">
                          <div className="asset-info-popup-second-body-header-text-container">
                            {grahDetails === "price" && (
                              <button
                                onClick={() => {
                                  setGrahDetails("price");
                                }}
                                className="asset-info-popup-second-body-header-button-active"
                              >
                                <p className="asset-info-popup-second-body-header-button-text-active">
                                  By Price
                                </p>
                              </button>
                            )}
                            {grahDetails !== "price" && (
                              <button
                                onClick={() => {
                                  setGrahDetails("price");
                                }}
                                className="asset-info-popup-second-body-header-button"
                              >
                                <p className="asset-info-popup-second-body-header-button-text">
                                  By Price
                                </p>
                              </button>
                            )}
                            {grahDetails === "volume" && (
                              <button
                                onClick={() => {
                                  setGrahDetails("volume");
                                }}
                                className="asset-info-popup-second-body-header-button-active"
                              >
                                <p className="asset-info-popup-second-body-header-button-text-active">
                                  By Volume
                                </p>
                              </button>
                            )}
                            {grahDetails !== "volume" && (
                              <button
                                onClick={() => {
                                  setGrahDetails("volume");
                                }}
                                className="asset-info-popup-second-body-header-button"
                              >
                                <p className="asset-info-popup-second-body-header-button-text">
                                  By Volume
                                </p>
                              </button>
                            )}
                          </div>
                          <p className="asset-info-popup-second-body-header-text-footer">
                            P/A
                          </p>
                        </div>
                        {/* <img
                          className="asset-info-popup-graph-number-icon"
                          src={graphNumbersIcon}
                          alt="Graph Icon"
                        />
                        <img
                          className="asset-info-popup-graph-price-icon"
                          src={graphIcon}
                          alt="Graph Icon"
                        /> */}
                        <div className="asset-info-popup-highchart-container">
                          {!candleStickClicked && (
                            <>
                              {grahDetails === "price" &&
                                props.productClicked?.priceChartData && (
                                  <AreaChart
                                    areaData={
                                      props.productClicked?.priceChartData
                                    }
                                    candleStickData={{}}
                                    graphTime={graphTime}
                                    currencySymbol={
                                      props.productClicked?.currencyFiatSymbol
                                    }
                                    graphType={grahDetails}
                                    ismaximised={isMaximized}
                                  />
                                )}
                              {grahDetails === "volume" &&
                                props.productClicked?.volumeChartData && (
                                  <AreaChart
                                    areaData={
                                      props.productClicked?.volumeChartData
                                    }
                                    candleStickData={{}}
                                    graphTime={graphTime.time}
                                    currencySymbol={
                                      props.productClicked?.currencyFiatSymbol
                                    }
                                    graphType={grahDetails}
                                    ismaximised={isMaximized}
                                  />
                                )}
                            </>
                          )}
                          {candleStickClicked && (
                            <>
                              {grahDetails === "price" &&
                                props.productClicked?.priceCandleStickData && (
                                  <CandleStickChart
                                    candleStickData={
                                      props.productClicked?.priceCandleStickData
                                    }
                                    graphTime={graphTime}
                                    currencySymbol={
                                      props.productClicked?.currencyFiatSymbol
                                    }
                                    graphType={grahDetails}
                                    ismaximised={isMaximized}
                                  />
                                )}
                              {grahDetails === "volume" &&
                                props.productClicked?.volumeCandleStickData && (
                                  <CandleStickChart
                                    candleStickData={
                                      props.productClicked
                                        ?.volumeCandleStickData
                                    }
                                    graphTime={graphTime.time}
                                    currencySymbol={
                                      props.productClicked?.currencyFiatSymbol
                                    }
                                    graphType={grahDetails}
                                    ismaximised={isMaximized}
                                  />
                                )}
                            </>
                          )}
                          <div className="asset-info-popup-highchart-container-buttons">
                            {graphTime.label === "1h" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1h",
                                    time: 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button-active"
                              >
                                <p className="asset-info-popup-highchart-container-button-text-active">
                                  1H
                                </p>
                              </button>
                            )}
                            {graphTime.label !== "1h" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1h",
                                    time: 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <p className="asset-info-popup-highchart-container-button-text">
                                  1H
                                </p>
                              </button>
                            )}

                            {graphTime.label === "24h" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "24h",
                                    time: 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button-active"
                              >
                                <p className="asset-info-popup-highchart-container-button-text-active">
                                  24H
                                </p>
                              </button>
                            )}

                            {graphTime.label !== "24h" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "24h",
                                    time: 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <p className="asset-info-popup-highchart-container-button-text">
                                  24H
                                </p>
                              </button>
                            )}

                            {graphTime.label === "1w" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1w",
                                    time: 7 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button-active"
                              >
                                <p className="asset-info-popup-highchart-container-button-text-active">
                                  1W
                                </p>
                              </button>
                            )}

                            {graphTime.label !== "1w" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1w",
                                    time: 7 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <p className="asset-info-popup-highchart-container-button-text">
                                  1W
                                </p>
                              </button>
                            )}

                            {graphTime.label === "1m" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1m",
                                    time: 30 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button-active"
                              >
                                <p className="asset-info-popup-highchart-container-button-text-active">
                                  1M
                                </p>
                              </button>
                            )}

                            {graphTime.label !== "1m" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1m",
                                    time: 30 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <p className="asset-info-popup-highchart-container-button-text">
                                  1M
                                </p>
                              </button>
                            )}

                            {graphTime.label === "6m" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "6m",
                                    time: 6 * 30 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button-active"
                              >
                                <p className="asset-info-popup-highchart-container-button-text-active">
                                  6M
                                </p>
                              </button>
                            )}

                            {graphTime.label !== "6m" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "6m",
                                    time: 6 * 30 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <p className="asset-info-popup-highchart-container-button-text">
                                  6M
                                </p>
                              </button>
                            )}

                            {graphTime.label === "1y" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1y",
                                    time: 365 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button-active"
                              >
                                <p className="asset-info-popup-highchart-container-button-text-active">
                                  1Y
                                </p>
                              </button>
                            )}

                            {graphTime.label !== "1y" && (
                              <button
                                onClick={() => {
                                  setGraphTime({
                                    label: "1y",
                                    time: 365 * 24 * 60 * 60,
                                  });
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <p className="asset-info-popup-highchart-container-button-text">
                                  1Y
                                </p>
                              </button>
                            )}

                            {!candleStickClicked && (
                              <button
                                onClick={() => {
                                  setCandleStickClicked(true);
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <img
                                  src={candleStickIcon}
                                  alt="Candle Stick Icon"
                                />
                              </button>
                            )}
                            {candleStickClicked && (
                              <button
                                onClick={() => {
                                  setCandleStickClicked(false);
                                }}
                                className="asset-info-popup-highchart-container-button"
                              >
                                <img
                                  src={lineChartIcon}
                                  alt="Candle Stick Icon"
                                />
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="asset-info-popup-third">
                          <div className="asset-info-popup-third-first">
                            <div className="asset-info-popup-third-first-container">
                              <div className="asset-info-popup-third-first-header">
                                <p className="asset-info-popup-third-first-header-title">
                                  Market Stats
                                </p>
                              </div>

                              <div className="asset-info-popup-third-first-body">
                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={volumeIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Total Bought
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {Number(
                                      props.productClicked.totalBought
                                    ).toFixed(4)}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={volumeIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Total Sold
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {Number(
                                      props.productClicked.totalSold
                                    ).toFixed(4)}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={volumeIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Total Volume
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {(
                                      Number(props.productClicked.totalBought) +
                                      Number(props.productClicked.totalSold)
                                    ).toFixed(4)}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={volumeIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Total Buyers
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {props.productClicked?.totalBuyers}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={volumeIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Total Sellers
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {props.productClicked?.totalSellers}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={calenderIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Launched On
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {props.productClicked?.createTimeFmt}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={categoryIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Category
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {capitalizeFirstLetter(
                                      props.productClicked?.securityCategory
                                    )}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={athIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      All Time High
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {props.productClicked?.highestPrice}{" "}
                                    {props.productClicked?.currencyFiatSymbol} ({" "}
                                    {props.productClicked?.prices.length > 0
                                      ? new Date(
                                          props.productClicked?.prices[
                                            props.productClicked?.pricesRawFmt?.indexOf(
                                              props.productClicked?.highestPrice
                                            )
                                          ][0]
                                        ).toLocaleDateString()
                                      : new Date(
                                          Date.now()
                                        ).toLocaleDateString()}{" "}
                                    ){" "}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={atlIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      All Time Low
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {props.productClicked?.lowestPrice}{" "}
                                    {props.productClicked?.currencyFiatSymbol} ({" "}
                                    {props.productClicked?.prices.length > 0
                                      ? new Date(
                                          props.productClicked?.prices[
                                            props.productClicked?.pricesRawFmt?.indexOf(
                                              props.productClicked?.lowestPrice
                                            )
                                          ][0]
                                        ).toLocaleDateString()
                                      : new Date(
                                          Date.now()
                                        ).toLocaleDateString()}{" "}
                                    ){" "}
                                  </p>
                                </div>

                                <div className="asset-info-popup-third-first-body-cont">
                                  <div className="asset-info-popup-third-first-body-text-cont">
                                    <img src={fixedFeeIcon} alt="" />
                                    <p className="asset-info-popup-third-first-body-text">
                                      Fixed Swap Fee
                                    </p>
                                  </div>
                                  <p className="asset-info-popup-third-first-body-label">
                                    {props.productClicked?.issueingFee ||
                                      props.productClicked?.swapFee}{" "}
                                    %
                                  </p>
                                </div>
                              </div>

                              {props?.productClicked?.offeringDocData?.Business
                                ?.Summary && (
                                <>
                                  <div className="asset-info-popup-third-first-body-line"></div>
                                  <p className="asset-info-popup-third-first-footer-text">
                                    {typeof props?.productClicked
                                      ?.offeringDocData?.Business?.Summary ===
                                    "string"
                                      ? props?.productClicked?.offeringDocData
                                          ?.Business?.Summary
                                      : ""}
                                  </p>
                                </>
                              )}
                            </div>

                            {Number(props?.productClicked?.lowestPrice) !==
                              Number(props?.productClicked?.highestPrice) && (
                              <div className="asset-info-popup-third-first-footer-container">
                                <p className="asset-info-popup-third-first-footer-title">
                                  Price Range
                                </p>

                                <div className="asset-info-popup-third-first-footer-body-cont">
                                  <div
                                    id="price-range-page-container"
                                    className="asset-info-popup-third-first-footer-body"
                                  >
                                    <div
                                      id="price-range-page"
                                      className="asset-info-popup-third-first-footer-body-pointer-cont"
                                    >
                                      <div className="asset-info-popup-third-first-footer-body-pointer">
                                        <p
                                          id="price-range-page-text"
                                          className="asset-info-popup-third-first-footer-body-pointer-text"
                                        ></p>
                                      </div>

                                      <img
                                        className="asset-info-popup-third-first-footer-body-pointer-img"
                                        src={priceIndIcon}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="asset-info-popup-third-first-footer-body-price-cont">
                                    <div
                                      id="price-range-line"
                                      onClick={(e) => {
                                        const rangeDiv =
                                          document.getElementById(
                                            "price-range-line"
                                          );
                                        const rangeRect =
                                          rangeDiv.getBoundingClientRect();
                                        const clickX =
                                          e.clientX - rangeRect.left;
                                        const price = Number(
                                          (
                                            (clickX / rangeDiv.offsetWidth) *
                                              (Number(
                                                props?.productClicked
                                                  ?.highestPrice
                                              ) -
                                                Number(
                                                  props?.productClicked
                                                    ?.lowestPrice
                                                )) +
                                            Number(
                                              props?.productClicked?.lowestPrice
                                            )
                                          ).toFixed(2)
                                        );
                                        updatePointerPosition(price);
                                      }}
                                      onLoad={() => {
                                        updatePointerPosition(
                                          Number(
                                            (
                                              Number(
                                                props?.productClicked
                                                  ?.highestPrice
                                              ) / 2
                                            ).toFixed(2)
                                          ),
                                          true
                                        );
                                      }}
                                      className="asset-info-popup-third-first-footer-body-price-line"
                                    >
                                      <img
                                        id="price-range-line-dot"
                                        className="asset-info-popup-third-first-footer-body-price-ind"
                                        src={priceDotIcon}
                                        alt=""
                                      />
                                      <div className="asset-info-popup-third-first-footer-body-price-mark-start"></div>
                                      <div className="asset-info-popup-third-first-footer-body-price-mark-end"></div>
                                    </div>
                                  </div>

                                  <div className="asset-info-popup-third-first-footer-body-price-label">
                                    <div className="asset-info-popup-third-first-footer-body-price-low">
                                      <p className="asset-info-popup-third-first-footer-body-price-low-title">
                                        All Time Low
                                      </p>
                                      <p className="asset-info-popup-third-first-footer-body-price-low-text">
                                        {props?.productClicked?.lowestPrice}
                                      </p>
                                    </div>

                                    <div className="asset-info-popup-third-first-footer-body-price-high">
                                      <p className="asset-info-popup-third-first-footer-body-price-low-title">
                                        All Time High
                                      </p>
                                      <p className="asset-info-popup-third-first-footer-body-price-low-text">
                                        {props?.productClicked?.highestPrice}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="asset-info-popup-third-first-footer-last">
                                  <img src={bulbIcon} alt="" />
                                  <p className="asset-info-popup-third-first-footer-last-text">
                                    {Number(
                                      props.productClicked.currentPrice
                                    ) >=
                                    Number(
                                      props.productClicked.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    )
                                      ? (
                                          ((Number(
                                            props.productClicked.currentPrice
                                          ) -
                                            Number(
                                              props.productClicked
                                                .pricesBeforeTodayFmt
                                                ?.priceBeforeToday
                                            )) *
                                            100) /
                                          Number(
                                            props.productClicked.currentPrice
                                          )
                                        ).toFixed(2)
                                      : (
                                          ((Number(
                                            props.productClicked
                                              .pricesBeforeTodayFmt
                                              ?.priceBeforeToday
                                          ) -
                                            Number(
                                              props.productClicked.currentPrice
                                            )) *
                                            100) /
                                          Number(
                                            props.productClicked
                                              .pricesBeforeTodayFmt
                                              ?.priceBeforeToday
                                          )
                                        ).toFixed(2)}{" "}
                                    {Number(
                                      props.productClicked.currentPrice
                                    ) >=
                                    Number(
                                      props.productClicked.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    )
                                      ? "Increase"
                                      : "Decrease"}{" "}
                                    in 24h.{" "}
                                    {/* <span className="asset-info-popup-third-first-footer-last-text-label">
                                   The current drop is due to shift in price
                                   actions
                                 </span> */}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* leadership
                                    will make a strong comeback next quarter. */}

                          {/* <div className="asset-info-popup-third-second">
                            <div className="asset-info-popup-third-second-header">
                              <p className="asset-info-popup-third-first-header-title">
                                Dividends
                              </p>
                            </div>

                            <div className="asset-info-popup-third-second-body">
                              <div className="asset-info-popup-third-second-body-cont">
                                <div className="asset-info-popup-third-second-body-content">
                                  <img src={calenderBgIcon} alt="" />

                                  <div className="asset-info-popup-third-second-body-content-body">
                                    <div className="asset-info-popup-third-second-body-content-body-cont">
                                      <p className="asset-info-popup-third-second-body-content-body-text">
                                        Dividend Date
                                      </p>
                                    </div>

                                    <p className="asset-info-popup-third-second-body-content-label">
                                      May 31st, 2024
                                    </p>
                                  </div>
                                </div>

                                <div className="asset-info-popup-third-second-body-content">
                                  <img src={netDividendIcon} alt="" />

                                  <div className="asset-info-popup-third-second-body-content-body">
                                    <div className="asset-info-popup-third-second-body-content-body-cont">
                                      <p className="asset-info-popup-third-second-body-content-body-text">
                                        Next Dividend
                                      </p>
                                    </div>

                                    <p className="asset-info-popup-third-second-body-content-label">
                                      $ 1.24
                                    </p>
                                  </div>
                                </div>

                                <div className="asset-info-popup-third-second-body-content">
                                  <img src={dividendYieldIcon} alt="" />

                                  <div className="asset-info-popup-third-second-body-content-body">
                                    <div className="asset-info-popup-third-second-body-content-body-cont">
                                      <p className="asset-info-popup-third-second-body-content-body-text">
                                        Dividend Yield TTM
                                      </p>
                                    </div>

                                    <p className="asset-info-popup-third-second-body-content-label">
                                      0.72 %
                                    </p>
                                  </div>
                                </div>

                                <div className="asset-info-popup-third-second-body-content">
                                  <img src={payoutIcon} alt="" />

                                  <div className="asset-info-popup-third-second-body-content-body">
                                    <div className="asset-info-popup-third-second-body-content-body-cont">
                                      <p className="asset-info-popup-third-second-body-content-body-text">
                                        Payout Ratio
                                      </p>
                                    </div>

                                    <p className="asset-info-popup-third-second-body-content-label">
                                      15.06%
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="asset-info-popup-third-last">
                            <p className="asset-info-popup-third-first-header-title">
                              Links
                            </p>

                            <div className="asset-info-popup-third-last-content-outer">
                              {props?.productClicked?.offeringDocData
                                ?.links && (
                                <div className="asset-info-popup-third-last-content">
                                  <div className="asset-info-popup-third-last-content-text-cont">
                                    <p className="asset-info-popup-third-last-content-text">
                                      Link to Website
                                    </p>
                                  </div>

                                  <img src={expandForwardIcon} alt="" />
                                </div>
                              )}
                              <div
                                onClick={() => {
                                  window.open(
                                    chainId > 0
                                      ? `${ChainConfig[chainId]?.blockExplorerUrls[0]}address/${props?.productClicked?.security}`
                                      : `${
                                          ChainConfig[
                                            chainDetails[chainId]?.chainId
                                          ]?.blockExplorerUrls[0]
                                        }address/${
                                          props?.productClicked?.security
                                        }`,
                                    "_blank"
                                  );
                                }}
                                className="asset-info-popup-third-last-content"
                              >
                                <div className="asset-info-popup-third-last-content-text-cont">
                                  <p className="asset-info-popup-third-last-content-text">
                                    Token{" "}
                                    {props?.productClicked?.securitySymbol}
                                  </p>
                                </div>

                                <img src={expandForwardIcon} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {productDetails === "fundamentals" && (
                      <>
                        <div className="asset-info-popup-second-body-description-container">
                          <div>
                            <p className="asset-info-popup-second-body-description-text-header">
                              Business Summary:
                            </p>
                            {props?.productClicked?.offeringDocData && (
                              <>
                                <p
                                  className="asset-info-popup-second-body-description-text"
                                  id="asset-info-popup-business-summary"
                                >
                                  <>
                                    {typeof props?.productClicked
                                      ?.offeringDocData?.Business?.Summary ===
                                    "string"
                                      ? props?.productClicked?.offeringDocData
                                          ?.Business?.Summary
                                      : ""}

                                    {Object.keys(
                                      props?.productClicked?.offeringDocData
                                        ?.Business || {}
                                    ).map((key) => (
                                      <>
                                        {String(key) !== "Summary" && (
                                          <>
                                            <p
                                              style={{ marginTop: "5px" }}
                                              className="asset-info-popup-second-body-description-text-header"
                                            >
                                              {key}:
                                            </p>
                                            {typeof props?.productClicked
                                              ?.offeringDocData?.Business[
                                              String(key)
                                            ] === "object" && (
                                              <>
                                                {Object.keys(
                                                  props?.productClicked
                                                    ?.offeringDocData?.Business[
                                                    String(key) || {}
                                                  ]
                                                ).map((_key) => (
                                                  <p className="asset-info-popup-second-body-description-text">
                                                    <b>{_key}</b>:{" "}
                                                    {typeof props
                                                      ?.productClicked
                                                      ?.offeringDocData
                                                      ?.Business[String(key)][
                                                      String(_key)
                                                    ] === "string" &&
                                                      props?.productClicked
                                                        ?.offeringDocData
                                                        ?.Business[String(key)][
                                                        String(_key)
                                                      ]}
                                                  </p>
                                                ))}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ))}
                                  </>
                                  {props?.productClicked?.offeringDocData[
                                    "Offering docs urls"
                                  ] && (
                                    <p
                                      style={{ marginTop: "5px" }}
                                      className="asset-info-popup-second-body-description-text-header"
                                    >
                                      Offering Documents:
                                    </p>
                                  )}

                                  <p className="asset-info-popup-second-body-description-link">
                                    {props?.productClicked?.offeringDocData[
                                      "Offering docs urls"
                                    ]?.map((url) => (
                                      <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="asset-info-popup-second-body-description-text-link"
                                      >
                                        {" "}
                                        {url}
                                      </a>
                                    ))}
                                  </p>
                                </p>
                                {businessTag &&
                                  businessTag.scrollHeight >
                                    businessTag.clientHeight && (
                                    <p
                                      onClick={() => {
                                        if (
                                          businessTag.className ===
                                          "asset-info-popup-second-body-description-text-full"
                                        ) {
                                          businessTag.className =
                                            "asset-info-popup-second-body-description-text";
                                          setBusinessViewMoreClicked(false);
                                        } else {
                                          businessTag.className =
                                            "asset-info-popup-second-body-description-text-full";
                                          setBusinessViewMoreClicked(true);
                                        }
                                      }}
                                      className="asset-info-popup-second-body-description-link"
                                    >
                                      ...
                                      <span className="asset-info-popup-second-body-description-text-link">
                                        {" "}
                                        {!businessViewMoreClicked
                                          ? "View More."
                                          : "View Less."}
                                      </span>
                                    </p>
                                  )}
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          id="asset-info-popup-second-body-description-container-financial"
                          className="asset-info-popup-second-body-description-container"
                        >
                          <div>
                            <p className="asset-info-popup-second-body-description-text-header">
                              Financial Summary:
                            </p>
                            {props.productClicked?.offeringDocData && (
                              <>
                                {typeof props.productClicked?.offeringDocData
                                  ?.Financial?.Summary === "string" && (
                                  <p
                                    className="asset-info-popup-second-body-description-text"
                                    id="asset-info-popup-financial-summary"
                                  >
                                    {
                                      props.productClicked?.offeringDocData
                                        ?.Financial?.Summary
                                    }
                                  </p>
                                )}
                                {typeof props.productClicked?.offeringDocData
                                  ?.Financial?.Summary === "object" && (
                                  <>
                                    {Object.keys(
                                      props.productClicked?.offeringDocData
                                        ?.Financial?.Summary || {}
                                    ).map((key) => (
                                      <>
                                        <p
                                          className="asset-info-popup-second-body-description-text"
                                          id="asset-info-popup-financial-summary"
                                        >
                                          <b>{key}:</b>
                                          {typeof props.productClicked
                                            ?.offeringDocData?.Financial
                                            ?.Summary[String(key)] ===
                                            "object" &&
                                            Object.keys(
                                              props.productClicked
                                                ?.offeringDocData?.Financial
                                                ?.Summary[String(key)] || {}
                                            ).map((_key) => (
                                              <>
                                                {String(_key) !== "items" && (
                                                  <p
                                                    className="asset-info-popup-second-body-description-text"
                                                    id="asset-info-popup-financial-summary"
                                                  >
                                                    <b>
                                                      {capitalizeFirstLetter(
                                                        String(_key)
                                                      )}
                                                      :{" "}
                                                      {typeof props
                                                        .productClicked
                                                        ?.offeringDocData
                                                        ?.Financial?.Summary[
                                                        String(key)
                                                      ][String(_key)] ===
                                                      "string"
                                                        ? props.productClicked
                                                            ?.offeringDocData
                                                            ?.Financial
                                                            ?.Summary[
                                                            String(key)
                                                          ][String(_key)]
                                                        : ""}
                                                    </b>
                                                  </p>
                                                )}

                                                {String(_key) === "items" && (
                                                  <>
                                                    {props.productClicked?.offeringDocData?.Financial?.Summary[
                                                      String(key)
                                                    ][String(_key)].map(
                                                      (obj) => (
                                                        <table
                                                          style={{
                                                            width: "100%",
                                                            textAlign: "center",
                                                            borderCollapse:
                                                              "collapse",
                                                            marginBottom: "2px",
                                                          }}
                                                        >
                                                          <thead>
                                                            <tr>
                                                              {Object.keys(
                                                                obj || {}
                                                              ).map((key) => (
                                                                <th
                                                                  style={{
                                                                    // maxWidth: `${
                                                                    //   100 /
                                                                    //   Object.keys(obj)
                                                                    //     .length
                                                                    // }%`,
                                                                    whiteSpace:
                                                                      "normal",
                                                                    wordWrap:
                                                                      "break-word",
                                                                    border:
                                                                      "1px solid",
                                                                  }}
                                                                >
                                                                  {key}
                                                                </th>
                                                              ))}
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              {Object.values(
                                                                obj || {}
                                                              ).map((val) => (
                                                                <td
                                                                  style={{
                                                                    whiteSpace:
                                                                      "normal",
                                                                    wordWrap:
                                                                      "break-word",
                                                                    border:
                                                                      "1px solid",
                                                                  }}
                                                                >
                                                                  {val}
                                                                </td>
                                                              ))}
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ))}
                                        </p>
                                      </>
                                    ))}
                                  </>
                                )}

                                {!financialViewMoreClicked &&
                                  financialTag &&
                                  financialTag.scrollHeight >
                                    financialTag.clientHeight && (
                                    <p
                                      onClick={() => {
                                        if (
                                          financialTag.className ===
                                          "asset-info-popup-second-body-description-text-full"
                                        ) {
                                          financialTag.className =
                                            "asset-info-popup-second-body-description-text";
                                          setFinancialViewMoreClicked(false);
                                        } else {
                                          financialTag.className =
                                            "asset-info-popup-second-body-description-text-full";
                                          setFinancialViewMoreClicked(true);
                                        }
                                      }}
                                      className="asset-info-popup-second-body-description-link"
                                    >
                                      ...
                                      <span className="asset-info-popup-second-body-description-text-link">
                                        {" "}
                                        {!financialViewMoreClicked
                                          ? "View More."
                                          : "View Less."}
                                      </span>
                                    </p>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {showAssetTrading &&
                tradeType === "buy" &&
                Number(userBalances.currency) < Number(tradeAmount) &&
                props.productClicked.currencySymbol.startsWith("VC") && (
                  <>
                    <p className="asset-info-popup-second-trading-header-text">
                      Select a mode of payment
                    </p>
                    <div
                      id="asset-info-popup-pay-with-bank-account"
                      className="asset-info-popup-second-trading-body"
                    >
                      <div className="asset-info-popup-second-trading-body-header">
                        <div className="asset-info-popup-second-trading-body-icon-first">
                          <img
                            src={bankAccountIcon}
                            alt="Wallet Icon"
                            className="asset-info-popup-trading-info-icon"
                          />
                        </div>

                        <p className="asset-info-popup-second-trading-body-text">
                          Pay with Card/Bank Account
                        </p>
                        <div className="asset-info-popup-second-trading-body-icon-second">
                          <img
                            onClick={(e) => {
                              e.target.src.split("/")[
                                e.target.src.split("/").length - 1
                              ] ===
                              radioButtonIcon.split("/")[
                                radioButtonIcon.split("/").length - 1
                              ]
                                ? (e.target.src = radioButtonClickedIcon)
                                : (e.target.src = radioButtonIcon);
                              const frame = document.getElementById(
                                "asset-info-popup-pay-with-bank-account"
                              );

                              if (
                                frame &&
                                frame.style.border !==
                                  "1.5px solid rgb(65, 115, 47)"
                              ) {
                                frame.style.border =
                                  "1.5px solid rgb(65, 115, 47)";
                                setPayWithBank(true);
                              } else if (frame) {
                                frame.style.border = "1px solid #EEEEF0";
                                setPayWithBank(false);
                              }
                            }}
                            src={radioButtonIcon}
                            alt="Radio Icon"
                            className="asset-info-popup-trading-info-icon"
                          />
                        </div>
                      </div>
                      <div className="asset-info-popup-second-trading-body-line"></div>
                      <div className="asset-info-popup-second-trading-body-fotter"></div>
                    </div>
                    {/* <div
                      id="asset-info-popup-pay-with-verified-wallet"
                      className="asset-info-popup-second-trading-body"
                    >
                      <div className="asset-info-popup-second-trading-body-header">
                        <div className="asset-info-popup-second-trading-body-icon-first">
                          <img
                            src={walletIcon}
                            alt="Wallet Icon"
                            className="asset-info-popup-trading-info-icon"
                          />
                        </div>

                        <p className="asset-info-popup-second-trading-body-text">
                          Pay with Verified Wallet
                        </p>
                        <div className="asset-info-popup-second-trading-body-icon-second">
                          <img
                            onClick={(e) => {
                              e.target.src.split("/")[
                                e.target.src.split("/").length - 1
                              ] ===
                              radioButtonIcon.split("/")[
                                radioButtonIcon.split("/").length - 1
                              ]
                                ? (e.target.src = radioButtonClickedIcon)
                                : (e.target.src = radioButtonIcon);
                              const frame = document.getElementById(
                                "asset-info-popup-pay-with-verified-wallet"
                              );

                              if (
                                frame &&
                                frame.style.border !==
                                  "1.5px solid rgb(65, 115, 47)"
                              ) {
                                frame.style.border =
                                  "1.5px solid rgb(65, 115, 47)";
                                setPayWithVerifiedWallet(true);
                              } else if (frame) {
                                frame.style.border = "1px solid #EEEEF0";
                                setPayWithVerifiedWallet(false);
                              }
                            }}
                            src={radioButtonIcon}
                            alt="Radio Icon"
                            className="asset-info-popup-trading-info-icon"
                          />
                        </div>
                      </div>
                      <div className="asset-info-popup-second-trading-body-line"></div>
                      <div className="asset-info-popup-second-trading-body-fotter"></div>
                    </div> */}
                  </>
                )}
            </div>
          </div>
        )}

        {showSetCollateral && (
          <div className="asset-info-popup-body">
            <div className="asset-info-popup-first">
              <div className="asset-info-popup-collateral-container">
                <p className="asset-info-popup-collateral-header-text-title">
                  Select an investment to put as collateral.
                </p>
                <div className="asset-info-popup-second">
                  <div className="asset-info-popup-second-trading-body">
                    <div className="asset-info-popup-second-trading-body-line"></div>
                    <p className="add-funds-content-footer-text">
                      Enter Collateral Amount
                    </p>
                    <div className="a-f-content-footer-input-container">
                      <div className="a-f-content-footer-input-group">
                        <p className="a-f-content-footer-input-text">$</p>
                        <input
                          id="add-funds-popup-input"
                          type="number"
                          className="a-f-content-footer-input"
                          placeholder="00.00"
                        />
                      </div>
                      <div className="a-f-content-footer-currencies">
                        <div className="a-f-content-footer-currencies-container">
                          <p className="a-f-content-footer-currency">USD</p>
                          <img alt="Exand Icon" src={exandIcon} />
                        </div>
                      </div>
                    </div>

                    <div className="asset-info-popup-second-trading-body-fotter"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="asset-info-popup-second">
              {/* <p className="asset-info-popup-second-trading-header-text">
                Select a mode of payment
              </p>
              <div
                id="asset-info-popup-pay-with-verified-wallet"
                className="asset-info-popup-second-trading-body"
              >
                <div className="asset-info-popup-second-trading-body-header">
                  <div className="asset-info-popup-second-trading-body-icon-first">
                    <img
                      src={walletIcon}
                      alt="Wallet Icon"
                      className="asset-info-popup-trading-info-icon"
                    />
                  </div>

                  <p className="asset-info-popup-second-trading-body-text">
                    Pay with Verified Wallet
                  </p>
                  <div className="asset-info-popup-second-trading-body-icon-second">
                    <img
                      onClick={(e) => {
                        e.target.src.split("/")[
                          e.target.src.split("/").length - 1
                        ] ===
                        radioButtonIcon.split("/")[
                          radioButtonIcon.split("/").length - 1
                        ]
                          ? (e.target.src = radioButtonClickedIcon)
                          : (e.target.src = radioButtonIcon);
                        const frame = document.getElementById(
                          "asset-info-popup-pay-with-verified-wallet"
                        );

                        if (
                          frame &&
                          frame.style.border !== "1.5px solid rgb(65, 115, 47)"
                        ) {
                          frame.style.border = "1.5px solid rgb(65, 115, 47)";
                          setPayWithVerifiedWallet(true);
                        } else if (frame) {
                          frame.style.border = "1px solid #EEEEF0";
                          setPayWithVerifiedWallet(false);
                        }
                      }}
                      src={radioButtonIcon}
                      alt="Radio Icon"
                      className="asset-info-popup-trading-info-icon"
                    />
                  </div>
                </div>
                <div className="asset-info-popup-second-trading-body-line"></div>
                <div className="asset-info-popup-second-trading-body-fotter">
                  <div className="asset-info-popup-second-trading-body-fotter-first">
                    <div className="asset-info-popup-second-trading-body-fotter-first-title">
                      <p className="asset-info-popup-second-trading-body-fotter-first-title-text">
                        Available Balance
                      </p>
                      <p className="asset-info-popup-second-trading-body-fotter-first-text">
                        $ 2200.00
                      </p>
                    </div>
                    <button className="asset-info-popup-second-trading-body-fotter-first-button">
                      <p className="asset-info-popup-second-trading-body-fotter-first-button-text">
                        USD
                      </p>
                      <img src={defaultExpandMoreIcon} alt="Expand Icon" />
                    </button>
                  </div>
                  <div className="asset-info-popup-second-trading-body-fotter-second">
                    <p className="asset-info-popup-second-trading-body-fotter-second-text">
                      Add Funds
                    </p>
                    <img src={expandMoreIcon} alt="Expand Icon" />
                  </div>
                </div>
              </div> */}

              {/* <div
                id="asset-info-popup-pay-with-bank-account"
                className="asset-info-popup-second-trading-body"
              >
                <div className="asset-info-popup-second-trading-body-header">
                  <div className="asset-info-popup-second-trading-body-icon-first">
                    <img
                      src={bankAccountIcon}
                      alt="Wallet Icon"
                      className="asset-info-popup-trading-info-icon"
                    />
                  </div>

                  <p className="asset-info-popup-second-trading-body-text">
                    Pay with Bank Account
                  </p>
                  <div className="asset-info-popup-second-trading-body-icon-second">
                    <img
                      onClick={(e) => {
                        e.target.src.split("/")[
                          e.target.src.split("/").length - 1
                        ] ===
                        radioButtonIcon.split("/")[
                          radioButtonIcon.split("/").length - 1
                        ]
                          ? (e.target.src = radioButtonClickedIcon)
                          : (e.target.src = radioButtonIcon);
                        const frame = document.getElementById(
                          "asset-info-popup-pay-with-bank-account"
                        );

                        if (
                          frame &&
                          frame.style.border !== "1.5px solid rgb(65, 115, 47)"
                        ) {
                          frame.style.border = "1.5px solid rgb(65, 115, 47)";
                          setPayWithBank(true);
                        } else if (frame) {
                          frame.style.border = "1px solid #EEEEF0";
                          setPayWithBank(false);
                        }
                      }}
                      src={radioButtonIcon}
                      alt="Radio Icon"
                      className="asset-info-popup-trading-info-icon"
                    />
                  </div>
                </div>
                <div className="asset-info-popup-second-trading-body-line"></div>
                <div className="asset-info-popup-second-trading-body-fotter"></div>
              </div> */}
            </div>
          </div>
        )}
      </div>

      {!showSetCollateral && (
        <div
          style={{ marginTop: "50px", marginBottom: "50px" }}
          className="asset-info-popup-buttons"
        >
          {!showAssetTrading && (
            <>
              <div
                onClick={() => {
                  setShowAssetTrading(true);
                  setTradeType("sell");
                }}
                className="asset-info-popup-button-sell"
              >
                <p className="asset-info-popup-button-text">Sell</p>
              </div>
              <div
                onClick={() => {
                  setShowAssetTrading(true);
                  setTradeType("buy");
                }}
                className="asset-info-popup-button-buy"
              >
                <p className="asset-info-popup-button-text">Buy</p>
              </div>
            </>
          )}
          {showAssetTrading && tradeType === "buy" && (
            <div
              onClick={async () => {
                if (payWithVerifiedWallet) {
                  props.setFavouriteClicked(false);
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <AddFunds
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                    />
                  );
                  setTradCategory("market");
                  setTradePrice(null);
                  setIsTradeInput(false);
                  setPayWithVerifiedWallet(false);
                  navigate("/payments/add-funds");
                } else if (payWithBank) {
                  props.setFavouriteClicked(false);
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <AddFunds
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                    />
                  );
                  setTradCategory("market");
                  setTradePrice(null);
                  setIsTradeInput(false);
                  setPayWithVerifiedWallet(false);
                  navigate("/payments/add-funds");
                } else {
                  let signer;
                  if (
                    provider &&
                    provider.getSigner &&
                    chainId &&
                    userAddress.length > 0
                  ) {
                    signer = provider.getSigner(userAddress);
                  }
                  if (signer && userAddress) {
                    if (Number(tradeAmount) <= 0) {
                      errorToast(
                        "Invalid amount to buy",
                        "Please enter a valid amount"
                      );
                      return;
                    }
                    if (
                      Object.keys(paraswapDetails).length === 0 &&
                      Number(tradeAmount) <
                        Number(props.productClicked?.minOrderSize)
                    ) {
                      errorToast(
                        "Amount lesser than minimum order size.",
                        props.productClicked?.minOrderSize
                          ? `Please enter at least ${props.productClicked?.minOrderSize}`
                          : "Please enter at least minimum order size"
                      );
                      return;
                    }
                    props.setCustomLoading(true);
                    await authenticateKYCStatus(
                      chainId,
                      userAddress,
                      provider.getSigner(userAddress),
                      appKitModal,
                      props.setPopupContent,
                      props.setPopupContentPosition,
                      props.setShowPopup,
                      props.setCustomLoading,
                      props.setFavouriteClicked
                    ).then(async (res) => {
                      if (res) {
                        let shouldProceed = true;
                        if (Object.keys(paraswapDetails).length > 0) {
                          if (
                            Number(paraswapDetails.amount) <
                            Number(props.productClicked?.minOrderSize)
                          ) {
                            shouldProceed = false;
                            props.setCustomLoading(false);
                            errorToast(
                              "Amount to swap lesser than minimum order size.",
                              props.productClicked?.minOrderSize
                                ? `Please swap out at least ${props.productClicked?.minOrderSize}`
                                : "Please swap out at least minimum order size"
                            );

                            return;
                          }
                          await swapTokenWithParaSwap(
                            userAddress,
                            chainId,
                            provider,
                            web3,
                            paraswapDetails.route
                          ).then((res) => {
                            if (!res) {
                              shouldProceed = false;
                              props.setCustomLoading(false);
                              errorToast(
                                "Token Swap on Paraswap Failed.",
                                `Create order with: ${props?.productClicked.currencySymbol} or try again.`
                              );
                            } else {
                              shouldProceed = true;
                              successToast(
                                "Transaction Successful!",
                                `${selectedCurrency.symbol} Swapped to ${props?.productClicked.currencySymbol} Successfully`,
                                res?.hash ? res.hash : ""
                              );
                            }
                          });
                        }
                        if (
                          shouldProceed &&
                          props.productClicked.poolType !== PoolType.primaryPool
                        ) {
                          //handle margin and secondary pool buy
                          let amountInWei;
                          const priceWei =
                            tradePrice > 0
                              ? ethers.utils.parseEther(tradePrice.toString())
                              : ethers.utils.parseEther(limitPrice.toString());
                          if (
                            props.productClicked.poolType ===
                            PoolType.marginPool
                          ) {
                            //use margin amount for margin buy
                            amountInWei = ethers.utils.parseUnits(
                              (
                                Number(
                                  props.productClicked.currentPrice *
                                    Number(tradeAmount)
                                ) * Number(props.productClicked.margin)
                              ).toString(),
                              props.productClicked.currencyDecimals.toString()
                            );
                          } else {
                            amountInWei =
                              Object.keys(paraswapDetails).length > 0
                                ? ethers.utils.parseUnits(
                                    paraswapDetails?.amount?.toString(),
                                    props.productClicked.currencyDecimals.toString()
                                  )
                                : ethers.utils.parseUnits(
                                    tradeAmount.toString(),
                                    props.productClicked.currencyDecimals.toString()
                                  );
                          }

                          const isLimit =
                            tradeCategory === "limit" ? true : false;
                          await createSecondaryAndMarginOrder(
                            userAddress,
                            signer,
                            props.productClicked.id,
                            props.productClicked.currency,
                            props.productClicked.address,
                            "VPT",
                            18,
                            props.productClicked.tokensList,
                            amountInWei,
                            isLimit ? priceWei.toString() : "0",
                            isLimit
                          ).then(() => props.setCustomLoading(false));
                        } else if (shouldProceed) {
                          //handle primary pool buy
                          const amountInWei = ethers.utils.parseEther(
                            tradeAmount.toString()
                          );
                          const calculatedAmountOut = ethers.utils.parseUnits(
                            sorValue.amountOut.toString(),
                            Number(props.productClicked.currencyDecimals)
                          );
                          const issueManagerAddress =
                            contractAddress[chainId]
                              .BalancerPrimaryIssueManager;
                          await createPrimaryOrder(
                            userAddress,
                            signer,
                            props.productClicked.id,
                            props.productClicked.currency,
                            props.productClicked.security,
                            props.productClicked.securitySymbol,
                            Number(props.productClicked.securityDecimals),
                            props.productClicked.tokensList,
                            amountInWei,
                            calculatedAmountOut,
                            issueManagerAddress,
                            "buy"
                          ).then(() => props.setCustomLoading(false));
                        }
                      }
                    });
                  } else if (userAddress === "") {
                    //connect user wallet if not connected
                    appKitModal.open();
                  }
                }
              }}
              style={{ width: "100%" }}
              className="asset-info-popup-button-buy"
            >
              <p className="asset-info-popup-button-text">Buy</p>
            </div>
          )}
          {showAssetTrading && tradeType === "sell" && (
            <div
              onClick={async () => {
                if (payWithVerifiedWallet) {
                  props.setFavouriteClicked(false);
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <AddFunds
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                    />
                  );
                  setTradCategory("market");
                  setTradePrice(null);
                  setIsTradeInput(false);
                  setPayWithVerifiedWallet(false);
                  setPayWithBank(false);
                  navigate("/payments/add-funds");
                } else {
                  let signer;
                  if (
                    provider &&
                    provider.getSigner &&
                    chainId &&
                    userAddress.length > 0
                  ) {
                    signer = provider.getSigner(userAddress);
                  }
                  if (signer && userAddress) {
                    if (Number(tradeAmount) <= 0) {
                      errorToast(
                        "Invalid amount to buy",
                        "Please enter a valid amount"
                      );
                      return;
                    }
                    if (
                      Number(tradeAmount) <
                      Number(props.productClicked?.minOrderSize)
                    ) {
                      errorToast(
                        "Amount lesser than minimum order size.",
                        props.productClicked?.minOrderSize
                          ? `Please enter at least ${props.productClicked?.minOrderSize}`
                          : "Please enter at least minimum order size"
                      );

                      return;
                    }
                    props.setCustomLoading(true);

                    await authenticateKYCStatus(
                      chainId,
                      userAddress,
                      provider.getSigner(userAddress),
                      appKitModal,
                      props.setPopupContent,
                      props.setPopupContentPosition,
                      props.setShowPopup,
                      props.setCustomLoading,
                      props.setFavouriteClicked
                    ).then(async (res) => {
                      if (res) {
                        if (
                          props.productClicked.poolType !== PoolType.primaryPool
                        ) {
                          const priceWei =
                            tradePrice > 0
                              ? ethers.utils.parseEther(tradePrice.toString())
                              : ethers.utils.parseEther(limitPrice.toString());
                          const amountInWei = ethers.utils.parseUnits(
                            tradeAmount.toString(),
                            props.productClicked.securityDecimals.toString()
                          );
                          const isLimit =
                            tradeCategory === "limit" ? true : false;
                          await createSecondaryAndMarginOrder(
                            userAddress,
                            signer,
                            props.productClicked.id,
                            props.productClicked.security,
                            props.productClicked.address,
                            "VPT",
                            18,
                            props.productClicked.tokensList,
                            amountInWei,
                            isLimit ? priceWei.toString() : "0",
                            isLimit
                          ).then(() => props.setCustomLoading(false));
                        } else {
                          const amountInWei = ethers.utils.parseEther(
                            tradeAmount.toString()
                          );
                          const calculatedAmountOut = ethers.utils.parseUnits(
                            sorValue.amountOut.toString(),
                            Number(props.productClicked.currencyDecimals)
                          );
                          const issueManagerAddress =
                            contractAddress[chainId]
                              .BalancerPrimaryIssueManager;

                          await createPrimaryOrder(
                            userAddress,
                            signer,
                            props.productClicked.id,
                            props.productClicked.security,
                            props.productClicked.currency,
                            props.productClicked.currencySymbol,
                            Number(props.productClicked.currencyDecimals),
                            props.productClicked.tokensList,
                            amountInWei,
                            calculatedAmountOut,
                            issueManagerAddress,
                            "sell"
                          ).then(async (res) => {
                            if (Object.keys(paraswapDetails).length > 0) {
                              await swapTokenWithParaSwap(
                                userAddress,
                                chainId,
                                provider,
                                web3,
                                paraswapDetails.route
                              ).then((res) => {
                                if (!res) {
                                  toast.warn(
                                    `Token Swap on Paraswap Failed. ${props?.productClicked.currencySymbol} Has Been Deposited To Your Wallet`
                                  );
                                } else {
                                  successToast(
                                    "Transaction Successful!",
                                    `${props?.productClicked.currencySymbol} Swapped to ${selectedCurrency.symbol} Successfully`,
                                    res?.hash ? res.hash : ""
                                  );
                                }
                              });
                            }
                            props.setCustomLoading(false);
                          });
                        }
                      }
                    });
                  } else if (userAddress === "") {
                    //connect user wallet if not connected
                    appKitModal.open();
                  }
                }
              }}
              style={{ width: "100%" }}
              className="asset-info-popup-button-sell"
            >
              <p className="asset-info-popup-button-text">Sell</p>
            </div>
          )}
        </div>
      )}
      {showSetCollateral && (
        <button
          onClick={() => {
            setCollateralAdded(2200.0);
            setPayWithBank(false);
            setPayWithVerifiedWallet(false);
            setShowSetCollateral(false);
          }}
          className="asset-info-popup-collateral-button"
        >
          <p className="asset-info-popup-collateral-button-text">
            Pay Collateral
          </p>
        </button>
      )}
    </div>
  );
};

export default AssetInfoPage;
