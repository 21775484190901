import { useNavigate } from "react-router-dom";
import PageSetup from "..";
import error404Icon from "../../assests/overlay/error-404.svg";

const ErrorPage = (props) => {
  const navigate = useNavigate();
  return (
    <div className="error-404-page">
      <div className="error-404-page-container">
        <img src={error404Icon} alt="Error Icon" />
        <p className="error-404-page-title">Oops....</p>

        <div className="error-404-body-cont">
          <p className="error-404-body-title">Something went wrong</p>

          <p className="error-404-body-label">
            This Page doesn't exist or was removed! We suggest you back to home.
          </p>
        </div>
      </div>
      <button
        id="borrow-popup-next-button"
        onClick={async (e) => {
          navigate("/");
        }}
        className="startup-button-active"
      >
        <p className="add-funds-popup-a-f-button-text">Refresh</p>
      </button>
    </div>
  );
};

export default ErrorPage;
