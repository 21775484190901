import expandIcon from "../../assests/page/chevron-down.svg";
import closeDropdown from "../../assests/page/chevron-up.svg";
import closeIcon from "../../assests/overlay/close-icon.svg";
import { useState } from "react";
import {
  ChainConfig,
  chainDetails,
  supportedChainsIds,
} from "../../utils/config";
import closeExpandIcon from "../../assests/overlay/close-expand.svg";
import searchIcon from "../../assests/sideBar/search-icon.svg";
import checkCircle from "../../assests/overlay/check-circle.svg";
import infoIcon from "../../assests/overlay/info-blue.svg";
import { wagmiAdapter } from "../..";
import ethereumIcon from "../../assests/overlay/ethereum-icon.svg";
import ProfilePopup from "./profile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { switchNetwork } from "../../utils/helpers";

export const UnsupportedNetwork = (props) => {
  const [selectedNetwork, setSelectedNetwork] = useState({});

  const handleSwitchNetworkButtonDisplay = () => {
    const button = document.getElementById("switch-network-button");
    if (button && Object.keys(selectedNetwork).length > 0) {
      button.className = "startup-button-active";
    } else if (button) {
      button.className = "startup-button";
    }
  };

  return (
    <div className="unsupported-network-popup">
      <div className="unsupported-network-popup-cont">
        <div className="unsupported-network-popup-first">
          <p className="unsupported-network-popup-first-title">
            Unsupported Network
          </p>
          <p className="unsupported-network-popup-first-label">
            The network you’er connected to is currently not supported by
            Verified Network. You’er currently connect to bitcoin network.
          </p>
          <div className="unsupported-network-popup-first-img">
            <img src={closeIcon} alt="Close Icon" />
          </div>
        </div>
        <div className="unsupported-network-popup-second">
          <p className="unsupported-network-popup-second-title">
            Supported network are:{" "}
            <span className="unsupported-network-popup-second-label">
              Ethereum, Base Mainnet.
            </span>
          </p>

          <div className="unsupported-network-popup-dropdown-cont">
            <div className="unsupported-network-popup-dropdown">
              {!selectedNetwork.clicked && (
                <div
                  onClick={() => {
                    setSelectedNetwork({ ...selectedNetwork, clicked: true });
                  }}
                  className="unsupported-network-popup-dropdown-text-cont"
                >
                  <p className="unsupported-network-popup-dropdown-text">
                    Select Network
                  </p>
                </div>
              )}
              {selectedNetwork.clicked && (
                <div
                  style={{
                    width: "100%",
                  }}
                  className="a-f-content-footer-input-group"
                >
                  <input
                    onInput={(e) => {
                      setSelectedNetwork({
                        ...selectedNetwork,
                        search: e.target.value,
                      });
                    }}
                    type="text"
                    className="offer-security-expand-input"
                    placeholder="search"
                  />
                  <img
                    onClick={() => {
                      setSelectedNetwork({
                        ...selectedNetwork,
                        clicked: false,
                        search: "",
                      });
                    }}
                    alt="Close Icon"
                    src={closeExpandIcon}
                  />
                </div>
              )}
              <img
                onClick={() => {
                  setSelectedNetwork({ ...selectedNetwork, clicked: true });
                }}
                src={expandIcon}
                alt="Expand Icon"
              />
            </div>
            <div className="offer-security-popup-body-expand-container">
              {supportedChainsIds.map((chn, idx) => (
                <>
                  {selectedNetwork.search === "" && (
                    <div
                      id={idx}
                      onClick={() => {
                        setSelectedNetwork({
                          ...ChainConfig[chn],
                          clicked: false,
                          search: "",
                        });
                        handleSwitchNetworkButtonDisplay();
                      }}
                      className="offer-security-popup-body-first-expand-cont"
                    >
                      <p className="offer-security-popup-body-first-expand-text">
                        {chn}
                      </p>

                      <div
                        style={{
                          height: "1px",
                          width: "100%",
                          background: "#EEEEF0",
                        }}
                      ></div>
                    </div>
                  )}
                  {selectedNetwork.search?.length > 0 &&
                    selectedNetwork.chainName
                      ?.toLowerCase()
                      .includes(selectedNetwork.search.toLowerCase()) && (
                      <div
                        id={idx}
                        onClick={() => {
                          setSelectedNetwork({
                            ...ChainConfig[chn],
                            clicked: false,
                            search: "",
                          });
                          handleSwitchNetworkButtonDisplay();
                        }}
                        className="offer-security-popup-body-first-expand-cont"
                      >
                        <p className="offer-security-popup-body-first-expand-text">
                          {chn}
                        </p>

                        <div
                          style={{
                            height: "1px",
                            width: "100%",
                            background: "#EEEEF0",
                          }}
                        ></div>
                      </div>
                    )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      <button
        id="switch-network-button"
        onClick={async (e) => {
          if (
            e.target.className.includes("startup-button") &&
            e.target.className !== "startup-button"
          ) {
            e.target.className = "startup-button-click";
            //
          } else {
            const buttonElement = document.getElementsByClassName(
              "startup-button-active"
            );
            if (buttonElement.length > 0) {
              buttonElement[0].className = "startup-button-click";
              //
            }
          }
        }}
        className="startup-button"
      >
        <p className="add-funds-popup-a-f-button-text">Switch Network</p>
      </button>
    </div>
  );
};

export const SwitchNetwork = (props) => {
  const { chainId } = useSelector((state) => state.network);

  const [selectedNetworkId, setSelectedNetworkId] = useState(chainId);

  const navigate = useNavigate();

  return (
    <div className="switch-network-popup">
      <div className="switch-network-popup-first">
        <div className="switch-network-popup-first-section">
          <div className="switch-network-popup-first-section-cont">
            <p className="switch-network-popup-first-section-title">
              Choose Network
            </p>

            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("?profile");
                props.setPopupContentPosition("flex-end");
                props.setPopupContent(
                  <ProfilePopup
                    setShowPopup={props.setShowPopup}
                    setPopupContent={props.setPopupContent}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                );
                props.setFavouriteClicked(true);
                props.setShowPopup(true);
              }}
              src={closeIcon}
              alt=""
            />
          </div>
          <div
            style={{
              width: "100%",
              border: "1px solid var(--Neutral-200, #D8D9DF)",
              borderRadius: "8px",
            }}
            className="a-f-content-footer-input-group"
          >
            <input
              type="text"
              className="offer-security-expand-input"
              placeholder="Search Network"
            />
            <img alt="Search Icon" src={searchIcon} />
          </div>
        </div>

        <div className="switch-network-popup-first-section-body">
          {wagmiAdapter?.wagmiChains?.map((chn) => (
            <>
              {selectedNetworkId === chn.id && (
                <div
                  onClick={() => {
                    setSelectedNetworkId(chn.id);
                    props.setShowPopup(false);
                    props.setPopupContent(null);
                    props.setPopupContentPosition("center");
                  }}
                  className="switch-network-popup-first-section-content-cont-active"
                >
                  <div className="switch-network-popup-first-section-content">
                    {chainDetails[Number(chn.id)]?.icon && (
                      <img
                        style={{
                          height: "24px",
                          width: "24px",
                          overflow: "hidden",
                          borderRadius: "40px",
                        }}
                        src={chainDetails[chn.id]?.icon}
                        alt="Chain Icon"
                      />
                    )}

                    <p className="switch-network-popup-first-section-cont-text">
                      {chn.name}
                    </p>
                  </div>

                  <img
                    className="switch-network-popup-first-section-cont-img"
                    src={checkCircle}
                    alt=""
                  />
                </div>
              )}
              {selectedNetworkId !== chn.id && (
                <div
                  onClick={async () => {
                    setSelectedNetworkId(chn.id);
                    props.setCustomLoading(true);

                    await switchNetwork(chn.id, window).then((res) => {
                      props.setCustomLoading(false);
                      if (res) {
                        props.setShowPopup(false);
                        props.setPopupContent(null);
                        props.setPopupContentPosition("center");
                      }
                    });
                  }}
                  className="switch-network-popup-first-section-content-cont"
                >
                  <div className="switch-network-popup-first-section-content">
                    {chainDetails[Number(chn.id)]?.icon && (
                      <img
                        style={{
                          height: "24px",
                          width: "24px",
                          overflow: "hidden",
                          borderRadius: "40px",
                        }}
                        src={chainDetails[chn.id]?.icon}
                        alt="Chain Icon"
                      />
                    )}
                    <p className="switch-network-popup-first-section-cont-text">
                      {chn.name}
                    </p>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>

      <div className="switch-network-popup-line"></div>

      <div className="switch-network-popup-second">
        <p className="switch-network-popup-second-title">
          Your connect wallet may not supported some of the network available
          for this dApp
        </p>
        <div className="switch-network-popup-second-text-cont">
          <img src={infoIcon} alt="Info Icon" />
          <a
            className="switch-network-popup-second-text"
            href="https://ethereum.org/en/developers/docs/networks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            What is a network
          </a>
        </div>
      </div>
    </div>
  );
};
