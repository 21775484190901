import { contractAddress, ERC20, Pool } from "@verified-network/verified-sdk";
import { ethers } from "ethers";
import { toast } from "react-toastify";

export const createSecondaryAndMarginOrder = async (
  userAddress,
  signer,
  poolId,
  tokenIn,
  tokenOut,
  tokenOutSymbol,
  tokenOutDecimals,
  tokensList,
  amountInWei,
  priceWei,
  isLimit
) => {
  try {
    const abiCoder = new ethers.utils.AbiCoder();
    const balancerVaultAddress = contractAddress.balancerVault;
    let limitArr, userData, tokenInIndex, tokenOutIndex;

    if (isLimit) {
      userData = abiCoder.encode(
        ["bytes32", "uint"],
        [ethers.utils.formatBytes32String("Limit"), priceWei]
      );
    } else {
      userData = abiCoder.encode([], []);
    }
    tokenInIndex = Number(tokensList.indexOf(tokenIn));
    tokenOutIndex = Number(tokensList?.indexOf(tokenOut));
    limitArr = new Array(tokensList.length).fill("0");
    limitArr[tokenInIndex] = amountInWei.toString();
    const tokenInContract = new ERC20(signer, tokenIn);
    const tokenOutContract = new ERC20(signer, tokenOut);
    const tokenOutBalance = Number(
      await tokenOutContract
        .balanceOf(userAddress)
        .then((res) => res?.response?.result[0])
    );

    let isApproved = false;

    if (balancerVaultAddress) {
      await tokenInContract
        .allowance(userAddress, balancerVaultAddress)
        .then((res) => {
          if (Number(res?.response?.result[0]) >= Number(amountInWei)) {
            isApproved = true;
          }
        });
    }
    if (!isApproved && balancerVaultAddress) {
      await tokenInContract
        .approve(balancerVaultAddress, amountInWei.toString())
        .then((res) => {
          if (res?.status === 0) {
            isApproved = true;
            toast.success("Approved Vault Successfully");
            console.log("Successful approval with hash: ", res?.response?.hash);
          } else {
            toast.error("Approve Transaction Failed!");
            console.error("approval failed");
          }
        })
        .catch((err) => {
          toast.error("Approve Transaction Failed!");
          console.error("approval failed with error: ", err);
        });
    }

    if (isApproved && userAddress && signer) {
      const balancerVaultContract = new Pool(signer);
      await balancerVaultContract
        .batchSwap(
          "0",
          [
            {
              poolId: poolId,
              assetInIndex: tokenInIndex.toString(),
              assetOutIndex: tokenOutIndex.toString(),
              amount: amountInWei.toString(),
              userData: userData,
            },
          ],
          tokensList,
          {
            fromInternalBalance: false,
            recipient: userAddress,
            sender: userAddress,
            toInternalBalance: false,
          },
          limitArr,
          "999999999999999999" // Infinity
        )
        .then(async (res) => {
          if (res?.status === 0) {
            toast.success("Order Created Successfully");
            console.log(
              "successful batch swap with hash: ",
              res?.response?.hash
            );
            // if (
            //   Number(tokenOutBalance) <= 0 &&
            //   typeof window.ethereum !== "undefined"
            // ) {
            //   const tokenOutSymbol = await tokenOutContract
            //     .symbol()
            //     .then((res) => res[0]);
            //   if (tokenOutSymbol) {
            //     await window.ethereum
            //       .request({
            //         method: "wallet_watchAsset",
            //         params: {
            //           type: "ERC20",
            //           options: {
            //             address: tokenOut,
            //             symbol: tokenOutSymbol,
            //             decimals: tokenOutDecimals,
            //           },
            //         },
            //       })
            //       .then((res) => {
            //         if (res) {
            //           toast.success(
            //             `${tokenOutSymbol} Token added to wallet successfully`
            //           );
            //           console.log("Token added to wallet successfully");
            //         } else {
            //           toast.error("Adding token To Wallet Failed.");
            //           console.error("Adding token To Wallet Failed");
            //         }
            //       })
            //       .catch((err) => {
            //         toast.error("Adding token to Wallet Failed.");
            //         console.error("Adding token To Wallet Failed: ", err);
            //       });
            //   }
            // }
          } else {
            toast.error("Order Transaction Failed");
            console.error("batchSwap failed");
          }
        })
        .catch((err) => {
          toast.error("Order Transaction Failed!");
          console.error("batchSwap failed with error: ", err);
        });
    }
  } catch (err) {
    toast.error("Order Transaction Failed!");
    console.error("batchSwap failed with error: ", err);
  }
};

export const createCustomSecondaryAndMarginOrder = async (
  userAddress,
  signer,
  chainId,
  poolId,
  tokenIn,
  tokenOut,
  tokenOutSymbol,
  tokenOutDecimals,
  tokensList,
  amountInWei,
  returningAmount,
  userData,
  isSecondary,
  isCancelOrder
) => {
  // console.log(
  //   userAddress,
  //   signer,
  //   chainId,
  //   poolId,
  //   tokenIn,
  //   tokenOut,
  //   tokenOutSymbol,
  //   tokenOutDecimals,
  //   tokensList,
  //   amountInWei,
  //   returningAmount,
  //   userData,
  //   isSecondary,
  //   isCancelOrder
  // );
  try {
    const balancerVaultAddress = contractAddress.balancerVault;
    const managerAddress =
      contractAddress[chainId].BalancerSecondaryIssueManager;
    let limitArr, tokenInIndex, tokenOutIndex;
    tokenInIndex = Number(tokensList.indexOf(tokenIn));
    tokenOutIndex = Number(tokensList?.indexOf(tokenOut));
    limitArr = new Array(tokensList.length).fill("0");
    limitArr[tokenInIndex] = amountInWei.toString();
    const tokenInContract = new ERC20(signer, tokenIn);
    const tokenOutContract = new ERC20(signer, tokenOut);
    const tokenOutBalance = Number(
      await tokenOutContract
        .balanceOf(userAddress)
        .then((res) => res?.response?.result[0])
    );
    let isApproved = false;
    let firstApprovalFailed = false;
    let isManagerApproved = false;
    let secondApprovalFailed = false;
    if (balancerVaultAddress) {
      await tokenInContract
        .allowance(userAddress, balancerVaultAddress)
        .then((res) => {
          if (Number(res?.response?.result[0]) >= Number(amountInWei)) {
            isApproved = true;
          }
        });
    }
    if (isSecondary && managerAddress) {
      await tokenOutContract
        .allowance(userAddress, managerAddress)
        .then((res) => {
          if (Number(res?.response?.result[0]) >= Number(returningAmount)) {
            isManagerApproved = true;
          }
        });
    }
    if (!isApproved && balancerVaultAddress) {
      await tokenInContract
        .approve(balancerVaultAddress, amountInWei.toString())
        .then((res) => {
          if (res?.status === 0) {
            if (!isSecondary) {
              isApproved = true;
            }
            toast.success("Approved Vault Successfully");
            console.log("Successful approval with hash: ", res?.response?.hash);
          } else {
            firstApprovalFailed = true;
            toast.error("Approve Transaction Failed!");
            console.error("approval failed");
          }
        })
        .catch((err) => {
          firstApprovalFailed = true;
          toast.error("Approve Transaction Failed!");
          console.error("approval failed with error: ", err);
        });
    }

    if (
      isSecondary &&
      !isManagerApproved &&
      !firstApprovalFailed &&
      !isCancelOrder
    ) {
      await tokenOutContract
        .approve(managerAddress, returningAmount.toString())
        .then((res) => {
          if (res?.status === 0) {
            isApproved = true;
            toast.success("Approved Manager Successfully");
            console.log(
              "Successful manager approval with hash: ",
              res?.response?.hash
            );
          } else {
            secondApprovalFailed = true;

            toast.error("Approve Transaction Failed!");
            console.error("approve manager failed");
          }
        })
        .catch((err) => {
          secondApprovalFailed = true;
          toast.error("Approve Transaction Failed!");
          console.error("approve manager failed with error: ", err);
        });
    }
    if (
      isApproved &&
      !firstApprovalFailed &&
      !secondApprovalFailed &&
      userAddress &&
      signer
    ) {
      const balancerVaultContract = new Pool(signer);
      await balancerVaultContract
        .batchSwap(
          "0",
          [
            {
              poolId: poolId,
              assetInIndex: tokenInIndex.toString(),
              assetOutIndex: tokenOutIndex.toString(),
              amount: amountInWei.toString(),
              userData: userData,
            },
          ],
          tokensList,
          {
            fromInternalBalance: false,
            recipient: userAddress,
            sender: userAddress,
            toInternalBalance: false,
          },
          limitArr,
          "999999999999999999" // Infinity
        )
        .then(async (res) => {
          if (res?.status === 0) {
            toast.success("Order Settled Successfully");
            console.log(
              "successful batch swap with hash: ",
              res?.response?.hash
            );
            // if (
            //   Number(tokenOutBalance) <= 0 &&
            //   typeof window.ethereum !== "undefined"
            // ) {
            //   await window.ethereum
            //     .request({
            //       method: "wallet_watchAsset",
            //       params: {
            //         type: "ERC20",
            //         options: {
            //           address: tokenOut,
            //           symbol: tokenOutSymbol,
            //           decimals: tokenOutDecimals,
            //         },
            //       },
            //     })
            //     .then((res) => {
            //       if (res) {
            //         toast.success(
            //           `${tokenOutSymbol} Token added to wallet successfully`
            //         );
            //         console.log("Token added to wallet successfully");
            //       } else {
            //         toast.error("Adding token To Wallet Failed.");
            //         console.error("Adding token To Wallet Failed");
            //       }
            //     })
            //     .catch((err) => {
            //       toast.error("Adding token to Wallet Failed.");
            //       console.error("Adding token To Wallet Failed: ", err);
            //     });
            // }
          } else {
            toast.error("Settle Order Transaction Failed");
            console.error("batchSwap failed");
          }
        })
        .catch((err) => {
          toast.error("Settle Order Transaction Failed!");
          console.error("batchSwap failed with error: ", err);
        });
    }
  } catch (err) {
    toast.error("Transaction Failed!");
    console.error("batchSwap failed with error: ", err);
  }
};

export const createPrimaryOrder = async (
  userAddress,
  signer,
  poolId,
  tokenIn,
  tokenOut,
  tokenOutSymbol,
  tokenOutDecimals,
  tokensList,
  amountInWei,
  calculatedAmount,
  issueManagerAddress,
  orderType
) => {
  try {
    const balancerVaultAddress = contractAddress.balancerVault;
    let limitArr, tokenInIndex, tokenOutIndex;
    tokenInIndex = Number(tokensList.indexOf(tokenIn));
    tokenOutIndex = Number(tokensList?.indexOf(tokenOut));
    limitArr = new Array(tokensList.length).fill("0");
    limitArr[tokenInIndex] = amountInWei.toString();
    const tokenInContract = new ERC20(signer, tokenIn);
    const tokenOutContract = new ERC20(signer, tokenOut);
    const tokenOutBalance = Number(
      await tokenOutContract
        .balanceOf(userAddress)
        .then((res) => res?.response?.result[0])
    );
    let tokenInAllowance, tokenOutAllowance;

    let isApproved = false;
    let firstApprovalFailed = false;
    if (balancerVaultAddress && issueManagerAddress) {
      tokenInAllowance = await tokenInContract
        .allowance(userAddress, balancerVaultAddress)
        .then((res) => res?.response?.result[0]);
      tokenOutAllowance = await tokenOutContract
        .allowance(userAddress, issueManagerAddress)
        .then((res) => res?.response?.result[0]);
    }

    if (orderType === "buy") {
      if (Number(tokenInAllowance) < Number(calculatedAmount)) {
        await tokenInContract
          .approve(balancerVaultAddress, calculatedAmount.toString())
          .then((res) => {
            if (res?.status === 0) {
              toast.success("Approved Vault Successfully");
            } else {
              firstApprovalFailed = true;
              toast.error("Approve Transaction Failed!");
              console.error("vault approval failed");
            }
          })
          .catch((err) => {
            firstApprovalFailed = true;
            toast.error("Approve Transaction Failed!");
            console.error("vault approval failed with error: ", err);
          });
      }
      //handle approval for refunding investment by DP
      //no need for conditional approval it will always increase allowance??
      if (!firstApprovalFailed) {
        await tokenOutContract
          .approve(
            issueManagerAddress,
            (Number(tokenOutAllowance) + Number(amountInWei)).toString()
          )
          .then((res) => {
            if (res?.status === 0) {
              isApproved = true;
              toast.success("Approved Manager Successfully");
            } else {
              toast.error("Approve Manager Transaction Failed!");
              console.error("manager approval failed");
            }
          })
          .catch((err) => {
            toast.error("Approve Transaction Failed!");
            console.error("manager approval failed with error: ", err);
          });
      }
    }

    if (
      orderType === "sell" &&
      Number(tokenInAllowance) < Number(amountInWei)
    ) {
      console.log("primary swap.............. proceed: ");
      await tokenInContract
        .approve(balancerVaultAddress, amountInWei.toString())
        .then((res) => {
          if (res?.status === 0) {
            toast.success("Approved Vault Successfully");
            isApproved = true;
          } else {
            toast.error("Approve Transaction Failed!");
            console.error("vault approval failed");
          }
        })
        .catch((err) => {
          toast.error("Approve Transaction Failed!");
          console.error("vault approval failed with error: ", err);
        });
    } else {
      isApproved = true;
    }

    if (isApproved && userAddress && signer) {
      const balancerVaultContract = new Pool(signer);
      await balancerVaultContract
        .batchSwap(
          orderType === "sell" ? "0" : "1",
          [
            {
              poolId: poolId,
              assetInIndex: tokenInIndex.toString(),
              assetOutIndex: tokenOutIndex.toString(),
              amount: amountInWei.toString(),
              userData: "0x",
            },
          ],
          tokensList,
          {
            fromInternalBalance: false,
            recipient: userAddress,
            sender: userAddress,
            toInternalBalance: false,
          },
          limitArr,
          "999999999999999999" // Infinity
        )
        .then(async (res) => {
          if (res?.status === 0) {
            toast.success("Order Created Successfully");
            console.log(
              "successful batch swap with hash: ",
              res?.response?.hash
            );
            // if (
            //   Number(tokenOutBalance) <= 0 &&
            //   typeof window.ethereum !== "undefined"
            // ) {
            //   if (tokenOutSymbol) {
            //     console.log("token out: ", tokenOutSymbol);
            //     await window.ethereum
            //       .request({
            //         method: "wallet_watchAsset",
            //         params: {
            //           type: "ERC20",
            //           options: {
            //             address: tokenOut,
            //             symbol: tokenOutSymbol.substring(0, 10),
            //             decimals: tokenOutDecimals,
            //           },
            //         },
            //       })
            //       .then((res) => {
            //         if (res) {
            //           toast.success(
            //             `${tokenOutSymbol} Token added to wallet successfully`
            //           );
            //           console.log("Token added to wallet successfully");
            //         } else {
            //           toast.error("Adding token To Wallet Failed.");
            //           console.error("Adding token To Wallet Failed");
            //         }
            //       })
            //       .catch((err) => {
            //         toast.error("Adding token to Wallet Failed.");
            //         console.error("Adding token To Wallet Failed: ", err);
            //       });
            //   }
            // }
            // if (tokenOutSymbol) {
            //   await window.ethereum
            //     .request({
            //       method: "wallet_watchAsset",
            //       params: {
            //         type: "ERC20",
            //         options: {
            //           address: tokenOut,
            //           symbol: tokenOutSymbol.substring(0, 10),
            //           decimals: tokenOutDecimals,
            //         },
            //       },
            //     })
            //     .then((res) => {
            //       if (res) {
            //         toast.success(
            //           `${tokenOutSymbol} Token added to wallet successfully`
            //         );
            //         console.log("Token added to wallet successfully");
            //       } else {
            //         toast.error("Adding token To Wallet Failed.");
            //         console.error("Adding token To Wallet Failed");
            //       }
            //     })
            //     .catch((err) => {
            //       toast.error("Adding token to Wallet Failed.");
            //       console.error("Adding token To Wallet Failed: ", err);
            //     });
            // }
          } else {
            toast.error("Order Transaction Failed");
            console.error("batchSwap failed");
          }
        })
        .catch((err) => {
          toast.error("Order Transaction Failed!");
          console.error("batchSwap failed with error: ", err);
        });
    }
  } catch (err) {
    toast.error("Order Transaction Failed!");
    console.error("batchSwap failed with error: ", err);
  }
};
