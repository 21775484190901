import PageSetup from "..";
import greenChart from "../../assests/page/price-chart-up.svg";
import redChart from "../../assests/page/price-chart-down.svg";
import priceArrowUp from "../../assests/page/price-arrow-up.svg";
import priceArrowDown from "../../assests/page/price-arrow-down.svg";
import productLogo from "../../assests/page/product-logo.svg";
import customProductLogo from "../../assests/page/custom-stock-icon.svg";
import {
  PrivateStockLabel,
  VCFundsLabel,
} from "../../components/overlays/labels";
import AutoSlider from "../../components/overlays/autoSlider";
import articleImage from "../../assests/overlay/loan-calculator.svg";
import { PoolType, productTestContent } from "../../utils/constants";
import AssetInfoPage from "../../components/popups/assetInfo";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import { capitalizeFirstLetter } from "../../utils/helpers";
import _ from "lodash";
import { useSwipeable } from "react-swipeable";

const AssetsPage = (props) => {
  const [productClicked, setProductClicked] = useState({});
  const [clickedPoolCategory, setClickedPoolCategory] = useState("all");
  const [assetListPerPage, setAssetListPerPage] = useState(0);
  const [newIssuesActiveSlider, setNewIssuesActiveSlider] = useState({
    currentSlider: 0,
    leastContentIndex: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const updateAssetListPerPage = () => {
      const generalPage = document.getElementById("general-page-main-content");
      if (generalPage) {
        setAssetListPerPage(
          //220px + 12px gap
          String(Number(generalPage.clientWidth) / 232).includes(".")
            ? Number(
                String(Number(generalPage.clientWidth) / 232).split(".")[0]
              ) + 1
            : Number(generalPage.clientWidth) / 232
        );
      }
    };
    updateAssetListPerPage();

    window.addEventListener("resize", updateAssetListPerPage);

    return () => {
      window.removeEventListener("resize", updateAssetListPerPage);
    };
  }, []);

  const {
    getAllPoolsData,
    getAllPoolsChart,
    getAllPoolsLoading,
    getAllPoolsError,
    generalRefresh,
    setGeneralRefresh,
    setProductsToSearch,
    paraswapSupportedTokens,
  } = props.generalDataProps;

  const allPoolsDataCopy = [...getAllPoolsData];

  if (setProductsToSearch) {
    setProductsToSearch(getAllPoolsData);
  }

  const useGetSwipeHandler = (
    sliderIndexState,
    setSliderIndexState,
    sliderMaximum,
    contentMaximumLength
  ) => {
    return useSwipeable({
      onSwiped: (ev) => {
        if (ev.dir === "Left") {
          if (sliderIndexState.currentSlider < sliderMaximum - 1) {
            setSliderIndexState({
              currentSlider: sliderIndexState.currentSlider + 1,
              leastContentIndex: sliderIndexState.leastContentIndex + 1,
            });
          } else if (
            sliderIndexState.leastContentIndex < contentMaximumLength
          ) {
            setSliderIndexState({
              currentSlider: sliderIndexState.currentSlider,
              leastContentIndex: sliderIndexState.leastContentIndex + 1,
            });
          }
        }
        if (ev.dir === "Right") {
          if (sliderIndexState.currentSlider > 0) {
            setSliderIndexState({
              currentSlider: sliderIndexState.currentSlider - 1,
              leastContentIndex: sliderIndexState.leastContentIndex - 1,
            });
          }
        }
      },
    });
  };

  // console.log(
  //   "active: ",
  //   assetListPerPage,
  //   Number(
  //     String(
  //       getAllPoolsData.filter(
  //         (pool) =>
  //           !pool?.securityData?.subscriptionsClosed?.length &&
  //           pool?.poolType === PoolType.primaryPool
  //       ).length / assetListPerPage
  //     ).split(".")[0]
  //   )
  // );

  const newIssuesSliderHandler = useGetSwipeHandler(
    newIssuesActiveSlider,
    setNewIssuesActiveSlider,
    assetListPerPage > 0
      ? String(
          getAllPoolsData.filter(
            (pool) =>
              !pool?.securityData?.subscriptionsClosed?.length &&
              pool?.poolType === PoolType.primaryPool
          ).length / assetListPerPage
        ).includes(".")
        ? Number(
            String(
              getAllPoolsData.filter(
                (pool) =>
                  !pool?.securityData?.subscriptionsClosed?.length &&
                  pool?.poolType === PoolType.primaryPool
              ).length / assetListPerPage
            ).split(".")[0]
          ) + 1
        : getAllPoolsData.filter(
            (pool) =>
              !pool?.securityData?.subscriptionsClosed?.length &&
              pool?.poolType === PoolType.primaryPool
          ).length / assetListPerPage
      : 1,
    getAllPoolsData.filter(
      (pool) =>
        !pool?.securityData?.subscriptionsClosed?.length &&
        pool?.poolType === PoolType.primaryPool
    ).length
  );

  return (
    <PageSetup
      showPageSeparator={false}
      separatorText="Create Secondary Market"
      separatorUrl="/investments/assets/create-secondary"
      backUrl="/investments/assets"
    >
      {/* first child(overlay) */}
      <>
        {/* <div className="overlay-content">
          <p className="overlay-content-text">
            Please add cosigner for your transactions to complete.
          </p>
        </div>
        <button className="overlay-button">
          <p className="overlay-button-text">Add</p>
        </button> */}
      </>
      {/* second child(first section body) */}
      <>
        {/* <div className="first-section-text-container">
          <p className="first-section-text">
            Setup your Verified Wallet Account
          </p>
        </div>
        <div className="first-section-body">
          <AssestOverlay
            headerTextImg={activateWalletImg}
            fotterText="Add funds for fast, charge-free transfers in all currencies."
            fotterTextSize="182"
            buttonText="Add Funds"
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
          />
          <AssestOverlay
            headerTextImg={getDebitCardImg}
            fotterText="Tap and pay directly from your wallet!"
            fotterTextSize="142"
            buttonText="Apply Now"
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
          />
          <AssestOverlay
            headerTextImg={linkBankImg}
            fotterText="Link bank accounts for easy wallet transfers."
            fotterTextSize="156"
            buttonText="Apply Now"
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
          />
        </div> */}
      </>

      {/* Third child(issues list) */}
      <>
        {getAllPoolsData.find(
          (pool) =>
            !pool?.securityData?.subscriptionsClosed?.length &&
            pool?.poolType === PoolType.primaryPool
        ) && (
          <>
            <div className="general-page-third-content-header">
              <p className="gp-third-content-text">New Issues</p>
              <button
                onClick={() => {
                  navigate("/investments/assets/new-issue");
                }}
                className="gp-main-content-header-button"
              >
                <p className="gp-main-content-header-button-text">View All</p>
              </button>
            </div>
            <div
              {...newIssuesSliderHandler}
              className="general-page-third-content-bottom"
            >
              <div className="gp-third-content-bottom-content-container">
                {getAllPoolsLoading && getAllPoolsData.length === 0 && (
                  <>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                  </>
                )}
                {getAllPoolsLoading && getAllPoolsData.length > 0 && (
                  <div className="gp-third-content-bottom-content-loading"></div>
                )}
                {getAllPoolsData
                  .filter(
                    (pool) =>
                      !pool?.securityData?.subscriptionsClosed?.length &&
                      pool?.poolType === PoolType.primaryPool
                  )
                  .map((pool, idx) => (
                    <>
                      {/* add 1 extra asset to show scroll behaviour */}
                      {idx <=
                        newIssuesActiveSlider.leastContentIndex +
                          (assetListPerPage + 1) && (
                        <div
                          id={idx}
                          onClickCapture={() => {
                            setProductClicked(pool);
                          }}
                          onClick={() => {
                            props.setPopupContentPosition("flex-end");
                            props.setPopupContent(
                              <AssetInfoPage
                                setShowPopup={props.setShowPopup}
                                setPopupContent={props.setPopupContent}
                                setPopupContentPosition={
                                  props.setPopupContentPosition
                                }
                                setFavouriteClicked={props.setFavouriteClicked}
                                productClicked={productClicked}
                                customLoading={props.customLoading}
                                setCustomLoading={props.setCustomLoading}
                                paraswapSupportedTokens={
                                  paraswapSupportedTokens
                                }
                                expandedView={props.expandedView}
                                setExpandedView={props.setExpandedView}
                              />
                            );
                            props.setFavouriteClicked(true);
                            props.setShowPopup(true);
                          }}
                          className="gp-third-content-bottom-content"
                        >
                          <div className="gp-third-content-bottom-first-section">
                            <div className="gp-third-content-bottom-first-section-image">
                              {pool.logo && (
                                <img alt="Product Logo" src={pool.logo} />
                              )}
                              {!pool.logo && (
                                <Avatar name={pool.securitySymbol} />
                              )}
                            </div>
                            <div className="gp-third-content-bottom-first-section-text-container">
                              {pool.securityCategory && (
                                <>
                                  {pool.securityCategory.toLowerCase() ===
                                    "stock" && (
                                    <PrivateStockLabel
                                      customText={"Equities"}
                                    />
                                  )}
                                  {pool.securityCategory.toLowerCase() ===
                                    "bond" && (
                                    <PrivateStockLabel customText={"Credit"} />
                                  )}
                                  {pool.securityCategory.toLowerCase() ===
                                    "cfd" && (
                                    <PrivateStockLabel
                                      customText={"Structured Products"}
                                    />
                                  )}
                                  {pool.securityCategory.toLowerCase() ===
                                    "fund" && <VCFundsLabel />}
                                </>
                              )}
                              <p className="gp-third-content-bottom-first-section-text">
                                {pool.securityName.length <= 15
                                  ? pool.securityName
                                  : pool.securityName.substring(0, 12) + "..."}
                              </p>
                            </div>
                          </div>
                          <div className="gp-third-content-line-separator"></div>
                          <div className="gp-third-content-bottom-second-section">
                            <div className="gp-third-content-bottom-second-section-header">
                              <p className="gp-third-content-bottom-second-section-header-text">
                                Min Qty:
                              </p>
                              <p className="gp-third-content-bottom-second-section-footer-text">
                                {Number(pool.minOrderSize).toFixed(2)}
                              </p>
                            </div>
                            <div className="gp-third-content-bottom-second-section-header">
                              {pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-header-text">
                                  Closing Date:
                                </p>
                              )}
                              {!pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-header-text">
                                  Creation Date:
                                </p>
                              )}
                              {pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-footer-text">
                                  {pool.cutoffTimeFmt}
                                </p>
                              )}
                              {!pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-footer-text">
                                  {pool.createTimeFmt}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
            <div className="gp-dot-slider-container">
              {Array.from(
                {
                  length:
                    assetListPerPage > 0
                      ? String(
                          getAllPoolsData.filter(
                            (pool) =>
                              !pool?.securityData?.subscriptionsClosed
                                ?.length &&
                              pool?.poolType === PoolType.primaryPool
                          ).length / assetListPerPage
                        ).includes(".")
                        ? Number(
                            String(
                              getAllPoolsData.filter(
                                (pool) =>
                                  !pool?.securityData?.subscriptionsClosed
                                    ?.length &&
                                  pool?.poolType === PoolType.primaryPool
                              ).length / assetListPerPage
                            ).split(".")[0]
                          ) + 1
                        : getAllPoolsData.filter(
                            (pool) =>
                              !pool?.securityData?.subscriptionsClosed
                                ?.length &&
                              pool?.poolType === PoolType.primaryPool
                          ).length / assetListPerPage
                      : 1,
                },
                (_, i) => i + 1
              ).map((_, idx) => (
                <>
                  {idx === newIssuesActiveSlider.currentSlider && (
                    <div className="gp-dot-slider-active"></div>
                  )}
                  {idx !== newIssuesActiveSlider.currentSlider && (
                    <div className="gp-dot-slider"></div>
                  )}
                </>
              ))}
            </div>
          </>
        )}

        <div className="general-page-main-content-header">
          <p className="gp-main-content-header-text">Investment Categories</p>
          <button
            onClick={() => {
              navigate("/investments/assets/new-issue");
            }}
            className="gp-main-content-header-button"
          >
            <p className="gp-main-content-header-button-text">View All</p>
          </button>
        </div>
        <div className="general-page-main-content-footer">
          <div className="gp-main-content-footer-buttons">
            <div className="gp-main-content-footer-buttons-container">
              <div className="gp-main-content-footer-buttons-group">
                {clickedPoolCategory !== "all" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("all");
                    }}
                    className="gp-main-content-footer-button"
                  >
                    <p className="gp-main-content-footer-button-text">All</p>
                  </button>
                )}
                {clickedPoolCategory === "all" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("all");
                    }}
                    className="gp-main-content-footer-button-active"
                  >
                    <p className="gp-main-content-footer-button-text-active">
                      All
                    </p>
                  </button>
                )}
                {clickedPoolCategory !== "stock" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("stock");
                    }}
                    className="gp-main-content-footer-button"
                  >
                    <p className="gp-main-content-footer-button-text">
                      Equities
                    </p>
                  </button>
                )}
                {clickedPoolCategory === "stock" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("stock");
                    }}
                    className="gp-main-content-footer-button-active"
                  >
                    <p className="gp-main-content-footer-button-text-active">
                      Equities
                    </p>
                  </button>
                )}

                {clickedPoolCategory !== "bond" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("bond");
                    }}
                    className="gp-main-content-footer-button"
                  >
                    <p className="gp-main-content-footer-button-text">Credit</p>
                  </button>
                )}
                {clickedPoolCategory === "bond" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("bond");
                    }}
                    className="gp-main-content-footer-button-active"
                  >
                    <p className="gp-main-content-footer-button-text-active">
                      Credit
                    </p>
                  </button>
                )}

                {clickedPoolCategory !== "fund" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("fund");
                    }}
                    className="gp-main-content-footer-button"
                  >
                    <p className="gp-main-content-footer-button-text">Funds</p>
                  </button>
                )}
                {clickedPoolCategory === "fund" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("fund");
                    }}
                    className="gp-main-content-footer-button-active"
                  >
                    <p className="gp-main-content-footer-button-text-active">
                      Funds
                    </p>
                  </button>
                )}

                {clickedPoolCategory !== "cfd" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("cfd");
                    }}
                    className="gp-main-content-footer-button"
                  >
                    <p className="gp-main-content-footer-button-text">
                      Structured Products
                    </p>
                  </button>
                )}
                {clickedPoolCategory === "cfd" && (
                  <button
                    onClick={() => {
                      setClickedPoolCategory("cfd");
                    }}
                    className="gp-main-content-footer-button-active"
                  >
                    <p className="gp-main-content-footer-button-text-active">
                      Structured Products
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="gp-main-content-footer-content-container">
            {/* Handle Loading page */}
            {getAllPoolsLoading && getAllPoolsData.length === 0 && (
              <div className="gp-main-content-footer-content-container-loading">
                <div className="gp-main-content-footer-content-container-loading-big"></div>
                <div className="gp-main-content-footer-content-container-loading-medium"></div>
                <div className="gp-main-content-footer-content-container-loading-medium"></div>
                <div className="gp-main-content-footer-content-container-loading-medium"></div>
                <div className="gp-main-content-footer-content-container-loading-medium"></div>
                <div className="gp-main-content-footer-content-container-loading-medium"></div>
                {/* <div className="gp-main-content-footer-content-container-loading-small"></div> */}
              </div>
            )}
            {getAllPoolsLoading && getAllPoolsData.length > 0 && (
              <div className="gp-main-content-footer-content-container-loading">
                <div className="gp-main-content-footer-content-container-loading-medium"></div>
              </div>
            )}

            {getAllPoolsData.map((pool, idx) => (
              <>
                {!pool?.securityData?.subscriptionsClosed?.length && (
                  <>
                    {clickedPoolCategory === "all" && (
                      <div
                        key={idx}
                        onClickCapture={() => {
                          setProductClicked(pool);
                        }}
                        onClick={() => {
                          props.setPopupContentPosition("flex-end");
                          props.setPopupContent(
                            <AssetInfoPage
                              setShowPopup={props.setShowPopup}
                              setPopupContent={props.setPopupContent}
                              setPopupContentPosition={
                                props.setPopupContentPosition
                              }
                              setFavouriteClicked={props.setFavouriteClicked}
                              productClicked={productClicked}
                              customLoading={props.customLoading}
                              setCustomLoading={props.setCustomLoading}
                              paraswapSupportedTokens={paraswapSupportedTokens}
                              expandedView={props.expandedView}
                              setExpandedView={props.setExpandedView}
                            />
                          );
                          props.setFavouriteClicked(true);
                          props.setShowPopup(true);
                        }}
                        className="gp-main-content-footer-content"
                      >
                        <div className="gp-main-content-footer-content-first-section">
                          <div className="gp-main-content-footer-content-title">
                            <p className="gp-main-content-footer-content-title-text">
                              {pool.securityName}
                            </p>
                          </div>
                          <div className="gp-main-content-footer-content-symbol">
                            {pool.securitySymbol}
                          </div>
                          <div className="new-issue-page-main-content-body-second">
                            <p className="new-issue-page-main-content-body-title">
                              Minimum Qty
                            </p>
                            <p className="new-issue-page-main-content-body-label">
                              {Number(pool.minOrderSize).toFixed(4)}
                            </p>
                          </div>
                          {pool.poolType === PoolType.primaryPool && (
                            <div className="new-issue-page-main-content-body-second">
                              <p className="new-issue-page-main-content-body-title">
                                Amount Offered
                              </p>
                              <p className="new-issue-page-main-content-body-label">
                                {pool.securityOffered}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="gp-main-content-footer-content-second-section">
                          {pool.poolChartImg && (
                            <img
                              className="gp-main-content-second-section-image"
                              alt="Price Chart"
                              src={pool.poolChartImg}
                            />
                          )}
                          <div className="gp-main-content-second-section-text-container">
                            <p
                              style={
                                Number(pool.currentPrice) <
                                Number(
                                  pool.pricesBeforeTodayFmt?.priceBeforeToday
                                )
                                  ? { color: "#BC1919" }
                                  : {}
                              }
                              className="gp-main-content-price-percentage"
                            >
                              {Number(pool.currentPrice) >=
                              Number(
                                pool.pricesBeforeTodayFmt?.priceBeforeToday
                              )
                                ? +(
                                    Number(pool.currentPrice) -
                                    Number(
                                      pool.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    )
                                  ).toFixed(2)
                                : -(
                                    Number(
                                      pool.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    ) - Number(pool.currentPrice)
                                  ).toFixed(2)}{" "}
                              ({" "}
                              {Number(pool.currentPrice) >=
                                Number(
                                  pool.pricesBeforeTodayFmt?.priceBeforeToday
                                ) &&
                              Number(pool.currentPrice) -
                                Number(
                                  pool.pricesBeforeTodayFmt?.priceBeforeToday
                                ) >
                                0
                                ? +(
                                    ((Number(pool.currentPrice) -
                                      Number(
                                        pool.pricesBeforeTodayFmt
                                          ?.priceBeforeToday
                                      )) *
                                      100) /
                                    Number(pool.currentPrice)
                                  ).toFixed(2)
                                : Number(pool.currentPrice) -
                                    Number(
                                      pool.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    ) ===
                                  0
                                ? +0
                                : -(
                                    ((Number(
                                      pool.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    ) -
                                      Number(pool.currentPrice)) *
                                      100) /
                                    Number(
                                      pool.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    )
                                  ).toFixed(2)}{" "}
                              % )
                            </p>
                            <div className="gp-main-content-price-container">
                              {Number(pool.currentPrice) >=
                                Number(
                                  pool.pricesBeforeTodayFmt?.priceBeforeToday
                                ) && (
                                <p className="gp-main-content-price-up">
                                  {pool.currencyFiatSymbol}{" "}
                                  {Number(pool.currentPrice).toFixed(4)}
                                </p>
                              )}
                              {Number(pool.currentPrice) <
                                Number(
                                  pool.pricesBeforeTodayFmt?.priceBeforeToday
                                ) && (
                                <p className="gp-main-content-price-down">
                                  {pool.currencyFiatSymbol}{" "}
                                  {Number(pool.currentPrice).toFixed(4)}
                                </p>
                              )}
                              <img
                                className="gp-main-content-price-icon"
                                alt="Price Arrow-Up"
                                src={
                                  Number(pool.currentPrice) >=
                                  Number(
                                    pool.pricesBeforeTodayFmt?.priceBeforeToday
                                  )
                                    ? priceArrowUp
                                    : priceArrowDown
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {clickedPoolCategory !== "all" &&
                      pool.securityCategory
                        .toLowerCase()
                        .includes(clickedPoolCategory) && (
                        <div
                          key={idx}
                          onClickCapture={() => {
                            setProductClicked(pool);
                          }}
                          onClick={() => {
                            props.setPopupContentPosition("flex-end");
                            props.setPopupContent(
                              <AssetInfoPage
                                setShowPopup={props.setShowPopup}
                                setPopupContent={props.setPopupContent}
                                setPopupContentPosition={
                                  props.setPopupContentPosition
                                }
                                setFavouriteClicked={props.setFavouriteClicked}
                                productClicked={productClicked}
                                customLoading={props.customLoading}
                                setCustomLoading={props.setCustomLoading}
                                paraswapSupportedTokens={
                                  paraswapSupportedTokens
                                }
                                expandedView={props.expandedView}
                                setExpandedView={props.setExpandedView}
                              />
                            );
                            props.setFavouriteClicked(true);
                            props.setShowPopup(true);
                          }}
                          className="gp-main-content-footer-content"
                        >
                          <div className="gp-main-content-footer-content-first-section">
                            <div className="gp-main-content-footer-content-title">
                              <p className="gp-main-content-footer-content-title-text">
                                {pool.securityName}
                              </p>
                            </div>
                            <div className="gp-main-content-footer-content-symbol">
                              {pool.securitySymbol}
                            </div>
                            <div className="new-issue-page-main-content-body-second">
                              <p className="new-issue-page-main-content-body-title">
                                Minimum Qty
                              </p>
                              <p className="new-issue-page-main-content-body-label">
                                {Number(pool.minOrderSize).toFixed(4)}
                              </p>
                            </div>
                            {pool.poolType === PoolType.primaryPool && (
                              <div className="new-issue-page-main-content-body-second">
                                <p className="new-issue-page-main-content-body-title">
                                  Amount Offered
                                </p>
                                <p className="new-issue-page-main-content-body-label">
                                  {pool.securityOffered}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="gp-main-content-footer-content-second-section">
                            {pool.poolChartImg && (
                              <img
                                className="gp-main-content-second-section-image"
                                alt="Price Chart"
                                src={pool.poolChartImg}
                              />
                            )}
                            <div className="gp-main-content-second-section-text-container">
                              <p
                                style={
                                  Number(pool.currentPrice) <
                                  Number(
                                    pool.pricesBeforeTodayFmt?.priceBeforeToday
                                  )
                                    ? { color: "#BC1919" }
                                    : {}
                                }
                                className="gp-main-content-price-percentage"
                              >
                                {Number(pool.currentPrice) >=
                                Number(
                                  pool.pricesBeforeTodayFmt?.priceBeforeToday
                                )
                                  ? +(
                                      Number(pool.currentPrice) -
                                      Number(
                                        pool.pricesBeforeTodayFmt
                                          ?.priceBeforeToday
                                      )
                                    ).toFixed(2)
                                  : -(
                                      Number(
                                        pool.pricesBeforeTodayFmt
                                          ?.priceBeforeToday
                                      ) - Number(pool.currentPrice)
                                    ).toFixed(2)}{" "}
                                ({" "}
                                {Number(pool.currentPrice) >=
                                  Number(
                                    pool.pricesBeforeTodayFmt?.priceBeforeToday
                                  ) &&
                                Number(pool.currentPrice) -
                                  Number(
                                    pool.pricesBeforeTodayFmt?.priceBeforeToday
                                  ) >
                                  0
                                  ? +(
                                      ((Number(pool.currentPrice) -
                                        Number(
                                          pool.pricesBeforeTodayFmt
                                            ?.priceBeforeToday
                                        )) *
                                        100) /
                                      Number(pool.currentPrice)
                                    ).toFixed(2)
                                  : Number(pool.currentPrice) -
                                      Number(
                                        pool.pricesBeforeTodayFmt
                                          ?.priceBeforeToday
                                      ) ===
                                    0
                                  ? +0
                                  : -(
                                      ((Number(
                                        pool.pricesBeforeTodayFmt
                                          ?.priceBeforeToday
                                      ) -
                                        Number(pool.currentPrice)) *
                                        100) /
                                      Number(
                                        pool.pricesBeforeTodayFmt
                                          ?.priceBeforeToday
                                      )
                                    ).toFixed(2)}{" "}
                                % )
                              </p>
                              <div className="gp-main-content-price-container">
                                {Number(pool.currentPrice) >=
                                  Number(
                                    pool.pricesBeforeTodayFmt?.priceBeforeToday
                                  ) && (
                                  <p className="gp-main-content-price-up">
                                    {pool.currencyFiatSymbol}{" "}
                                    {Number(pool.currentPrice).toFixed(4)}
                                  </p>
                                )}
                                {Number(pool.currentPrice) <
                                  Number(
                                    pool.pricesBeforeTodayFmt?.priceBeforeToday
                                  ) && (
                                  <p className="gp-main-content-price-down">
                                    {pool.currencyFiatSymbol}{" "}
                                    {Number(pool.currentPrice).toFixed(4)}
                                  </p>
                                )}
                                <img
                                  className="gp-main-content-price-icon"
                                  alt="Price Arrow-Up"
                                  src={
                                    Number(pool.currentPrice) >=
                                    Number(
                                      pool.pricesBeforeTodayFmt
                                        ?.priceBeforeToday
                                    )
                                      ? priceArrowUp
                                      : priceArrowDown
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </>
            ))}
          </div>
        </div>
      </>
      {/* Fourth child(margin issues) */}
      <></>
      {/* Fifth child(most bought) */}
      <>
        {getAllPoolsData.find(
          (pool) =>
            !pool?.securityData?.subscriptionsClosed?.length &&
            pool?.poolType === PoolType.secondaryPool
        ) && (
          <>
            <div className="general-page-fourth-content-header">
              <p className="gp-main-content-header-text">Secondaries</p>
              <div className="gp-main-content-header-button-custom">
                <p className="gp-main-content-header-button-text">View All</p>
              </div>
            </div>
            <div className="general-page-third-content-bottom">
              <div className="gp-third-content-bottom-content-container">
                {getAllPoolsLoading && getAllPoolsData.length === 0 && (
                  <>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                  </>
                )}
                {getAllPoolsLoading && getAllPoolsData.length > 0 && (
                  <div className="gp-third-content-bottom-content-loading"></div>
                )}
                {getAllPoolsData.map((pool, idx) => (
                  <>
                    {!pool?.securityData?.subscriptionsClosed?.length && (
                      <>
                        {pool.poolType === PoolType.secondaryPool && (
                          <div
                            id={idx}
                            onClickCapture={() => {
                              setProductClicked(pool);
                            }}
                            onClick={() => {
                              props.setPopupContentPosition("flex-end");
                              props.setPopupContent(
                                <AssetInfoPage
                                  setShowPopup={props.setShowPopup}
                                  setPopupContent={props.setPopupContent}
                                  setPopupContentPosition={
                                    props.setPopupContentPosition
                                  }
                                  setFavouriteClicked={
                                    props.setFavouriteClicked
                                  }
                                  productClicked={productClicked}
                                  customLoading={props.customLoading}
                                  setCustomLoading={props.setCustomLoading}
                                  paraswapSupportedTokens={
                                    paraswapSupportedTokens
                                  }
                                  expandedView={props.expandedView}
                                  setExpandedView={props.setExpandedView}
                                />
                              );
                              props.setFavouriteClicked(true);
                              props.setShowPopup(true);
                            }}
                            className="gp-third-content-bottom-content"
                          >
                            <div className="gp-third-content-bottom-first-section">
                              <div className="gp-third-content-bottom-first-section-image">
                                {pool.logo && (
                                  <img alt="Product Logo" src={pool.logo} />
                                )}
                                {!pool.logo && (
                                  <Avatar name={pool.securitySymbol} />
                                )}
                              </div>
                              <div className="gp-third-content-bottom-first-section-text-container">
                                {pool.securityCategory && (
                                  <>
                                    {pool.securityCategory.toLowerCase() ===
                                      "stock" && (
                                      <PrivateStockLabel
                                        customText={"Equities"}
                                      />
                                    )}
                                    {pool.securityCategory.toLowerCase() ===
                                      "bond" && (
                                      <PrivateStockLabel
                                        customText={"Credit"}
                                      />
                                    )}
                                    {pool.securityCategory.toLowerCase() ===
                                      "cfd" && (
                                      <PrivateStockLabel
                                        customText={"Structured Products"}
                                      />
                                    )}
                                    {pool.securityCategory.toLowerCase() ===
                                      "fund" && <VCFundsLabel />}
                                  </>
                                )}
                                <p className="gp-third-content-bottom-first-section-text">
                                  {pool.securityName.length <= 15
                                    ? pool.securityName
                                    : pool.securityName.substring(0, 12) +
                                      "..."}
                                </p>
                              </div>
                            </div>
                            <div className="gp-third-content-line-separator"></div>
                            <div className="gp-third-content-bottom-second-section">
                              <div className="gp-third-content-bottom-second-section-header">
                                <p className="gp-third-content-bottom-second-section-header-text">
                                  Min Qty:
                                </p>
                                <p className="gp-third-content-bottom-second-section-footer-text">
                                  {Number(pool.minOrderSize).toFixed(2)}
                                </p>
                              </div>
                              <div className="gp-third-content-bottom-second-section-header">
                                {pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-header-text">
                                    Closing Date:
                                  </p>
                                )}
                                {!pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-header-text">
                                    Creation Date:
                                  </p>
                                )}
                                {pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-footer-text">
                                    {pool.cutoffTimeFmt}
                                  </p>
                                )}
                                {!pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-footer-text">
                                    {pool.createTimeFmt}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
              </div>
            </div>
          </>
        )}

        {getAllPoolsData.find(
          (pool) =>
            !pool?.securityData?.subscriptionsClosed?.length &&
            pool?.poolType === PoolType.marginPool
        ) && (
          <>
            <div className="general-page-fourth-content-header">
              <p className="gp-main-content-header-text">
                Margin Traded Products
              </p>
              <div className="gp-main-content-header-button-custom">
                <p className="gp-main-content-header-button-text">View All</p>
              </div>
            </div>
            <div className="general-page-third-content-bottom">
              <div className="gp-third-content-bottom-content-container">
                {getAllPoolsLoading && getAllPoolsData.length === 0 && (
                  <>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                  </>
                )}
                {getAllPoolsLoading && getAllPoolsData.length > 0 && (
                  <div className="gp-third-content-bottom-content-loading"></div>
                )}
                {getAllPoolsData.map((pool, idx) => (
                  <>
                    {!pool?.securityData?.subscriptionsClosed?.length && (
                      <>
                        {pool.poolType === PoolType.marginPool && (
                          <div
                            id={idx}
                            onClickCapture={() => {
                              setProductClicked(pool);
                            }}
                            onClick={() => {
                              props.setPopupContentPosition("flex-end");
                              props.setPopupContent(
                                <AssetInfoPage
                                  setShowPopup={props.setShowPopup}
                                  setPopupContent={props.setPopupContent}
                                  setPopupContentPosition={
                                    props.setPopupContentPosition
                                  }
                                  setFavouriteClicked={
                                    props.setFavouriteClicked
                                  }
                                  productClicked={productClicked}
                                  customLoading={props.customLoading}
                                  setCustomLoading={props.setCustomLoading}
                                  paraswapSupportedTokens={
                                    paraswapSupportedTokens
                                  }
                                  expandedView={props.expandedView}
                                  setExpandedView={props.setExpandedView}
                                />
                              );
                              props.setFavouriteClicked(true);
                              props.setShowPopup(true);
                            }}
                            className="gp-third-content-bottom-content"
                          >
                            <div className="gp-third-content-bottom-first-section">
                              <div className="gp-third-content-bottom-first-section-image">
                                {pool.logo && (
                                  <img alt="Product Logo" src={pool.logo} />
                                )}
                                {!pool.logo && (
                                  <Avatar name={pool.securitySymbol} />
                                )}
                              </div>
                              <div className="gp-third-content-bottom-first-section-text-container">
                                {pool.securityCategory && (
                                  <>
                                    {pool.securityCategory.toLowerCase() ===
                                      "stock" && (
                                      <PrivateStockLabel
                                        customText={"Equities"}
                                      />
                                    )}
                                    {pool.securityCategory.toLowerCase() ===
                                      "bond" && (
                                      <PrivateStockLabel
                                        customText={"Credit"}
                                      />
                                    )}
                                    {pool.securityCategory.toLowerCase() ===
                                      "cfd" && (
                                      <PrivateStockLabel
                                        customText={"Structured Products"}
                                      />
                                    )}
                                    {pool.securityCategory.toLowerCase() ===
                                      "fund" && <VCFundsLabel />}
                                  </>
                                )}
                                <p className="gp-third-content-bottom-first-section-text">
                                  {pool.securityName.length <= 15
                                    ? pool.securityName
                                    : pool.securityName.substring(0, 12) +
                                      "..."}
                                </p>
                              </div>
                            </div>
                            <div className="gp-third-content-line-separator"></div>
                            <div className="gp-third-content-bottom-second-section">
                              <div className="gp-third-content-bottom-second-section-header">
                                <p className="gp-third-content-bottom-second-section-header-text">
                                  Min Qty:
                                </p>
                                <p className="gp-third-content-bottom-second-section-footer-text">
                                  {Number(pool.minOrderSize).toFixed(2)}
                                </p>
                              </div>
                              <div className="gp-third-content-bottom-second-section-header">
                                {pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-header-text">
                                    Closing Date:
                                  </p>
                                )}
                                {!pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-header-text">
                                    Creation Date:
                                  </p>
                                )}
                                {pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-footer-text">
                                    {pool.cutoffTimeFmt}
                                  </p>
                                )}
                                {!pool.cutoffTime && (
                                  <p className="gp-third-content-bottom-second-section-footer-text">
                                    {pool.createTimeFmt}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
              </div>
            </div>
          </>
        )}

        {getAllPoolsData.find(
          (pool) => !pool?.securityData?.subscriptionsClosed?.length
        ) && (
          <>
            <div className="general-page-fourth-content-header">
              <p className="gp-main-content-header-text">
                Most Bought in the Last 30 days
              </p>
              <div className="gp-main-content-header-button-custom">
                <p className="gp-main-content-header-button-text">View All</p>
              </div>
            </div>
            <div className="general-page-third-content-bottom">
              <div className="gp-third-content-bottom-content-container">
                {getAllPoolsLoading && getAllPoolsData.length === 0 && (
                  <>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                    <div className="gp-third-content-bottom-content-loading"></div>
                  </>
                )}
                {getAllPoolsLoading && getAllPoolsData.length > 0 && (
                  <div className="gp-third-content-bottom-content-loading"></div>
                )}
                {allPoolsDataCopy
                  .sort((a, b) => Number(b.totalBought) - Number(a.totalBought))
                  .map((pool, idx) => (
                    <>
                      {!pool?.securityData?.subscriptionsClosed?.length && (
                        <div
                          id={idx}
                          onClickCapture={() => {
                            setProductClicked(pool);
                          }}
                          onClick={() => {
                            props.setPopupContentPosition("flex-end");
                            props.setPopupContent(
                              <AssetInfoPage
                                setShowPopup={props.setShowPopup}
                                setPopupContent={props.setPopupContent}
                                setPopupContentPosition={
                                  props.setPopupContentPosition
                                }
                                setFavouriteClicked={props.setFavouriteClicked}
                                productClicked={productClicked}
                                customLoading={props.customLoading}
                                setCustomLoading={props.setCustomLoading}
                                paraswapSupportedTokens={
                                  paraswapSupportedTokens
                                }
                                expandedView={props.expandedView}
                                setExpandedView={props.setExpandedView}
                              />
                            );
                            props.setFavouriteClicked(true);
                            props.setShowPopup(true);
                          }}
                          className="gp-third-content-bottom-content"
                        >
                          <div className="gp-third-content-bottom-first-section">
                            <div className="gp-third-content-bottom-first-section-image">
                              {pool.logo && (
                                <img alt="Product Logo" src={pool.logo} />
                              )}
                              {!pool.logo && (
                                <Avatar name={pool.securitySymbol} />
                              )}
                            </div>
                            <div className="gp-third-content-bottom-first-section-text-container">
                              {pool.securityCategory && (
                                <>
                                  {pool.securityCategory.toLowerCase() ===
                                    "stock" && (
                                    <PrivateStockLabel
                                      customText={"Equities"}
                                    />
                                  )}
                                  {pool.securityCategory.toLowerCase() ===
                                    "bond" && (
                                    <PrivateStockLabel customText={"Credit"} />
                                  )}
                                  {pool.securityCategory.toLowerCase() ===
                                    "cfd" && (
                                    <PrivateStockLabel
                                      customText={"Structured Products"}
                                    />
                                  )}
                                  {pool.securityCategory.toLowerCase() ===
                                    "fund" && <VCFundsLabel />}
                                </>
                              )}
                              <p className="gp-third-content-bottom-first-section-text">
                                {pool.securityName.length <= 15
                                  ? pool.securityName
                                  : pool.securityName.substring(0, 12) + "..."}
                              </p>
                            </div>
                          </div>
                          <div className="gp-third-content-line-separator"></div>
                          <div className="gp-third-content-bottom-second-section">
                            <div className="gp-third-content-bottom-second-section-header">
                              <p className="gp-third-content-bottom-second-section-header-text">
                                Min Qty:
                              </p>
                              <p className="gp-third-content-bottom-second-section-footer-text">
                                {Number(pool.minOrderSize).toFixed(2)}
                              </p>
                            </div>
                            <div className="gp-third-content-bottom-second-section-header">
                              {pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-header-text">
                                  Closing Date:
                                </p>
                              )}
                              {!pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-header-text">
                                  Creation Date:
                                </p>
                              )}
                              {pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-footer-text">
                                  {pool.cutoffTimeFmt}
                                </p>
                              )}
                              {!pool.cutoffTime && (
                                <p className="gp-third-content-bottom-second-section-footer-text">
                                  {pool.createTimeFmt}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </>
        )}
      </>
      {/* Sixth child(News and Learning) */}
      <>
        {/* <div className="general-page-fourth-content-header">
          <p className="gp-main-content-header-text">News and Learnings</p>
          <div className="gp-main-content-header-button-custom">
            <p className="gp-main-content-header-button-text">View All</p>
          </div>
        </div>
        <div className="gp-news-content-container">
          <div className="gp-news-content-group">
            <div className="gp-news-content">
              <img
                className="gp-news-content-image"
                alt="Article Icon"
                src={articleImage}
              />
              <div className="gp-news-content-text-container">
                <div className="gp-news-content-text-group">
                  <p className="gp-news-content-text-header">
                    Header in 1 Line as per content
                  </p>
                  <p className="gp-news-content-text-footer">
                    Description Text that talks about the corporate action and
                    covers 2 lines.
                  </p>
                </div>
                <button className="assets-overlay-button">
                  <p className="overlay-button-text">Read Article</p>
                </button>
              </div>
            </div>

            <div className="gp-news-content-custom">
              <img
                className="gp-news-content-image"
                alt="Article Icon"
                src={articleImage}
              />
              <div className="gp-news-content-text-container">
                <div className="gp-news-content-text-group">
                  <p className="gp-news-content-text-header">
                    Header in 1 Line as per content
                  </p>
                  <p className="gp-news-content-text-footer">
                    Description Text that talks about the corporate action and
                    covers 2 lines.
                  </p>
                </div>
                <button className="assets-overlay-button">
                  <p className="overlay-button-text">Read Article</p>
                </button>
              </div>
            </div>
          </div>
          <AutoSlider />
        </div> */}
      </>
    </PageSetup>
  );
};

export default AssetsPage;
