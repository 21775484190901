import LayoutContent from "./content";
import SiderBar from "../sideBar";
import { useState } from "react";

const LayoutBody = (props) => {
  const [fullSidebar, setFullSidebar] = useState(true);

  return (
    <div className="layout-body">
      <SiderBar
        fullSidebar={fullSidebar}
        setFullSidebar={setFullSidebar}
        setShowPopup={props.setShowPopup}
        setPopupContent={props.setPopupContent}
        setPopupContentPosition={props.setPopupContentPosition}
        setFavouriteClicked={props.setFavouriteClicked}
        expandedView={props.expandedView}
      />
      <LayoutContent
        fullSidebar={fullSidebar}
        setFullSidebar={setFullSidebar}
        children={props.children}
        setShowPopup={props.setShowPopup}
        setPopupContent={props.setPopupContent}
        setPopupContentPosition={props.setPopupContentPosition}
        favouriteClicked={props.favouriteClicked}
        setFavouriteClicked={props.setFavouriteClicked}
        productsToSearch={props.productsToSearch}
        generalDataProps={props.generalDataProps}
      />
    </div>
  );
};

export default LayoutBody;
