import searchIcon from "../../assests/sideBar/search-icon.svg";
import widgetIcon from "../../assests/sideBar/instant-mix-icon.svg";
import widgetIconWithDot from "../../assests/sideBar/widget-with-dot.svg";
import favoriteIcon from "../../assests/overlay/Star.svg";
import favoriteClickedIcon from "../../assests/overlay/star-clicked.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  pagesWithGeneralSearch,
  productSearchResult,
} from "../../utils/constants";
import WhitelistPopup from "../../components/popups/whiteList";
import SearchFilterPopup from "../../components/popups/searchFilter";
import AssetInfoPage from "../../components/popups/assetInfo";

const LayoutContent = (props) => {
  const [newSearchContent, setNewSearchContent] = useState(null);
  const [showSearchedResult, setShowSearchedResult] = useState(false);
  const [productClicked, setProductClicked] = useState({
    name: "",
    description: "",
    label: "",
  });
  const [ShowFilterDot, setShowFilterDot] = useState(false);
  const [allFilters, setAllFilters] = useState({});
  const [newProductToSearch, setNewProductToSearch] = useState(null);

  const { setProductsToSearch } = props.generalDataProps;

  const { paraswapSupportedTokens } = props.generalDataProps;

  useEffect(() => {
    if (
      Object.values(allFilters).length > 0 &&
      props.productsToSearch?.length > 0
    ) {
      let allFiltered = [];
      Object.keys(allFilters).forEach((key) => {
        if (allFilters[key].length > 0) {
          const productFilter = props.productsToSearch.filter((prd) =>
            allFilters[key].includes(
              prd[key.toString().toLowerCase().replace("by ", "")]
            )
          );
          allFiltered.push(...productFilter);
        }
      });
      setNewProductToSearch(allFiltered);
    }

    if (Object.values(allFilters).flat().length === 0) {
      setNewProductToSearch(null);
    }
  }, [allFilters, props.productsToSearch]);

  // console.log("allFilters: ", allFilters);

  const location = useLocation();

  const handleFavouriteClick = (e) => {
    e.target.src.split("/")[e.target.src.split("/").length - 1] ===
    favoriteIcon.split("/")[favoriteIcon.split("/").length - 1]
      ? (e.target.src = favoriteClickedIcon)
      : (e.target.src = favoriteIcon);
    props.setPopupContentPosition("flex-end");
    props.setPopupContent(
      <WhitelistPopup
        setShowPopup={props.setShowPopup}
        setPopupContent={props.setPopupContent}
        setPopupContentPosition={props.setPopupContentPosition}
        setFavouriteClicked={props.setFavouriteClicked}
        setNewSearchContent={setNewSearchContent}
        productClicked={productClicked}
      />
    );
    props.setFavouriteClicked(true);
    props.setShowPopup(true);
  };

  return (
    <main
      id="layout-content-id"
      style={
        !props.fullSidebar && !props.favouriteClicked
          ? { width: "92.5324675%" }
          : !props.fullSidebar && props.favouriteClicked
          ? { width: "62%", position: "absolute", left: "9%" }
          : props.favouriteClicked
          ? { width: "53%", position: "absolute", right: "29%" }
          : {}
      }
      className="layout-content"
    >
      {pagesWithGeneralSearch.includes(location.pathname) && (
        <>
          <div className="l-search-container">
            <div className="l-search">
              <div className="l-search-right">
                <img
                  className="l-search-icon"
                  alt="Search Icon"
                  src={searchIcon}
                />
                <input
                  onInputCapture={(e) => {
                    if (e.target.value !== "") {
                      setShowSearchedResult(true);
                    }
                  }}
                  onFocus={() => {
                    setNewSearchContent(null);
                    setShowSearchedResult(true);
                  }}
                  onInput={(e) => {
                    if (e.target.value === "") {
                      setShowSearchedResult(false);
                    } else if (!newProductToSearch) {
                      setNewSearchContent(
                        // eslint-disable-next-line array-callback-return
                        props.productsToSearch.filter((prd) => {
                          if (
                            prd.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          ) {
                            prd.searched = e.target.value.toLowerCase();
                            prd.nameArray = prd.name.split("");
                            return prd;
                          }
                        })
                      );
                    } else if (newProductToSearch) {
                      setNewSearchContent(
                        // eslint-disable-next-line array-callback-return
                        newProductToSearch.filter((prd) => {
                          if (
                            prd.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          ) {
                            prd.searched = e.target.value.toLowerCase();
                            prd.nameArray = prd.name.split("");
                            return prd;
                          }
                        })
                      );
                    }
                  }}
                  type="text"
                  placeholder="Search investments (eg: YC Capital )"
                  className="l-search-input"
                />
              </div>
              <div className="l-search-left">
                <div className="l-search-separator"></div>
                {ShowFilterDot && (
                  <img
                    onClick={() => {
                      props.setPopupContentPosition("flex-end");
                      props.setPopupContent(
                        <SearchFilterPopup
                          setShowPopup={props.setShowPopup}
                          setPopupContent={props.setPopupContent}
                          setPopupContentPosition={
                            props.setPopupContentPosition
                          }
                          setFavouriteClicked={props.setFavouriteClicked}
                          setShowFilterDot={setShowFilterDot}
                          allFilters={allFilters}
                          setAllFilters={setAllFilters}
                          setNewProductToSearch={setNewProductToSearch}
                        />
                      );
                      props.setFavouriteClicked(true);
                      props.setShowPopup(true);
                    }}
                    className="l-search-widget-icon"
                    alt="Search Widget Icon"
                    src={widgetIconWithDot}
                  />
                )}
                {!ShowFilterDot && (
                  <img
                    onClick={() => {
                      props.setPopupContentPosition("flex-end");
                      props.setPopupContent(
                        <SearchFilterPopup
                          setShowPopup={props.setShowPopup}
                          setPopupContent={props.setPopupContent}
                          setPopupContentPosition={
                            props.setPopupContentPosition
                          }
                          setFavouriteClicked={props.setFavouriteClicked}
                          setShowFilterDot={setShowFilterDot}
                          allFilters={allFilters}
                          setAllFilters={setAllFilters}
                          setNewProductToSearch={setNewProductToSearch}
                        />
                      );
                      props.setFavouriteClicked(true);
                      props.setShowPopup(true);
                    }}
                    className="l-search-widget-icon"
                    alt="Search Widget Icon"
                    src={widgetIcon}
                  />
                )}
              </div>
            </div>
            {showSearchedResult && (
              <div className="l-search-result">
                {!newProductToSearch &&
                  !newSearchContent &&
                  props.productsToSearch.map((prd, idx) => (
                    <div
                      key={idx}
                      className="l-search-result-content-container"
                      onClickCapture={() => {
                        setProductClicked(prd);
                      }}
                      onClick={() => {
                        props.setPopupContentPosition("flex-end");
                        props.setPopupContent(
                          <AssetInfoPage
                            setShowPopup={props.setShowPopup}
                            setPopupContent={props.setPopupContent}
                            setPopupContentPosition={
                              props.setPopupContentPosition
                            }
                            setFavouriteClicked={props.setFavouriteClicked}
                            productClicked={productClicked}
                            customLoading={props.customLoading}
                            setCustomLoading={props.setCustomLoading}
                            paraswapSupportedTokens={paraswapSupportedTokens}
                            expandedView={props.expandedView}
                            setExpandedView={props.setExpandedView}
                          />
                        );
                        props.setFavouriteClicked(true);
                        props.setShowPopup(true);
                      }}
                    >
                      <div className="l-search-result-content">
                        <div className="l-search-result-first-section">
                          <div className="l-search-result-first-text-container">
                            <p className="l-search-result-first-text-title">
                              {prd.name}
                            </p>
                            <div className="l-search-result-first-text-description-container">
                              <p className="l-search-result-first-text-description">
                                {prd.label}
                              </p>
                            </div>
                          </div>
                          <div className="l-search-result-second-text-container">
                            <p className="l-search-result-second-text">
                              {prd.description}
                            </p>
                          </div>
                        </div>
                        {/* {prd.favorite && (
                          <img
                            onClickCapture={() => {
                              setProductClicked(prd);
                            }}
                            onClick={(e) => {
                              e.target.src.split("/")[
                                e.target.src.split("/").length - 1
                              ] ===
                              favoriteIcon.split("/")[
                                favoriteIcon.split("/").length - 1
                              ]
                                ? handleFavouriteClick(e)
                                : (e.target.src = favoriteIcon);
                            }}
                            className="l-search-result-favorite-icon"
                            src={favoriteClickedIcon}
                            alt="Favorite Icon"
                          />
                        )}
                        {!prd.favorite && (
                          <img
                            onClickCapture={() => {
                              setProductClicked(prd);
                            }}
                            onClick={(e) => {
                              handleFavouriteClick(e);
                            }}
                            className="l-search-result-favorite-icon"
                            src={favoriteIcon}
                            alt="Favorite Icon"
                          />
                        )} */}
                      </div>
                    </div>
                  ))}
                {newProductToSearch &&
                  !newSearchContent &&
                  newProductToSearch.map((prd, idx) => (
                    <div
                      key={idx}
                      className="l-search-result-content-container"
                      onClickCapture={() => {
                        setProductClicked(prd);
                      }}
                      onClick={() => {
                        props.setPopupContentPosition("flex-end");
                        props.setPopupContent(
                          <AssetInfoPage
                            setShowPopup={props.setShowPopup}
                            setPopupContent={props.setPopupContent}
                            setPopupContentPosition={
                              props.setPopupContentPosition
                            }
                            setFavouriteClicked={props.setFavouriteClicked}
                            productClicked={productClicked}
                            customLoading={props.customLoading}
                            setCustomLoading={props.setCustomLoading}
                            paraswapSupportedTokens={paraswapSupportedTokens}
                            expandedView={props.expandedView}
                            setExpandedView={props.setExpandedView}
                          />
                        );
                        props.setFavouriteClicked(true);
                        props.setShowPopup(true);
                      }}
                    >
                      <div className="l-search-result-content">
                        <div className="l-search-result-first-section">
                          <div className="l-search-result-first-text-container">
                            <p className="l-search-result-first-text-title">
                              {prd.name}
                            </p>
                            <div className="l-search-result-first-text-description-container">
                              <p className="l-search-result-first-text-description">
                                {prd.label}
                              </p>
                            </div>
                          </div>
                          <div className="l-search-result-second-text-container">
                            <p className="l-search-result-second-text">
                              {prd.description}
                            </p>
                          </div>
                        </div>
                        {/* {prd.favorite && (
                          <img
                            onClickCapture={() => {
                              setProductClicked(prd);
                            }}
                            onClick={(e) => {
                              e.target.src.split("/")[
                                e.target.src.split("/").length - 1
                              ] ===
                              favoriteIcon.split("/")[
                                favoriteIcon.split("/").length - 1
                              ]
                                ? handleFavouriteClick(e)
                                : (e.target.src = favoriteIcon);
                            }}
                            className="l-search-result-favorite-icon"
                            src={favoriteClickedIcon}
                            alt="Favorite Icon"
                          />
                        )}
                        {!prd.favorite && (
                          <img
                            onClickCapture={() => {
                              setProductClicked(prd);
                            }}
                            onClick={(e) => {
                              handleFavouriteClick(e);
                            }}
                            className="l-search-result-favorite-icon"
                            src={favoriteIcon}
                            alt="Favorite Icon"
                          />
                        )} */}
                      </div>
                    </div>
                  ))}
                {newSearchContent &&
                  newSearchContent.length > 0 &&
                  newSearchContent.map((prd, idx) => (
                    <div
                      key={idx}
                      className="l-search-result-content-container"
                      onClickCapture={() => {
                        setProductClicked(prd);
                      }}
                      onClick={() => {
                        props.setPopupContentPosition("flex-end");
                        props.setPopupContent(
                          <AssetInfoPage
                            setShowPopup={props.setShowPopup}
                            setPopupContent={props.setPopupContent}
                            setPopupContentPosition={
                              props.setPopupContentPosition
                            }
                            setFavouriteClicked={props.setFavouriteClicked}
                            productClicked={productClicked}
                            customLoading={props.customLoading}
                            setCustomLoading={props.setCustomLoading}
                            paraswapSupportedTokens={paraswapSupportedTokens}
                            expandedView={props.expandedView}
                            setExpandedView={props.setExpandedView}
                          />
                        );
                        props.setFavouriteClicked(true);
                        props.setShowPopup(true);
                      }}
                    >
                      <div className="l-search-result-content">
                        <div className="l-search-result-first-section">
                          <div className="l-search-result-first-text-container">
                            <p className="l-search-result-first-text-title">
                              {prd.nameArray?.length > 0 &&
                                prd.nameArray.map((nm, idx) => (
                                  <>
                                    {!prd.searched.includes(
                                      nm.toLowerCase()
                                    ) && (
                                      <span
                                        key={`name-${idx}`}
                                        style={{
                                          color: "#8F91A1",
                                        }}
                                      >
                                        {nm}
                                      </span>
                                    )}
                                    {prd.searched.includes(
                                      nm.toLowerCase()
                                    ) && <span key={idx}>{nm}</span>}
                                  </>
                                ))}
                            </p>
                            <div className="l-search-result-first-text-description-container">
                              <p className="l-search-result-first-text-description">
                                {prd.label}
                              </p>
                            </div>
                          </div>
                          <div className="l-search-result-second-text-container">
                            <p className="l-search-result-second-text">
                              {prd.description}
                            </p>
                          </div>
                        </div>
                        {/* {prd.favorite && (
                          <img
                            onClickCapture={() => {
                              setProductClicked(prd);
                            }}
                            onClick={(e) => {
                              e.target.src.split("/")[
                                e.target.src.split("/").length - 1
                              ] ===
                              favoriteIcon.split("/")[
                                favoriteIcon.split("/").length - 1
                              ]
                                ? handleFavouriteClick(e)
                                : (e.target.src = favoriteIcon);
                            }}
                            className="l-search-result-favorite-icon"
                            src={favoriteClickedIcon}
                            alt="Favorite Icon"
                          />
                        )}
                        {!prd.favorite && (
                          <img
                            onClickCapture={() => {
                              setProductClicked(prd);
                            }}
                            onClick={(e) => {
                              handleFavouriteClick(e);
                            }}
                            className="l-search-result-favorite-icon"
                            src={favoriteIcon}
                            alt="Favorite Icon"
                          />
                        )} */}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </>
      )}
      {props.children}
    </main>
  );
};

export default LayoutContent;
