import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { createAppKit } from "@reown/appkit/react";
import { mainnet, sepolia, base, gnosis } from "@reown/appkit/networks";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import verifiedLogo from "./assests/header/verified-logo.svg";
import { WagmiProvider } from "wagmi";

const root = ReactDOM.createRoot(document.getElementById("root"));

const projectId = process.env.REACT_APP_WALLET_CONNECT_ID;

const networks =
  process.env.REACT_APP_NODE_ENV === "production"
    ? [base, mainnet]
    : [sepolia, gnosis];

const metadata = {
  name: "Verified Network",
  description:
    "The decentralized web application for payments, financing and investments on the Verified Network",
  url: window.location.href,
  icons: [verifiedLogo],
};

export const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
});

createAppKit({
  adapters: [wagmiAdapter],
  networks,
  metadata,
  projectId,
  features: {
    analytics: true,
    onramp: true,
  },
});

const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiProvider>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
