import verifiedLogo from "../../assests/header/verified-logo.svg";
import bellIcon from "../../assests/header/bell.svg";
import questionsIcon from "../../assests/header/question.svg";
import profileIcon from "../../assests/header/user.svg";
import settingsIcon from "../../assests/page/settings.svg";
import mobileSettings from "../../assests/header/mobile-settings.svg";
import backIcon from "../../assests/page/mobile-chevron_left.svg";
import filterIcon from "../../assests/page/filter_list.svg";
import verifiedIcon from "../../assests/page/verified-custom.svg";
import snIcon from "../../assests/page/SN.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ProfilePopup from "../../components/popups/profile";
import { useState } from "react";
import BorrowPopupPage from "../../components/popups/borrow";
import {
  pagesWithoutHeader,
  pagesWithoutProfileIcon,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../utils/helpers";

const CommonHeader = (props) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const { userAddress, userFirstName, userKycStatus, isConnected } =
    useSelector((state) => state.user);

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <>
      {!pagesWithoutHeader.includes(location.pathname) && (
        <header
          style={
            props.mobileScreen &&
            window.location.pathname.includes("/add-funds")
              ? {
                  minHeight: "105px",
                  zIndex: "1",
                  paddingTop: "12px",
                  alignItems: "flex-start",
                  overflow: "auto",
                }
              : {}
          }
          className="header"
        >
          {!props.mobileScreen && (
            <>
              <div onClick={() => navigate("/")} className="header-logo">
                <img alt="Verified Logo" src={verifiedLogo} />
              </div>
              {window.location.pathname !== "" && (
                <div className="header-icons">
                  {/* {window.location.search === "?notifications" ? (
                <div className="header-icon-overlay-container">
                  <div
                    onClick={() => {
                      navigate("?notifications");
                      if (showNotifications) {
                        setShowNotifications(false);
                        navigate(
                          window.location.href
                            .replace(window.location.search, "")
                            .replace(window.location.origin, "")
                        );
                      } else {
                        setShowNotifications(true);
                      }
                    }}
                    className="header-icon-clicked"
                  >
                    <img alt="Notifications Icon" src={bellIcon} />
                  </div>

                  {showNotifications && (
                    <div className="header-icon-overlay-body">
                      <div className="header-icon-overlay-body-header">
                        <div className="header-icon-overlay-body-header-first">
                          <div className="header-icon-overlay-body-header-first-container">
                            <div className="header-icon-overlay-body-header-first-icon">
                              <img
                                className="profile-popup-icon"
                                src={filterIcon}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="header-icon-overlay-body-header-second">
                          <div className="header-icon-overlay-body-header-second-icon">
                            <img
                              className="profile-popup-icon"
                              src={settingsIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="header-icon-overlay-body-footer">
                        <div className="header-icon-overlay-body-footer-first">
                          <div className="header-icon-overlay-body-footer-first-icon">
                            <img src={verifiedIcon} alt="" />
                          </div>

                          <div className="header-icon-overlay-body-footer-first-body">
                            <div className="header-icon-overlay-body-footer-first-body-text-container">
                              <p className="header-icon-overlay-body-footer-first-body-text">
                                Your Loan money has been disbursed
                              </p>
                              <p className="header-icon-overlay-body-footer-first-body-label">
                                Your Issued Bond{" "}
                                <span className="header-icon-overlay-body-footer-first-body-custom-label">
                                  ( ID:11DNV-C3SW)
                                </span>{" "}
                                has been purchased by Alok Anand. We have
                                disbursed{" "}
                                <span className="header-icon-overlay-body-footer-first-body-custom-label">
                                  $148.80
                                </span>{" "}
                                to your wallet. You can see updated details in
                                your loans section.
                              </p>
                            </div>

                            <div className="header-icon-overlay-body-footer-first-body-content">
                              <button
                                onClick={() => {
                                  setShowNotifications(false);
                                  props.setPopupContentPosition("flex-end");
                                  props.setPopupContent(
                                    <BorrowPopupPage
                                      setShowPopup={props.setShowPopup}
                                      setPopupContent={props.setPopupContent}
                                      setPopupContentPosition={
                                        props.setPopupContentPosition
                                      }
                                      currentStep="1"
                                      actionTye="repay"
                                      setShowNotifications={
                                        setShowNotifications
                                      }
                                    />
                                  );
                                  props.setShowPopup(true);
                                }}
                                className="header-icon-overlay-body-footer-first-body-button-active"
                              >
                                <p className="header-icon-overlay-body-footer-first-body-button-text-active">
                                  View Details
                                </p>
                              </button>

                              <button className="header-icon-overlay-body-footer-first-body-button">
                                <p className="header-icon-overlay-body-footer-first-body-button-text">
                                  Remind Me Later
                                </p>
                              </button>
                            </div>

                            <p className="header-icon-overlay-body-footer-first-body-footer-text">
                              2 mins ago
                            </p>
                          </div>
                        </div>

                        <div className="header-icon-overlay-body-footer-second">
                          <div className="header-icon-overlay-body-footer-first-icon">
                            <img src={verifiedIcon} alt="" />
                          </div>

                          <div className="header-icon-overlay-body-footer-first-body">
                            <div className="header-icon-overlay-body-footer-first-body-text-container">
                              <p className="header-icon-overlay-body-footer-second-body-text">
                                Top Stock Recommendations
                              </p>
                              <p className="header-icon-overlay-body-footer-first-body-label">
                                Dharmesh Shah of ICICI Securities suggests
                                buying Tata Power and Oil India today
                              </p>
                            </div>

                            <p className="header-icon-overlay-body-footer-first-body-footer-text">
                              3 mins ago
                            </p>
                          </div>
                        </div>

                        <div className="header-icon-overlay-body-footer-third">
                          <div className="header-icon-overlay-body-footer-first-custom-icon">
                            <img src={snIcon} alt="" />
                          </div>
                          <div className="header-icon-overlay-body-footer-first-body">
                            <div className="header-icon-overlay-body-footer-first-body-text-container">
                              <p className="header-icon-overlay-body-footer-third-body-text">
                                Co Signers
                              </p>
                              <p className="header-icon-overlay-body-footer-first-body-label">
                                <span className="header-icon-overlay-body-footer-third-body-custom-label">
                                  Kunal Sharma
                                </span>{" "}
                                &{" "}
                                <span className="header-icon-overlay-body-footer-third-body-custom-label">
                                  Sunil Rai
                                </span>{" "}
                                has rejected to be your cosigner. Please add
                                cosigner for your transactions to complete.
                              </p>
                            </div>

                            <p className="header-icon-overlay-body-footer-first-body-footer-text">
                              5 mins ago
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => {
                    navigate("?notifications");
                    setShowNotifications(!showNotifications);
                  }}
                  className="header-icon"
                >
                  <img alt="Notifications Icon" src={bellIcon} />
                </div>
              )}
              {window.location.search === "?questions" ? (
                <div
                  onClick={() => {
                    navigate("?questions");
                    // appKitModal.open();
                  }}
                  className="header-icon-clicked"
                >
                  <img alt="Questions Icon" src={questionsIcon} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    navigate("?questions");
                    // appKitModal.open();
                  }}
                  className="header-icon"
                >
                  <img alt="Questions Icon" src={questionsIcon} />
                </div>
              )} */}
                  {window.location.search === "?profile" ? (
                    <div
                      onClick={() => {
                        navigate("?profile");
                        props.setPopupContentPosition("flex-end");
                        props.setPopupContent(
                          <ProfilePopup
                            setShowPopup={props.setShowPopup}
                            setPopupContent={props.setPopupContent}
                            setPopupContentPosition={
                              props.setPopupContentPosition
                            }
                            setFavouriteClicked={props.setFavouriteClicked}
                            setCustomLoading={props.setCustomLoading}
                          />
                        );
                        props.setFavouriteClicked(true);
                        props.setShowPopup(true);
                      }}
                      className="header-icon-clicked"
                    >
                      <img alt="Profile Icon" src={profileIcon} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        navigate("?profile");
                        props.setPopupContentPosition("flex-end");
                        props.setPopupContent(
                          <ProfilePopup
                            setShowPopup={props.setShowPopup}
                            setPopupContent={props.setPopupContent}
                            setPopupContentPosition={
                              props.setPopupContentPosition
                            }
                            setFavouriteClicked={props.setFavouriteClicked}
                            setCustomLoading={props.setCustomLoading}
                          />
                        );
                        props.setFavouriteClicked(true);
                        props.setShowPopup(true);
                      }}
                      className="header-icon"
                    >
                      <img alt="Profile Icon" src={profileIcon} />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {props.mobileScreen && (
            <>
              {window.location.pathname !== "" && (
                <>
                  <div
                    style={
                      props.mobileScreen &&
                      window.location.pathname === "/add-funds"
                        ? {
                            paddingLeft: "12px",
                          }
                        : {}
                    }
                    className="mobile-header-first-section"
                  >
                    {!pagesWithoutProfileIcon.includes(location.pathname) && (
                      <>
                        {!window.location.pathname.includes("/profile") &&
                          !window.location.pathname.includes("/add-funds") &&
                          !window.location.pathname.includes("/asset-info") && (
                            <div
                              onClick={() => navigate("profile")}
                              id="profile"
                              className="header-icon"
                            >
                              <img alt="Profile Icon" src={profileIcon} />
                            </div>
                          )}
                        {window.location.pathname.includes("/profile") && (
                          <img
                            onClick={() => {
                              if (window.location.pathname === "/profile") {
                                navigate("/");
                              } else {
                                navigate("/profile");
                              }
                            }}
                            style={{ marginRight: "8px" }}
                            alt="Back Icon"
                            src={backIcon}
                          />
                        )}
                        {window.location.pathname.includes("/add-funds") && (
                          <img
                            onClick={() => {
                              if (window.location.pathname === "/add-funds") {
                                navigate("/");
                              } else {
                                navigate("/add-funds");
                              }
                            }}
                            style={{ marginRight: "8px" }}
                            alt="Back Icon"
                            src={backIcon}
                          />
                        )}
                        {window.location.pathname.includes("/asset-info") && (
                          <img
                            onClick={() => {
                              if (window.location.pathname === "/asset-info") {
                                navigate("/");
                              } else {
                                navigate("/asset-info");
                              }
                            }}
                            style={{ marginRight: "8px" }}
                            alt="Back Icon"
                            src={backIcon}
                          />
                        )}
                      </>
                    )}

                    {location.pathname === "/" && userFirstName && (
                      <p className="mobile-header-first-section-text">
                        Hi {capitalizeFirstLetter(userFirstName)},
                      </p>
                    )}
                    {location.pathname === "/dashboard" && userFirstName && (
                      <p className="mobile-header-first-section-text">
                        Hi {capitalizeFirstLetter(userFirstName)},
                      </p>
                    )}
                    {location.pathname === "/investments" && (
                      <p className="mobile-header-first-section-text">
                        Investments
                      </p>
                    )}
                    {location.pathname === "/profile" && (
                      <p className="mobile-header-first-section-text">
                        Profile
                      </p>
                    )}
                    {location.pathname === "/profile-details" && (
                      <p className="mobile-header-first-section-text">
                        Profile Details
                      </p>
                    )}
                    {location.pathname === "/profile-settings" && (
                      <p className="mobile-header-first-section-text">
                        Account Settings
                      </p>
                    )}
                    {location.pathname === "/add-funds" && (
                      <p className="mobile-header-first-section-text">
                        Add Funds
                      </p>
                    )}
                    {location.pathname === "/add-funds/currencies" && (
                      <p className="mobile-header-first-section-text">
                        Choose a currency
                      </p>
                    )}
                    {location.pathname === "/asset-info" && (
                      <p className="mobile-header-first-section-text">
                        ASSET DETAILS
                      </p>
                    )}
                  </div>
                  <div className="header-icons">
                    {/* {window.location.pathname === "/notifications" ? (
                      <div
                        onClick={() => navigate("notifications")}
                        className="header-icon-clicked"
                      >
                        <img alt="Notifications Icon" src={bellIcon} />
                      </div>
                    ) : (
                      <div
                        onClick={() => navigate("notifications")}
                        className="header-icon"
                      >
                        <img alt="Notifications Icon" src={bellIcon} />
                      </div>
                    )}
                    {window.location.pathname === "/questions" ? (
                      <div
                        onClick={() => navigate("questions")}
                        className="header-icon-clicked"
                      >
                        <img alt="Questions Icon" src={questionsIcon} />
                      </div>
                    ) : (
                      <div
                        onClick={() => navigate("questions")}
                        className="header-icon"
                      >
                        <img alt="Questions Icon" src={questionsIcon} />
                      </div>
                    )} */}
                    {window.location.pathname === "/profile" && (
                      <div
                        style={{ background: "transparent" }}
                        onClick={() => navigate("profile-settings")}
                        className="header-icon"
                      >
                        <img alt="Settings Icon" src={mobileSettings} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </header>
      )}
    </>
  );
};

export default CommonHeader;
