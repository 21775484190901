import { constructSimpleSDK, SwapSide } from "@paraswap/sdk";
import ERC20ABI from "../../utils/abis/ERC20.json";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { ChainConfig } from "../../utils/config";

export const getTokensSwapRate = async (
  userAddress,
  chainId,
  provider,
  swapType,
  tokenFrom,
  tokenTo,
  tokenFromDecimals,
  amountToSwap,
  maxSlippage
) => {
  try {
    const paraSwap = constructSimpleSDK(
      { chainId: chainId, fetch: window.fetch },
      provider
    );
    const priceCheckRoute = await paraSwap.swap.getRate({
      srcToken: tokenFrom,
      destToken: tokenTo,
      amount: (amountToSwap * Number(10 ** tokenFromDecimals)).toFixed(0),
      userAddress: userAddress,
      side: SwapSide.SELL,
      options: maxSlippage
        ? {
            maxImpact: maxSlippage,
          }
        : {},
    });
    const amountCheck =
      priceCheckRoute.destAmount / Math.pow(10, priceCheckRoute.destDecimals);

    const slippage =
      amountCheck >= amountToSwap
        ? (Number(amountCheck - amountToSwap) / amountToSwap) * 100
        : (Number(amountToSwap - amountCheck) / amountToSwap) * 100;

    return {
      amount: amountCheck,
      slippage: slippage,
      route: priceCheckRoute,
    };
  } catch (err) {
    if (err?.message === "ESTIMATED_LOSS_GREATER_THAN_MAX_IMPACT") {
      toast.error("Slippage Error Change Slippage and Try Again");
    }
    // else if (err?.message?.includes("is too small to proceed")) {
    //   toast.error("Amount is Too Small To Swap");
    // }
    return {
      amount: 0,
      slippage: 0,
      route: null,
    };
  }
};

export const swapTokenWithParaSwap = async (
  userAddress,
  chainId,
  provider,
  web3,
  priceRoute
) => {
  const paraSwap = constructSimpleSDK(
    { chainId: chainId, fetch: window.fetch },
    provider
  );
  let isApproved = false;

  const tokenTransfered = new web3.eth.Contract(ERC20ABI, priceRoute.srcToken);
  const allowance = await tokenTransfered.methods
    .allowance(userAddress, priceRoute.tokenTransferProxy)
    .call()
    .then((res) => Number(res));
  if (allowance < Number(priceRoute.srcAmount)) {
    await tokenTransfered.methods
      .approve(priceRoute.tokenTransferProxy, priceRoute.srcAmount)
      .send({ from: userAddress })
      .then((res) => {
        isApproved = true;
      })
      .catch((err) => {
        isApproved = false;
        return false;
      });
  } else if (allowance >= Number(priceRoute.srcAmount)) {
    isApproved = true;
  }

  if (isApproved && provider?.getSigner) {
    const txParams = await paraSwap.swap.buildTx({
      srcToken: priceRoute.srcToken,
      destToken: priceRoute.destToken,
      srcAmount: priceRoute.srcAmount,
      destAmount: priceRoute.destAmount,
      priceRoute,
      userAddress: userAddress,
      partner: userAddress,
    });

    const transaction = {
      ...txParams,
      gasPrice: "0x" + new BigNumber(txParams.gasPrice).toString(16),
      gasLimit: "0x" + new BigNumber(1000000).toString(16),
      value: "0x" + new BigNumber(txParams.value).toString(16),
    };

    // console.log(
    //   "transaction paraSwapTransaction func",
    //   transaction,
    //   priceRoute,
    //   web3
    // );
    const tx = {
      from: userAddress,
      to: transaction.to,
      data: transaction.data,
      gasLimit: transaction.gasLimit,
      value: transaction.value,
      chainId: transaction.chainId,
      gasPrice: transaction.gasPrice,
    };

    try {
      const transactionData = await provider
        .getSigner(userAddress)
        .sendTransaction(tx);
      const pendingTransaction = await transactionData.wait();
      if (pendingTransaction?.transactionHash) {
        return {
          hash: ChainConfig[chainId]?.blockExplorerUrls[0]
            ? `${ChainConfig[chainId]?.blockExplorerUrls[0]}tx/${pendingTransaction?.transactionHash}`
            : "",
        };
      }
    } catch (err) {
      console.error("paraswap swap failed with error: ", err);
      return false;
    }
  }
  return false;
};
