import { createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef } from "react";

export const AreaChart = (props) => {
  const {
    areaData,
    graphTime,
    currencySymbol,
    graphType,
    ismaximised,
    colors: {
      backgroundColor = "#FFF",
      lineColor = "#7A76FF",
      textColor = "#8F91A1",
      areaTopColor = "#7A76FF",
      areaBottomColor = "rgba(255, 255, 255, 0.00)",
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: 300,
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },

      crosshair: {
        mode: 0,
        vertLine: {
          color: "#B6B7C3",
          width: 1,
          labelVisible: true,
          labelBackgroundColor: "#FFF",
        },
        horzLine: {
          color: "#B6B7C3",
          width: 1,
          labelVisible: true,
          labelBackgroundColor: "#FFF",
        },
      },
      timeScale: {
        tickMarkFormatter: (timeStamp) => {
          if (filteredData.length > 0 && graphTime.label === "1h") {
            const date = new Date(Number(timeStamp) * 1000);
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            return `${minutes}:${seconds}`;
          } else if (filteredData.length > 0 && graphTime.label === "24h") {
            const date = new Date(Number(timeStamp) * 1000);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            return `${hours}:${minutes}`;
          } else if (filteredData.length > 0 && graphTime.label === "1w") {
            const date = new Date(Number(timeStamp) * 1000);
            const dayNumber = date.getDate();
            const day = date.toLocaleDateString("en-US", { weekday: "short" });
            return `${dayNumber} ${day}`;
          } else {
            const date = new Date(Number(timeStamp) * 1000);
            const day = date.getDate();
            const month = date.toLocaleDateString("default", {
              month: "short",
            });
            return `${day} ${month}`;
          }
        },
        tickMarkMaxCharacterLength: 5,
      },
      localization: {
        priceFormatter: (price) => {
          if (graphType === "price" && currencySymbol) {
            return `${Number(price).toFixed(4)} ${currencySymbol}`;
          } else {
            return `${Number(price).toFixed(4)}`;
          }
        },
      },
      rightPriceScale: {
        borderVisible: false,
      },
      leftPriceScale: {
        borderVisible: true,
        minimumWidth: 1,
        borderColor: "#EEEEF0",
        textColor: textColor,
      },
    });
    chart.timeScale().fitContent();

    const now = Date.now();

    const filteredData = graphTime
      ? areaData.filter(
          (dt) =>
            Number(dt.time) >= Number(now / 1000) - Number(graphTime.time) &&
            Number(dt.time) <= Number(now / 1000)
        )
      : [];

    const areaSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
    });
    areaSeries.setData(filteredData.length > 0 ? filteredData : areaData);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    areaData,
    graphTime,
    graphType,
    currencySymbol,
    ismaximised,
  ]);

  return (
    <div
      id="area-chart-container"
      style={{ width: "100%" }}
      ref={chartContainerRef}
    />
  );
};

export const CandleStickChart = (props) => {
  const {
    candleStickData,
    graphTime,
    currencySymbol,
    graphType,
    ismaximised,
    colors: {
      backgroundColor = "#FFF",
      lineColor = "#7A76FF",
      textColor = "#8F91A1",
      upColor = "#41732F",
      borderUpColor = "#41732F",
      wickUpColor = "#41732F",
      downColor = "#BC1919",
      borderDownColor = "#BC1919",
      wickDownColor = "#BC1919",
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: 300,
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },

      crosshair: {
        mode: 0,
        vertLine: {
          color: "#B6B7C3",
          width: 1,
          labelVisible: true,
          labelBackgroundColor: "#FFF",
        },
        horzLine: {
          color: "#B6B7C3",
          width: 1,
          labelVisible: true,
          labelBackgroundColor: "#FFF",
        },
      },
      timeScale: {
        tickMarkFormatter: (timeStamp) => {
          if (filteredData.length > 0 && graphTime.label === "1h") {
            const date = new Date(Number(timeStamp) * 1000);
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            return `${minutes}:${seconds}`;
          } else if (filteredData.length > 0 && graphTime.label === "24h") {
            const date = new Date(Number(timeStamp) * 1000);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            return `${hours}:${minutes}`;
          } else if (filteredData.length > 0 && graphTime.label === "1w") {
            const date = new Date(Number(timeStamp) * 1000);
            const dayNumber = date.getDate();
            const day = date.toLocaleDateString("en-US", { weekday: "short" });
            return `${dayNumber} ${day}`;
          } else {
            const date = new Date(Number(timeStamp) * 1000);
            const day = date.getDate();
            const month = date.toLocaleDateString("default", {
              month: "short",
            });
            return `${day} ${month}`;
          }
        },
        tickMarkMaxCharacterLength: 5,
      },
      localization: {
        priceFormatter: (price) => {
          if (graphType === "price" && currencySymbol) {
            return `${Number(price).toFixed(4)} ${currencySymbol}`;
          } else {
            return `${Number(price).toFixed(4)}`;
          }
        },
      },
      rightPriceScale: {
        borderVisible: false,
      },
      leftPriceScale: {
        borderVisible: true,
        minimumWidth: 1,
        borderColor: "#EEEEF0",
        textColor: textColor,
      },
    });
    chart.timeScale().fitContent();

    const now = Date.now();

    const filteredData = graphTime
      ? candleStickData.filter(
          (dt) =>
            Number(dt.time) >= Number(now / 1000) - Number(graphTime.time) &&
            Number(dt.time) <= Number(now / 1000)
        )
      : [];

    const areaSeries = chart.addCandlestickSeries({
      upColor,
      downColor,
      wickUpColor,
      wickDownColor,
      borderDownColor,
      borderUpColor,
    });
    areaSeries.setData(
      filteredData.length > 0 ? filteredData : candleStickData
    );

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    backgroundColor,
    lineColor,
    textColor,
    candleStickData,
    upColor,
    downColor,
    borderUpColor,
    borderDownColor,
    wickUpColor,
    wickDownColor,
    graphTime,
    graphType,
    currencySymbol,
    ismaximised,
  ]);

  return (
    <div
      id="candle-stick-chart-container"
      style={{ width: "100%" }}
      ref={chartContainerRef}
    />
  );
};
